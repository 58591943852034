import axios from "axios";

export default {
  namespaced: true,

  state: {
    promotions: [],
    localPromotions: [],
    error: null
  },

  getters: {
    promotions: state => {
      return state.promotions;
    },
    localPromotions: state => {
      return state.localPromotions;
    },
    error: state => {
      return state.error;
    }
  },

  mutations: {
    SET_PROMOTIONS(state, promotions) {
      state.promotions = promotions;
    },
    SET_LOCAL_PROMOTIONS(state, promotions) {
      state.localPromotions = promotions;
    },
    SET_ERROR(state, error) {
      state.error = error;
    },
    ADD_TO_PROMOTIONS(state, promotion) {
      state.promotions.push(promotion);
    },
  },

  actions: {
    GET_PROMOTIONS: async ({commit}, params) => {
      commit("loading", true, {root: true});

      const res = await axios
        .get(`/api/promotions/${params.countryOfUser}/${params.stateOfUser}`)
        .then(response => {
          commit("SET_PROMOTIONS", response.data.data);
          commit("loading", false, {root: true});
          return response
        })
        .catch(error => {
          commit("SET_PROMOTIONS", []);
          commit("loading", false, {root: true});
        });
        if(res.data.data.length > 0) {
          return Promise.resolve(res.data.data.length)
        }
    },
    GET_LOCAL_PROMOTIONS: async ({commit}) => {
      commit("loading", true, {root: true});

      await axios
        .get("/api/promotions?user=true")
        .then(response => {
          commit("SET_LOCAL_PROMOTIONS", response.data.data);
          commit("loading", false, {root: true});
        })
        .catch(error => {
          commit("SET_LOCAL_PROMOTIONS", []);
          commit("loading", false, {root: true});
        });
    },
    GET_PROMOTION_PREVIEW: async ({commit}, id) => {
      commit("loading", true, {root: true});

      await axios
        .get(`/api/promotions/preview/${id}`)
        .then(response => {
          commit("SET_PROMOTIONS", []);
          commit("ADD_TO_PROMOTIONS", response.data.data);
          commit("loading", false, {root: true});
        })
        .catch(error => {
          commit("SET_PROMOTIONS", []);
          commit("loading", false, {root: true});
        });
    },
  }
};
