<template>
  <section class="sign">
    <SwitchLocale/>
    <a v-if="step !== 1" class="link-back" href="#" @click.prevent="prev()">
      <img src="../../assets/img/icon-arrow-left-white.svg" alt="back"/>
    </a>
    <router-link class="logo" to="/">
      <img src="@/assets/img/logo.svg" alt="Trivento Wine Compass"/>
    </router-link>
    <h1>{{ $t("sign-up") }}</h1>
    <form
      class="form"
      action="#"
      @click="focusInput"
      @submit.prevent="submit"
      autocomplete="off"
    >
      <transition-group name="fadeSign" mode="out-in">
        <div v-if="step === 1" key="1" class="form-step-1 form-step">
          <div
            class="form__row"
            :class="{ 'form__row--invalid': v$.form.name.$error }"
          >
            <label for="name">{{ $t("name") }}</label>
            <input
              type="text"
              name="name"
              id="name"
              v-model="v$.form.name.$model"
              v-bind:placeholder="$t('type-name')"
            />
            <div
              class="input-errors"
              v-for="(error, index) of v$.form.name.$errors"
              :key="index"
            >
              <span class="form__row-info">
                {{ error.$message }}
              </span>
            </div>
          </div>
          <div
            class="form__row"
            :class="{ 'form__row--invalid': v$.form.surname.$error }"
          >
            <label for="surname">{{ $t("surname") }}</label>
            <input
              type="text"
              name="surname"
              id="surname"
              v-model="v$.form.surname.$model"
              v-bind:placeholder="$t('type-surname')"
            />
            <div
              class="input-errors"
              v-for="(error, index) of v$.form.surname.$errors"
              :key="index"
            >
              <span class="form__row-info">
                {{ error.$message }}
              </span>
            </div>
          </div>
          <a class="btn" href="#" @click.prevent="next()">{{ $t("continue") }}</a>
          <div class="justify-center">
            <router-link to="/signIn" class="underline">
              {{ $t("sign-in") }}
            </router-link>
          </div>
        </div>
        <div v-if="step === 2" key="2" class="form-step-2 form-step">
          <div
            class="form__row"
            :class="{ 'form__row--invalid-select': v$.form.birth_year.$error }"
          >
            <label for="name">{{ $t("birth-year") }}</label>
            <vSelect
              class="form__row"
              v-bind:placeholder="$t('select-year-birth')"
              :options="years"
              v-model="v$.form.birth_year.$model"
            />
            <div
              class="input-errors"
              v-for="(error, index) of v$.form.birth_year.$errors"
              :key="index"
            >
              <span class="form__row-info">
                {{ error.$message }}
              </span>
            </div>
          </div>
          <div
            class="form__row"
            :class="{ 'form__row--invalid-select': v$.form.country.$error }"
          >
            <label for="name">{{ $t("country") }}</label>
            <vSelect
              v-bind:placeholder="$t('select-country')"
              :options="countries"
              v-model="v$.form.country.$model"
              @keypress="isNumber(event)"
            />
            <div
              class="input-errors"
              v-for="(error, index) of v$.form.country.$errors"
              :key="index"
            >
              <span class="form__row-info">
                {{ error.$message }}
              </span>
            </div>
          </div>
          <a class="btn" href="#" @click.prevent="next()">{{ $t("continue") }}</a>
          <div class="justify-center">
            <router-link to="/signIn" class="underline">
              {{ $t("sign-in") }}
            </router-link>
          </div>
        </div>
        <div v-if="step === 3" key="3" class="form-step-3 form-step">
          <div
            class="form__row"
            :class="{ 'form__row--invalid': v$.form.email.$error }"
          >
            <label for="email">{{ $t("email") }}</label>
            <input
              type="email"
              name="email"
              id="email"
              autocomplete="false"
              v-model="v$.form.email.$model"
              v-bind:placeholder="$t('type-email')"
            />
            <div
              class="input-errors"
              v-for="(error, index) of v$.form.email.$errors"
              :key="index"
            >
              <span class="form__row-info">
                {{ error.$message }}
              </span>
            </div>
          </div>
          <div
            class="form__row"
            :class="{ 'form__row--invalid': v$.form.password.$error }"
          >
            <label for="password">{{ $t("password") }}</label>
            <input
              type="password"
              name="password"
              id="password"
              autocomplete="false"
              v-on:keyup="
                this.form.password_confirmation = $event.target.value;
                togglePass();
              "
              v-model="v$.form.password.$model"
              v-bind:placeholder="$t('***')"
              :class="{ active: pass.active }"
              @keydown="togglePass"
            />
            <div
              class="input-errors"
              v-for="(error, index) of v$.form.password.$errors"
              :key="index"
            >
              <span class="form__row-info">
                {{ error.$message }}
              </span>
            </div>
          </div>
          <div
            class="form__row"
            :class="{ 'form__row--invalid': v$.form.legal.$error }"
          >
            <label class="form__label-check">
              <input
                type="checkbox"
                name="legal"
                v-model="v$.form.legal.$model"
                @change="v$.form.legal.$touch()"
              />
              <span class="form__checkbox">
                <img src="../../assets/img/icon-check.svg" alt="checkbox"/>
              </span>
              {{ $t("legal-drinking") }}
            </label>
          </div>
          <div
            class="form__row"
            :class="{ 'form__row--invalid': v$.form.terms.$error }"
          >
            <label class="form__label-check">
              <input
                type="checkbox"
                name="terms"
                v-model="v$.form.terms.$model"
                @change="v$.form.terms.$touch()"
              />
              <span class="form__checkbox">
                <img src="../../assets/img/icon-check.svg" alt="checkbox"/>
              </span>
              <span>{{ $t("i-accept") }}</span>
              <span class="underline" @click="toggle()">{{ $t("terms-conditions") }}</span>
            </label>
          </div>
          <div
            class="form__row"
            :class="{ 'form__row--invalid': v$.form.keep_me_update.$error }"
          >
            <label class="form__label-check">
              <input
                type="checkbox"
                name="keep_me_updated"
                v-model="v$.form.keep_me_update.$model"
                @change="v$.form.keep_me_update.$touch()"
              />
              <span class="form__checkbox">
                <img src="../../assets/img/icon-check.svg" alt="checkbox"/>
              </span>
              {{ $t("keep-updated") }}
            </label>
          </div>
          <div class="sign-form__error-holder" v-if="error.errShow">
            <span>{{ error.email }}</span>
          </div>
          <button class="btn" type="submit">
            {{ $t("sign-up") }}
          </button>
          <div v-if="showReset">
            <router-link to="/forgot-password">{{ $t("forgot-pass") }}</router-link>
          </div>
        </div>
      </transition-group>
    </form>
    <Socials v-if="step !== 3"/>
    <terms-conditions/>
  </section>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import SwitchLocale from "../../components/SwitchLocale";
import FormMixin from "../../mixins/form";
import Socials from "../../components/Socials";
import vSelect from "@/assets/vue-select/src/index.js";
import {email, helpers, minLength, required} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";
import {getError} from "../../utils/helpers";
import TermsConditions from "../../views/TermsConditions.vue";

export default {
  name: "SignUp",

  components: {
    SwitchLocale,
    Socials,
    vSelect,
    TermsConditions,
  },
  mixins: [FormMixin],
  setup: () => ({v$: useVuelidate()}),
  data() {
    return {
      pass: {
        active: false,
      },
      step: 1,
      form: {
        name: "",
        surname: "",
        birth_year: "",
        country: "",
        email: "",
        password: "",
        password_confirmation: "",
        legal: false,
        terms: false,
        keep_me_update: false,
      },
      error: {
        email: "",
        errShow: false,
      },
      showReset: false,
    };
  },
  validations() {
    const year = (value) => this.years.includes(value);
    const country = (value) => this.countries.includes(value);
    const boolian = (value) => value;

    return {
      pass: {
        active: false,
      },
      form: {
        name: {
          required: helpers.withMessage(
            () => this.$t("name-required"),
            required
          ),
          minLength: minLength(2),
        },
        surname: {
          required: helpers.withMessage(
            () => this.$t("surname-required"),
            required
          ),
          minLength: minLength(2),
        },
        birth_year: {
          required: helpers.withMessage(
            () => this.$t("birth-year-required"),
            required
          ),
          year,
        },
        country: {
          required: helpers.withMessage(
            () => this.$t("country-required"),
            required
          ),
          country,
        },
        email: {
          required: helpers.withMessage(
            () => this.$t("email-required"),
            required
          ),
          email,
        },
        password: {
          required: helpers.withMessage(
            () => this.$t("password-required"),
            required
          ),
          minLength: minLength(8),
        },
        password_confirmation: {},
        legal: {
          boolian,
        },
        terms: {
          boolian,
        },
        keep_me_update: {},
      },
    };
  },
  methods: {
    ...mapActions({
      signUp: "auth/signUp",
    }),
    prev() {
      this.step--;
    },
    next() {
      let validate = false;

      if (this.step === 1) {
        validate =
          !this.v$.form.name.$invalid && !this.v$.form.surname.$invalid;
      }

      if (this.step === 2) {
        validate =
          !this.v$.form.birth_year.$invalid && !this.v$.form.country.$invalid;
      }

      if (this.step === 3) {
        validate =
          !this.v$.form.email.$invalid && !this.v$.form.password.$invalid;
      }

      if (validate) {
        this.v$.$reset();
        this.step++;
      } else {
        this.v$.$validate();
      }
    },
    async submit() {
      this.v$.$validate();

      this.error = {
        email: "",
        errShow: false,
      };
      this.showReset = false;

      if (!this.v$.$error) {
        this.v$.$reset();
        await this.signUp(this.form)
          .then(() => {
            this.$router.replace({name: "home"});
            this.$gtm.trackEvent({
              event: 'registro',
            })
            if(this.wine && this.wine.rating.colour && this.wine.rating.aroma && this.wine.rating.flavour) {
              this.$store.dispatch("wine/PASSPORT", [
                this.wine,
                {
                  aroma_rating: this.wine.rating.aroma,
                  flavour_rating: this.wine.rating.flavour,
                  colour_rating: this.wine.rating.colour
                }
              ]);
              console.log('Después del registro registrar passport')
            }
          })
          .catch((error) => {
            let errors = getError(error);
            if (errors.email.length) {
              this.error.errShow = true;
              this.error.email = errors.email[0];
              if (this.error.email == "The email has already been taken.") {
                this.showReset = true;
              }
            }
          });
      }
    },
    togglePass() {
      if (this.isEmpty() == 0) {
        this.pass.active = false;
      } else if (this.isEmpty() != 0) {
        this.pass.active = true;
      } else {
        this.pass.active = false;
      }
    },
    isEmpty() {
      return this.form.password.length;
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      let charCode = evt.which ? evt.which : evt.keyCode;
      if (
        !(charCode > 31 && (charCode < 48 || charCode > 57)) ||
        charCode == 44 ||
        charCode == 46 ||
        charCode == 47
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    toggle() {
      let terms = document.querySelector(".terms");

      if (terms.classList.contains("active")) {
        terms.classList.remove("active");
      } else {
        terms.classList.add("active");
      }
    },
  },
  computed: {
    ...mapGetters("setting", ["countries"]),
    ...mapGetters("wine", ["wine"]),
    years: function () {
      let year = new Date().getFullYear() - 18;
      let i;
      let years = [];
      for (i = 0; i <= 100; i++) {
        years.push(year - i);
      }

      return years;
    },
  },
  mounted() {
    this.$store.cache.dispatch("setting/GET_COUNTRIES");

    let app = document.querySelector("#app");
    app.classList = "";
    app.classList.add("signUp");

    this.$store.commit("headerHide");
    this.$store.commit("navHide");
    this.$store.commit("popupHidden", true);

    this.focusInput();
  },
  unmounted() {
    if (this.$cookies.get("popShow")) {
      this.$store.commit("popupHidden", false);
    }
  },
};
</script>
