<template>
  <section class="sign">
    <SwitchLocale/>
    <router-link class="logo" to="/">
      <img src="../../assets/img/logo.svg" alt="Trivento Wine Compass"/>
    </router-link>
    <h1>{{ $t("sign-in") }}</h1>
    <form class="form" action="#" @submit.prevent="submit">
      <div
        class="form__row"
        :class="{ 'form__row--invalid': v$.form.email.$error }"
      >
        <label for="email">{{ $t("email") }}</label>
        <input
          type="email"
          name="email"
          id="email"
          v-model="v$.form.email.$model"
          v-bind:placeholder="$t('type-email')"
        />
        <div
          class="input-errors"
          v-for="(error, index) of v$.form.email.$errors"
          :key="index"
        >
          <span class="form__row-info">
            {{ error.$message }}
          </span>
        </div>
      </div>
      <div
        class="form__row"
        :class="{ 'form__row--invalid': v$.form.password.$error }"
      >
        <label for="password">{{ $t("password") }}</label>
        <input
          type="password"
          name="password"
          id="password"
          v-model="v$.form.password.$model"
          v-bind:placeholder="$t('***')"
          :class="{ active: pass.active }"
          @keydown="togglePass"
          @keyup="togglePass"
        />
        <div
          class="input-errors"
          v-for="(error, index) of v$.form.password.$errors"
          :key="index"
        >
          <span class="form__row-info">
            {{ error.$message }}
          </span>
        </div>
      </div>
      <button class="btn" type="submit">
        {{ $t("sign-in") }}
      </button>
      <div class="sign-form-error" v-if="error.errShow">
        <span>{{ error.email }}</span>
      </div>

      <div class="justify-between">
        <router-link to="/forgot-password">{{ $t("forgot-pass") }}</router-link>
        <router-link class="underline" to="/signUp">{{ $t("sign-up") }}</router-link>
      </div>
    </form>
  </section>
</template>

<script>
import {mapActions} from "vuex";
import SwitchLocale from "../../components/SwitchLocale";
import useVuelidate from "@vuelidate/core";
import {email, helpers, minLength, required} from "@vuelidate/validators";
import FormMixin from "../../mixins/form";
import {getError} from "../../utils/helpers";

export default {
  name: "SignIn",

  components: {
    SwitchLocale,
  },
  mixins: [FormMixin],
  setup: () => ({v$: useVuelidate()}),
  data() {
    return {
      pass: {
        active: false,
      },
      form: {
        email: "",
        password: "",
      },
      error: {
        email: "",
        errShow: false,
      },
      message: null,
    };
  },
  validations() {
    return {
      form: {
        email: {
          required: helpers.withMessage(
            () => this.$t("email-required"),
            required
          ),
          email,
        },
        password: {
          required: helpers.withMessage(
            () => this.$t("password-required"),
            required
          ),
          minLength: minLength(8),
        },
      },
    };
  },
  methods: {
    ...mapActions({
      signIn: "auth/signIn",
    }),
    async submit() {
      this.error = {
        email: "",
        errShow: false,
      };
      this.message = null;

      this.v$.$validate();

      if (!this.v$.$error) {
        this.v$.$reset();

        await this.signIn(this.form)
          .then(() => {
            this.$router.replace({name: "home"});
            this.$gtm.trackEvent({
              event: 'login',
            })
          })
          .catch((error) => {
            let errors = getError(error);
            if (errors.email.length) {
              this.error.errShow = true;
              this.error.email = errors.email[0];
            } else {
              // TODO: overwrite with response status 403 ?
              this.error.errShow = true;
              this.error.email = "Please, verify your email address"
            }
          });
      }
    },
    togglePass() {
      if (this.isEmpty() == 0) {
        this.pass.active = false;
      } else if (this.isEmpty() != 0) {
        this.pass.active = true;
      } else {
        this.pass.active = false;
      }
    },
    isEmpty() {
      return this.form.password.length;
    },
  },
  mounted() {
    let app = document.querySelector("#app");
    app.classList = "";
    app.classList.add('signIn');

    this.$store.commit("headerHide");
    this.$store.commit("navHide");
    this.$store.commit("popupHidden", true);

    this.focusInput();

    this.$store.commit("popupHidden", true);
  },
  unmounted() {
    if (this.$cookies.get("popShow")) {
      this.$store.commit("popupHidden", false);
    }
  },
};
</script>
