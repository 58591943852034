<template>
  <section class="stories">
    <Close :to="`/explore/${$route.params.slug}`"/>
    <Stories :stories="stories" :interval="12000">
      <template
        v-for="story in wine.stories"
        :key="story.id"
        v-slot:[story.id]
      >
        <div>
          <img
            class="stories__bg"
            v-lazy="story.image"
            :alt="story.title[ this.$i18n.locale ]"
          />
          <div
            class="stories__info-holder"
            :class="'stories__info-holder--'+story.text_position"
          >
            <div
              class="stories__info-inner"
              :style="{
                'background-color': story.text_background_color,
                'color': story.text_color
              }"
            >
              <h1>{{ story.title[ this.$i18n.locale ] }}</h1>
              <p>{{ story.description[ this.$i18n.locale ] }}</p>
            </div>
          </div>
        </div>
      </template>

      <template
        v-for="component in additional"
        :key="component"
        v-slot:[component]
      >
        <component :is="component" class="story__content-holder" />
      </template>
    </Stories>
  </section>
</template>

<script>
import {Stories} from "@/assets/vue-insta-stories";
import {mapActions, mapGetters} from 'vuex';
import Close from '../../../components/Close';
import GoToVivino from './GoToVivino';
import Subscribe from './Subscribe';
import RateWine from './RateWine';

export default {
  name: "Story",

  components: {
    Close,
    Stories,
    GoToVivino,
    Subscribe,
    RateWine,
  },
  data() {
    return {
      templates: {
        default: ['GoToVivino', 'Subscribe'],
        member: ['RateWine'],
        rated: ['RateWine', 'AddNode', 'ThankYou'],
      }
    };
  },
  computed: {
    ...mapGetters("wine", ["wine", 'getWine']),
    ...mapGetters("auth", ["authenticated"]),
    stories: function () {
      let templates = [];
      this.wine.stories.forEach(
          story => templates.push({ template: (story.id).toString() })
      );

      let additional = this.templates.default;
      if (this.authenticated) {
        additional = this.templates.member;
      }
      if (this.$store.state.isMember && this.$store.state.isRatedWine) {
        let additional = this.templates.rated;
      }

      additional.forEach(
        template => templates.push({ template: template })
      )

      return templates;
    },
    additional: function () {
      let additional = this.templates.default;
      if (this.authenticated) {
        additional = this.templates.member;
      }
      if (this.$store.state.isMember && this.$store.state.isRatedWine) {
        additional = this.templates.rated;
      }

      return additional;
    }
  },
  created() {
    const verify = this.getWine(this.$route.params.slug)
    if(verify) {
      this.GET_WINE(this.$route.params.slug)
    }
  },
  mounted() {
    let app = document.querySelector("#app");
    app.classList = "";

    this.$store.commit("headerHide");
    this.$store.commit("navHide");
  },
  methods: {
    ...mapActions('wine', ['GET_WINE'])
  }
};
</script>
