<template>
  <div class="rateUs">
    <img class="stories__bg" src="@/assets/img/story-4.jpeg" alt="story-bg"/>
    <div class="stories__info-holder">
      <h1>{{ $t("rate-wine") }}</h1>
      <p>{{ description }}</p>
      <Rating
        v-model="rating"
        :stars="5"
        :cancel="false"
        :readonly="false"
      />
      <router-link
        class="btn"
        @touchstart="clickPass"
        to="/passport"
      >
        {{ $t("go-to-passport") }}
      </router-link>
      <router-link
        class="btn btn--light"
        @touchstart="clickNotes"
        to="/addNotes"
      >
        {{ $t("add-notes") }}
      </router-link
      >
    </div>
  </div>
</template>

<script>
import Rating from "primevue/rating";
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import {mapGetters} from "vuex";

export default {
  name: "RateWine",

  components: {
    Rating
  },
  data() {
    return {
      isRated: false,
      rating: 0,
      description: ''
    };
  },
  methods: {
    clickPass() {
      let btn = document.querySelector('[href="/passport"]');
      btn.click();
    },
    clickNotes() {
      let btn = document.querySelector('[href="/addNotes"]');
      btn.click();
    },
  },
  computed: {
    ...mapGetters("wine", ["wine"]),
  },
  mounted() {
    this.rating = this.wine.rating.wine;
    this.description = this.wine.description[this.$i18n.locale];

    this.$store.commit("headerHide");
    this.$store.commit("navHide");

    let app = document.querySelector("#app");
    if (app) {
      app.style.overflowY = "hidden";
    }

    let icons = document.querySelectorAll(".p-rating-icon");
    let eventList = ["touchstart", "mousedown"];

    for (let i = 0; i < icons.length; i++) {
      for (let j = 0; j < eventList.length; j++) {
        icons[i].addEventListener(eventList[j], event => {
          this.rating = i + 1;

          this.$store.commit("wine/SET_WINE_RATE", this.rating);

          this.$store.dispatch("wine/PASSPORT", [
            this.wine,
            {
              wine_rating: this.rating
            }
          ]);
        });
      }
    }

  },
  unmounted() {
    let app = document.querySelector("#app");
    app.style.overflowY = "initial";
  }
};
</script>
