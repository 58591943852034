<template>
  <section class="portfolio">
    <Close :to="`/explore/${$route.params.slug}`" stroke="#000000"/>
    <Stories :stories="stories" :interval="12000">
      <template
        v-for="portfolio in wine.portfolio"
        :key="portfolio.id"
        v-slot:[portfolio.id]
      >
        <div class="portfolio__content-holder">
          <img v-lazy="portfolio.image" :alt="portfolio.name"/>
          <h1>{{ portfolio.name }}</h1>
          <p>{{ portfolio.description[this.$i18n.locale] }}</p>
        </div>
      </template>
    </Stories>
  </section>
</template>

<script>
import {Stories} from "@/assets/vue-insta-stories";
import {mapActions, mapGetters} from 'vuex';
import Close from '../../components/Close';

export default {
  name: "Portfolio",

  components: {
    Close,
    Stories,
  },
  created() {
    const verify = this.getWine(this.$route.params.slug)
    if(verify) {
      this.GET_WINE(this.$route.params.slug)
    }
  },
  computed: {
    ...mapGetters("wine", ["wine", 'getWine']),
    stories: function () {
      let templates = [];
      this.wine.portfolio.forEach(function (portfolio) {
        templates.push({template: (portfolio.id).toString()})
      });

      return templates;
    },
  },
  mounted: function () {
    this.$store.commit("headerHide");
    this.$store.commit("navHide");

    // CHANGE BG on class
    let app = document.querySelector("#app");

    app.className = "";
    app.classList.add("isPortfolio");

    let body = document.querySelector("body");
    let html = document.querySelector("html");

    body.classList.add('fixedHeight')
    html.classList.add('fixedHeight')
  },
  methods: {
    ...mapActions('wine', ['GET_WINE'])
  }
};
</script>
