import axios from "axios";

export default {
  namespaced: true,

  state: {
    authenticated: false,
    user: null,
    error: null
  },

  getters: {
    authenticated(state) {
      return state.authenticated;
    },
    user(state) {
      return state.user;
    },
    error: state => {
      return state.error;
    }
  },

  mutations: {
    SET_AUTHENTICATED(state, value) {
      state.authenticated = value;
    },
    SET_USER(state, value) {
      state.user = value;
    },
    SET_ERROR(state, error) {
      state.error = error;
    }
  },

  actions: {
    async signIn({dispatch}, credentials) {
      await axios.get("/sanctum/csrf-cookie");
      await axios.post("/login", credentials);
      return dispatch("me");
    },
    async signUp({dispatch}, payload) {
      await axios.get("/sanctum/csrf-cookie");
      await axios.post("/register", payload);
      return dispatch("me");
    },
    async FORGOT_PASSWORD({commit}, payload) {
      await axios.get("/sanctum/csrf-cookie");
      return axios.post("/forgot-password", payload);
    },
    async RESET_PASSWORD({dispatch}, payload) {
      await axios.get("/sanctum/csrf-cookie");
      return axios.post("/reset-password", payload);
    },
    async signOut({dispatch}) {
      await axios.post("/logout");
      return dispatch("me");
    },
    async EMAIL_RESEND({dispatch}) {
      await axios.get("/sanctum/csrf-cookie");
      return axios.post("/email/resend");
    },
    async GET_USER({dispatch}) {
      await axios.get("/sanctum/csrf-cookie");
      return dispatch("me");
    },
    me({commit}) {
      return axios
        .get("/api/user")
        .then(response => {
          commit("SET_AUTHENTICATED", true);
          commit("SET_USER", response.data.data);
        })
        .catch(() => {
          commit("SET_AUTHENTICATED", false);
          commit("SET_USER", null);
        });
    }
  }
};
