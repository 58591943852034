import axios from 'axios'

export default {
  namespaced: true,

  state: {
    palates: [],
    error: null,
  },

  getters: {
    palates: (state) => {
      return state.palates
    },
    error: (state) => {
      return state.error;
    },
  },

  mutations: {
    SET_PALATES(state, palates) {
      state.palates = palates
    },
    SET_ERROR(state, error) {
      state.error = error;
    },
  },

  actions: {
    GET_PALATES: async ({commit}) => {
      await axios.get('/sanctum/csrf-cookie')
      return axios.get('/api/palates')
    },
    SET_PALATE_TEMP: async ({commit}, params) => {
      return axios.post('/api/palates-temp', params)
    }
  }
}
