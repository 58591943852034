<script>
import {Radar} from "vue3-chart-v2";

export default {
  name: 'RadarChart',

  extends: Radar,
  props: {
    chartData: {
      type: Object,
      required: true
    },
  },
  mounted () {
      this.renderChart(this.chartData, {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false
      },
      scale: {
        min: 0,
        max: 6,
        gridLines: {
          circular: true,
          color: "#EAE8D8"
        },
        ticks: {
          beginAtZero: true,
          display: true,
          backdropColor: "#2D2D2F",
          fontColor: "#fff",
        },
        angleLines: {
          color: "#EAE8D8"
        },
        labels: {
          color: "#fff"
        },
        pointLabels: {
          fontColor: "#fff",
          fontSize: 12,
        }
      }
    })
  }
};
</script>
