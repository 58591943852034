{
  "add-home": "Criar atalho",
  "add-notes": "Faça anotações",
  "add-notes-descr": "Este é seu espaço pessoal para anotar todas as ideias que surgirem durante suas aventuras no universo do vinho.",
  "add_homescreen_app": "Toque para adicionar à tela principal",
  "age-location-text": "Você está prestes a começar sua aventura no Wine Compass da Trivento. Só precisamos saber onde você mora e se tem idade legal para beber.",
  "aroma": "Aroma",
  "aroma-rate-text": "Você gostou do aroma desse vinho?",
  "become-member": "Entre para o clube",
  "become-member-text": "Você tem espírito de aventura? Explore outras paragens do Wine Compass",
  "birth-year": "Ano de nascimento",
  "birth-year-required": "Para continuar, digite seu ano de nascimento",
  "bodega-trivento": "Bodega Trivento Argentina",
  "build-taste": "Monte o perfil do seu paladar",
  "caf-thank-you": "Suas pontuações nos permitem selecionar e mostrar outros vinhos da Trivento que você vai adorar ❤",
  "choose-bottle": "Escolha o rótulo da Trivento que quer conhecer",
  "choose-option": "Escolha uma experiência",
  "club": "CLUBE",
  "colour": "Cor",
  "colour-aroma": "Cor, aroma e sabor",
  "colour-rate-text": "Você gostou da cor desse vinho?",
  "come-back": "Volte mas tarde",
  "confirm": "CONFIRMAR",
  "congrats": "Parabéns",
  "contact-us": "CONTATO",
  "continue": "CONTINUAR",
  "country": "País de residência",
  "country-required": "Para continuar, selecione o país",
  "created-pass": "Criamos uma adega para você. Agora, você pode avaliar vinhos diretamente em nosso site",
  "did-not-receive": "Se o e-mail não tiver chegado",
  "discover": "PESQUISAR",
  "edit-profile": "EDITE SEU PERFIL",
  "email": "E-mail",
  "email-confirm": "Seu endereço de e-mail foi alterado",
  "email-required": "Para continuar, digite seu e-mail",
  "enjoy": "CURTIR",
  "explore": "EXPLORAR",
  "explore-portfolio": "Explore o portfólio",
  "file-greater": "O arquivo tem mais de 2 MB",
  "flavour": "Sabor",
  "flavour-rate-text": "Você gostou do sabor desse vinho?",
  "forgot-pass": "Esqueceu sua senha?",
  "fresh-verification-link": "O link de verificação foi enviado novamente por e-mail",
  "go-to-palate": "Ver meu paladar",
  "go-to-passport": "Ir à sua adega",
  "goes-well": "Harmonização",
  "harvest": "SAFRA",
  "hide-taste": "Ocultar minha degustação",
  "home": "INÍCIO",
  "i-accept": "Aceito",
  "id-be-happy": "Sem dúvida!",
  "journey-descr": "Cruz del Alto, outono de 2016",
  "keep-updated": "Quero receber notícias",
  "know-story": "Conheça a história",
  "legal-drinking": "Sou maior de idade",
  "make-note": "Faça suas anotações",
  "mendoza-argentina": "Mendoza, Argentina",
  "my-cellar": "Minha adega",
  "my-taste": "Minha degustação",
  "name": "Nome",
  "name-required": "Para continuar, digite seu nome",
  "new": "Nova",
  "news-for-you": "Notícias para você",
  "no": "Não",
  "nothing": "Ainda não há nada aqui",
  "ok": "OK",
  "opinion-important": "Sua opinião é importante para nós.",
  "palate": "Seu paladar",
  "palate-empty": "Não há nada aqui",
  "password": "Senha",
  "password-required": "Para continuar, digite sua senha",
  "podcast": "EPISÓDIOS DO PODCAST",
  "profile": "Seu perfil",
  "profile-update-confirm": "Seu perfil foi atualizado",
  "promotion": "PROMOÇÕES",
  "rate-wine": "Avalie seu vinho",
  "request-again": "Solicite outro",
  "request-password-reset": "Solicitar redefinição de senha",
  "resend-pass": "Enviar as instruções de recuperação de senha novamente",
  "resend-pass-again": "Aguarde {0} segundos para enviar o e-mail de redefinição de senha novamente",
  "reset-pass": "Redefinir senha",
  "reset-pass-email-sent": "O e-mail de redefinição de senha foi enviado",
  "restore-conf-pass": "Confirmar nova senha",
  "restore-create-pass": "Criar nova senha",
  "restore-enter-email": "Para recuperar sua senha, digite o e-mail associado à conta",
  "restore-new-pass": "Nova senha",
  "restore-pass": "Redefinir senha",
  "restore-pass-reset": "Sua senha foi redefinida. Use sua nova senha para entrar",
  "restore-unic-pass": "A senha deve ter pelo menos 8 caracteres",
  "restore-we-have-sent": "As instruções de recuperação de senha foram enviadas ao seu endereço de e-mail",
  "rights-reserved": "Todos os direitos reservados 2021",
  "same-as-password": "As senhas não correspondem",
  "save-changes": "SALVAR",
  "save-note": "Salvar anotação",
  "save-notes": "Salvar",
  "select-country": "Para continuar, selecione o país",
  "select-year-birth": "Selecione seu ano de nascimento",
  "shop": "COMPRAR",
  "show-taste": "Mostrar minha degustação",
  "sign-in": "Entrar",
  "sign-out": "Sair",
  "sign-up": "Abrir conta",
  "spare-minutes": "Tem alguns minutos para nos avaliar no Vivino?",
  "subscribe": "Quero explorar mais",
  "subscribed-newsletter": "Assinar o boletim",
  "surname": "Sobrenome",
  "surname-required": "Para continuar, digite seu sobrenome",
  "taste-palate": "SEU PALADAR",
  "terms-conditions": "os Termos e Condições",
  "thank-you": "Obrigado!",
  "thank-you-member": "OBRIGADO POR ENTRAR PARA O CLUBE",
  "to-all-wines": "Ver todos os vinhos",
  "trivento-wines": "Vinhos da Trivento",
  "type-email": "Forneça seu e-mail",
  "type-name": "Digite seu nome",
  "type-surname": "Digite seu sobrenome",
  "variety": "VARIEDADE",
  "vendors": "Vendedores",
  "visit-trivento": "Visite Trivento.com",
  "visit-website": "VISITE NOSSO SITE",
  "vivino-website": "Ir ao Vivino",
  "welcome": "SINTA-SE EM CASA!",
  "wine": "VINHO",
  "wine-added": "O vinho e a nota foram adicionados ao seu passaporte.",
  "wine-journal": "Diário de vinhos",
  "write-notes": "Escreva aqui",
  "yes": "Sim",
  "your-cellar": "Sua adega",
  "your-journey": "Suas aventuras",
  "your-rate": "Sua nota para esse vinho",
  "accept-term": "Aceito os Termos e Condições",
  "no-promotions": "Informe-se sobre as últimas promoções nos websites das lojas.",

  "enable-geolocation": "Ativar geolocalização",
  "geolocation-description": "O Trivento Wine Compass deseja acessar sua localização para fornecer conteúdo personalizado. Para ativá-lo, siga estes passos:",
  "geolocation_step_1": "Acesse a opção \"Configurações\" em seu telefone.",
  "geolocation_step_2": "Selecione a opção \"Privacidade e Segurança\".",
  "geolocation_step_3": "Escolha a opção \"Localização\".",
  "geolocation_step_4": "Encontre o navegador que você está usando e selecione a opção \"Enquanto usa o aplicativo\".",
  "wines": "Vinhos",
  "experiences": "Experiências"
}
