<template>
  <section class="enjoy">
    <div v-if="enjoys.length !== 0">
      <a
        v-for="enjoy in enjoys"
        :key="enjoy.id"
        @click.prevent="goLink(enjoy)"
        class="enjoy__item"
        :class="'enjoy__item--' + enjoy.align_content"
        :style="{
          'background-color': enjoy.background_color,
          'color': enjoy.text_color
        }"
        target="_blank"
      >
        <img v-lazy="enjoy.image" :alt="enjoy.name[this.$i18n.locale]" />
        <div class="enjoy__item-content">
          <h2>{{ enjoy.name[this.$i18n.locale] }}</h2>
          <p>{{ enjoy.description[this.$i18n.locale] }}</p>
        </div>
      </a>
    </div>
    <nothing v-else />
  </section>
</template>

<script>
import {mapGetters} from "vuex";
import Nothing from '../components/Nothing';

export default {
  name: "Enjoy",

  components: {
    Nothing
  },
  computed: {
    ...mapGetters("enjoy", ["enjoys"])
  },
  mounted() {
    const params = this.$route.query;
    if(Object.prototype.hasOwnProperty.call(params, 'enjoy')) {
      this.$store.dispatch('enjoy/GET_ENJOY_PREVIEW', params.enjoy);
    } else {
      let countryOfUser = this.$cookies.get('country');
      this.$store.cache.dispatch('enjoy/GET_ENJOYS', countryOfUser);

      this.$store.commit("navShow");
      this.$store.commit("linkBackDisable");
      this.$store.commit("burgerShow");
      this.$store.commit("userShow");

      let app = document.querySelector("#app");

      app.className = "";
      app.classList.add("isEnjoy");
    }
  },
  methods: {
    goLink(enjoy) {
      const name = enjoy.name['en']
      this.$gtm.trackEvent({
        event: 'enjoy',
        action: 'click',
        label: name,
        value: name
      })
      window.open(enjoy.link)
    }
  }
};
</script>
