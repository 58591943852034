<template>
  <section class="becomeMember story-custom">
    <router-link to="/exploreWine">
      <svg
        class="stories__close"
        width="23"
        height="23"
        viewBox="0 0 23 23"
        fill="red"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 2L20.7949 20.7949"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M21.4602 2L2.66532 20.7949"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </router-link>
    <img src="@/assets/img/member-bg.jpeg" :alt="$t('thank-you-member')"/>
    <div class="story-custom__content">
      <h1>{{ $t("thank-you-member") }}</h1>
      <p>{{ $t("created-pass") }}</p>
      <router-link class="btn" to="/rateWine">
        {{ $t("rate-wine") }}
      </router-link>
    </div>
  </section>
</template>

<script>
export default {
  name: "BecomeMember",

  mounted() {
    this.$store.commit("headerHide");
    this.$store.commit("navHide");
  },
};
</script>
