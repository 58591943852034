<template>
  <section class="popup" v-if="popup && isPopupHidden() && !isIntro()">
    <div class="container_popup">
      <div
          class="header__menu-close"
          @click="close"
          @touchstart="close"
        >
        <span></span>
        <span></span>
      </div>
      <div class="container_popup_content">
        <h1 class="popup_title">{{ popup.title[this.$i18n.locale] }}</h1>
        <br>
        <div>
          <p>{{ popup.description[this.$i18n.locale] }}</p>
          <br>
          <button type="button" @click="toPrmotions" class="btn">{{ $t("continue") }}</button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {mapState} from "vuex";
export default {
  name: "Popup",
  data() {
    return {
      popupHidden: this.$store.state.popup.popupHidden
    };
  },
  computed: {
    ...mapState("popup", ["popup"]),
  },
  methods: {
    toPrmotions() {
      this.$router.push('/promotions');
      this.close()
    },
    close() {
      this.popupHidden = this.$store.commit("popup/popupHidden", false);
      this.setCookie("popupShow", "false", "7d");
    },
    setCookie(name, val, expire) {
      this.$cookies.set(name, val, expire);
    },
    isPopupHidden() {
      return this.$store.state.popup.popupHidden;
    },
    isIntro() {
      let page = document.querySelector(".isAge");
      return page;
    },
  }
};
</script>
