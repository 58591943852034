<template>
  <section class="shop-details">
    <h1>{{ $t("vendors") }}</h1>
    <div v-if="vendors.length !== 0">
      <div v-for="vendor in vendors" :key="vendor.id" >
        <a
          @click.prevent="toPopup(vendor)"
          class="shop-details__item shop-details__item--blue-light"
          href="#"
          target="_blank"
        >
          <div class="shop-details__img-holder">
            <img
              v-lazy="vendor.image"
              :alt="vendor.shop_name[ this.$i18n.locale ]"
            />
          </div>
          <div class="shop-details__item-content">
            <h2>{{ vendor.shop_name[ this.$i18n.locale ] }}</h2>
            <p>{{ vendor.description[ this.$i18n.locale ] }}</p>
          </div>
        </a>
      </div>
    </div>
    <nothing v-else />
  </section>
</template>

<script>
import {mapGetters} from 'vuex';
import Nothing from '../components/Nothing';

export default {
  name: "Vendors",

  components: {
    Nothing
  },
  computed: {
    ...mapGetters("vendor", ["vendors"]),
  },
  mounted() {
    const params = this.$route.query;
    if(Object.prototype.hasOwnProperty.call(params, 'vendor')) {
      this.$store.dispatch('vendor/GET_VENDOR_PREVIEW', params.vendor);
    } else {
      let countryOfUser = this.$cookies.get('country');
      let stateOfUser = this.$cookies.get("state");
      let zipCode = this.$cookies.get("zip_code");
      this.$store.cache.dispatch('vendor/GET_VENDORS', {countryOfUser, stateOfUser, zipCode});

      this.$store.commit("navShow");
      this.$store.commit("linkBackDisable");
    }
  },
  methods: {
    toPopup(vendor) {
      const name = vendor.shop_name['en']
      this.$gtm.trackEvent({
        event: 'vendor',
        action: 'click',
        label: name,
        value: name
      })
      window.open(vendor.link)
    }
  }
};
</script>
