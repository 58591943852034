<template>
  <div>
    <img
      class="stories__bg"
      src="@/assets/img/caf-end-bg.jpeg"
      :alt="$t('colour')"
    />
    <h1 class="text-align-center colour__underline">
      {{ $t("thank-you") }}
    </h1>
    <div class="stories__info-holder stories__info-holder--bottom-rate">
      <p class="text-align-center">
        {{ $t("caf-thank-you") }}
      </p>
      <router-link @touchstart="clickPass" to="/palate" class="btn">
        {{ $t("go-to-palate") }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "ThankYou",
  methods: {
    clickPass() {
      let btn = document.querySelector('[href="/palate"]');
      btn.click();
    },
  },
};
</script>
