import {createRouter, createWebHistory} from "vue-router";
import Home from "../views/Home.vue";
import AgeLocation from "../views/AgeLocation.vue";
import Discover from "../views/Discover.vue";
import Explore from "../views/Explore.vue";
import Enjoy from "../views/Enjoy.vue";
import Shop from "../views/Shop.vue";
import ExploreWine from "../views/ExploreWine.vue";
import Portfolio from "../views/Portfolio.vue";
import Story from "../views/Story.vue";
import Pair from "../views/Pair.vue";
import Caf from "../views/caf/Index.vue";
import ForgotPassword from "../views/auth/ForgotPassword";
import TermsConditions from "../views/TermsConditions";
import SignUp from "../views/auth/SignUp";
import SignIn from "../views/auth/SignIn";
import ResetPassword from "../views/auth/ResetPassword";
import Profile from "../views/Profile";
import News from "../views/News";
import Promotions from "../views/Promotions";
import Club from "../views/news/Club";
import NewsPromotions from "../views/news/Promotions";
import Journey from "../views/Journey";
import Palate from "../views/journey/Palate";
import Passport from "../views/journey/Passport";
import PassportDetails from "../views/journey/PassportDetails";
import BecomeMember from "../views/BecomeMember";
import RateWine from "../views/rateWine/RateWine";
import AddNotes from "../views/rateWine/AddNotes";
import WineAdded from "../views/rateWine/WineAdded";
import Vendors from '../views/Vendors';

import WineDetail from '../views/wines/Detail'
import CafDetail from "../views/wines/caf/Index.vue"
import StoryDetail from '../views/wines/stories/Story'
import PairDetail from '../views/wines/Pair'
import PortfolioDetail from '../views/wines/Portfolio'

import store from "@/store";
import VueCookies from "../assets/vue-cookies";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home
  },
  {
    path: "/ageLocation",
    name: "AgeLocation",
    component: AgeLocation
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile
  },
  {
    path: "/news",
    name: "News",
    component: News
  },
  {
    path: "/promotions",
    name: "Promotions",
    component: Promotions
  },
  {
    path: "/club",
    name: "Club",
    component: Club
  },
  {
    path: "/journey",
    name: "Journey",
    component: Journey
  },
  {
    path: "/palate",
    name: "Palate",
    component: Palate
  },
  {
    path: "/passport",
    name: "Passport",
    component: Passport
  },
  {
    path: "/passport-details",
    name: "PassportDetails",
    component: PassportDetails
  },
  {
    path: "/discover",
    name: "Discover",
    component: Discover
  },
  {
    path: "/enjoy",
    name: "Enjoy",
    component: Enjoy
  },
  {
    path: "/shop",
    name: "Shop",
    component: Shop
  },
  {
    path: "/vendors",
    name: "Vendors",
    component: Vendors
  },
  {
    path: "/promotions",
    name: "Promotions",
    component: Promotions
  },
  {
    path: "/newsPromotions",
    name: "NewsPromotions",
    component: NewsPromotions
  },
  {
    path: "/explore",
    name: "Explore",
    component: Explore,
  },
  {
    path: "/explore/:slug",
    name: "ExploreDetail",
    component: WineDetail,
  },
  {
    path: "/explore/:slug/caf",
    name: "CafDetail",
    component: CafDetail,
  },
  {
    path: "/explore/:slug/stories",
    name: "StoryDetail",
    component: StoryDetail,
  },
  {
    path: "/explore/:slug/pair",
    name: "PairDetail",
    component: PairDetail,
  },
  {
    path: "/explore/:slug/portfolio",
    name: "PortfolioDetail",
    component: PortfolioDetail,
  },
  {
    path: "/exploreWine",
    name: "ExploreWine",
    component: ExploreWine
  },
  {
    path: "/portfolio",
    name: "Portfolio",
    component: Portfolio
  },
  {
    path: "/stories",
    name: "Stories",
    component: Story
  },
  {
    path: "/caf",
    name: "Caf",
    component: Caf
  },
  {
    path: "/pair",
    name: "Pair",
    component: Pair
  },
  {
    path: "/terms",
    name: "TermsConditions",
    component: TermsConditions
  },
  {
    path: "/signIn",
    name: "SignIn",
    component: SignIn
  },
  {
    path: "/signUp",
    name: "SignUp",
    component: SignUp
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
    meta: {
      auth:false
    }
  },
  {
    path: "/reset-password/:token",
    name: "ResetPassword",
    component: ResetPassword,
    meta: {
      auth: false
    }
  },
  {
    path: "/becomeMember",
    name: "BecomeMember",
    component: BecomeMember
  },
  {
    path: "/rateWine",
    name: "RateWine",
    component: RateWine
  },
  {
    path: "/addNotes",
    name: "AddNotes",
    component: AddNotes
  },
  {
    path: "/wineAdded",
    name: "WineAdded",
    component: WineAdded
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

const getLocationByIp = async() => {
  const {data} = await store.dispatch("GET_CLIENT_IP_ADDRESS")
  if(data.country) {
    setCookieDataLocation(data)
  }
}

const setCookieDataLocation = (data) => {
  window.$cookies.set("locale", data.country.languages[0], "30d");
  window.$cookies.set("state", data.state, "30d");
  window.$cookies.set("zip_code", data.zip_code, "30d");
  window.$cookies.set("country", data.country.name, "30d");
  window.$cookies.set("locales", data.country.languages, "7d");

  store.commit('setting/SET_LOCALES', data.country.languages);
  store.dispatch("setting/SET_LOCALE", data.country.languages[0])
}
const getLocationByGeoCode = async(lat, long) => {
  const {data} = await store.dispatch("GET_GEOLOCATION", {
    latitude: lat,
    longitude: long,
  });
  if(data.success) {
    setCookieDataLocation(data)
  }
}

const getLatAndLong = async() => {
  function success(position) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;

    const latCookie = window.$cookies.get("latitude")
    const longCookie = window.$cookies.get("longitude")

    // if(latitude !== parseFloat(latCookie) || longitude !== parseFloat(longCookie)) {
      window.$cookies.set("latitude", latitude, "30d");
      window.$cookies.set("longitude", longitude, "30d");
      store.commit('popupLocationShow', false);
      window.$cookies.set("geolocation", true, "7d");
      getLocationByGeoCode(latitude, longitude)
    // }
  }

  function error() {
    console.log('Unable to retrieve your location!!!')
    const popupLocation = VueCookies.get("popupLocationShow")
    if(popupLocation !== 'false') {
      store.commit('popupLocationShow', true)
    }
    // getLocationByIp()
  }

  if ("geolocation" in navigator) {
    await navigator.geolocation.getCurrentPosition(success, error);
  } else {
    console.log('geolocation IS NOT available')
    // await getLocationByIp()
  }
}

router.beforeEach(async (to, from) => {
  const geoLocation = window.$cookies.get("geolocation")

  if(geoLocation !== 'true'){
    await getLocationByIp()
  }
  await getLatAndLong()
})

export default router;
