import axios from 'axios'

export default {
  namespaced: true,

  state: {
    discovers: [],
    error: null,
  },

  getters: {
    discovers: (state) => {
      return state.discovers
    },
    error: (state) => {
      return state.error;
    },
  },

  mutations: {
    SET_DISCOVERS(state, discovers) {
      state.discovers = discovers
    },
    SET_ERROR(state, error) {
      state.error = error;
    },
    ADD_TO_DISCOVERS(state, discover) {
      state.discovers.push(discover);
    }
  },

  actions: {
    GET_DISCOVERS: async ({commit}, country) => {
      commit('loading', true, {root: true})

      await axios.get(`/api/discovers/${country}`)
        .then((response) => {
          commit('SET_DISCOVERS', response.data.data)
          commit('loading', false, {root: true})
        }).catch((error) => {
          commit('SET_DISCOVERS', [])
          commit('loading', false, {root: true})
        })
    },
    GET_DISCOVER_PREVIEW: async ({commit}, id) => {
      commit('loading', true, {root: true})

      await axios.get(`/api/discovers/preview/${id}`)
        .then((response) => {
          commit('SET_DISCOVERS', [])
          commit('ADD_TO_DISCOVERS', response.data.data)
          commit('loading', false, {root: true})
        }).catch((error) => {
          commit('SET_DISCOVERS', [])
          commit('loading', false, {root: true})
        })
    },
  }
}
