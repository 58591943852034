<template>
  <section class="sign">
    <SwitchLocale/>
    <router-link class="logo" to="/">
      <img src="../../assets/img/logo.svg" alt="Trivento Wine Compass"/>
    </router-link>
    <h1>{{ $t("restore-pass") }}</h1>
    <p v-if="step === 1">{{ $t("restore-enter-email") }}</p>
    <p v-if="step === 2">{{ $t("restore-we-have-sent") }}</p>
    <form class="form" action="#" @submit.prevent="submit">
      <div v-if="step === 1">
        <div
          class="form__row"
          :class="{
            'form__row--invalid': v$.form.email.$error || error,
          }"
        >
          <label for="email">{{ $t("email") }}</label>
          <input
            type="email"
            name="email"
            id="email"
            v-model="v$.form.email.$model"
            v-bind:placeholder="$t('type-email')"
            @keyup="clearError"
          />
          <div
            class="input-errors"
            v-for="(error, index) of v$.form.email.$errors"
            :key="index"
          >
            <span class="form__row-info">
              {{ error.$message }}
            </span>
          </div>
          <div
            class="input-errors"
            v-for="(message, field) of error"
            :key="field"
          >
            <span v-if="field === 'email'" class="form__row-info">
              {{ message[0] }}
            </span>
          </div>
        </div>
        <button class="btn" type="submit">
          {{ $t("request-password-reset") }}
        </button>
      </div>
      <div v-if="step === 2">
        <input type="hidden" name="email" v-model="v$.form.email.$model"/>
        <router-link class="btn" to="/">
          {{ $t("ok") }}
        </router-link>
        <div
          class="sign__btn-holder justify-center text-align-center underline"
        >
          <div v-if="this.retry === 0">
            <button class="link link--underline" type="submit">
              {{ $t("resend-pass") }}
            </button>
          </div>
          <div v-else>
            <span>{{ $t("resend-pass-again", [this.retry]) }}</span>
          </div>
        </div>
      </div>
    </form>
    <div v-if="step === 1" class="sign__btn-holder justify-center">
      <router-link class="underline" to="/signUp">
        {{ $t("sign-up") }}
      </router-link>
    </div>
  </section>
</template>

<script>
import SwitchLocale from "../../components/SwitchLocale";
import useVuelidate from "@vuelidate/core";
import {email, helpers, required} from "@vuelidate/validators";
import FormMixin from "../../mixins/form";
import {getError} from "../../utils/helpers";

export default {
  name: "ForgotPassword",

  components: {
    SwitchLocale,
  },
  mixins: [FormMixin],
  setup: () => ({v$: useVuelidate()}),
  data() {
    return {
      pass: {
        active: false,
      },
      step: 1,
      retry: 60,
      form: {
        email: "",
      },
      error: null,
      message: null,
    };
  },
  validations() {
    return {
      form: {
        email: {
          required: helpers.withMessage(
            () => this.$t("email-required"),
            required
          ),
          email,
        },
      },
    };
  },
  methods: {
    async submit() {
      this.error = null;
      this.message = null;

      this.v$.$validate();

      if (!this.v$.$error) {
        this.v$.$reset();

        await this.$store
          .dispatch("auth/FORGOT_PASSWORD", this.form)
          .then(() => {
            this.retry = 60;
            this.retryTimer();
            this.step = 2;
            this.message = this.$t("reset-pass-email-sent");
          })
          .catch((error) => {
            this.error = getError(error);
          });
      }
    },
    retryTimer() {
      if (this.retry > 0) {
        setTimeout(() => {
          this.retry -= 1;
          this.retryTimer();
        }, 1000);
      }
    },
    clearError() {
      this.error = null;
    },
    togglePass() {
      if (this.isEmpty() == 0) {
        this.pass.active = false;
      } else if (this.isEmpty() != 0) {
        this.pass.active = true;
      } else {
        this.pass.active = false;
      }
    },
    isEmpty() {
      return this.form.password.length;
    },
  },
  mounted() {
    let app = document.querySelector("#app");
    app.classList = "";

    this.$store.commit("headerHide");
    this.$store.commit("navHide");

    this.focusInput();

    this.$store.commit("popupHidden", true);
  },
  unmounted() {
    if (this.$cookies.get("popShow")) {
      this.$store.commit("popupHidden", false);
    }
  },
};
</script>
