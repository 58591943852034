import axios from 'axios'

export default {
  namespaced: true,

  actions: {
    async GET_PROFILE({commit}, payload) {
      await axios.get('/sanctum/csrf-cookie')
      return axios.get('/api/profile', payload)
    },
    async UPDATE_PROFILE({commit}, payload) {
      await axios.get('/sanctum/csrf-cookie')
      return axios.post('/api/profile', payload, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    }
  }
}
