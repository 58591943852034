<template>
  <nav class="footer__nav">
    <ul>
      <li :class="active(['/explore', '/exploreWine'])">
        <router-link to="/explore">
          <i class="icon-icon-compass"></i>
        </router-link>
      </li>
      <li :class="active(['/discover'])">
        <router-link to="/discover">
          <i class="icon-icon-loop"></i>
        </router-link>
      </li>
      <li :class="active(['/enjoy'])">
        <router-link to="/enjoy">
          <i class="icon-icon-wine"></i>
        </router-link>
      </li>
      <li :class="active(['/shop', '/vendors', '/promotions'])">
        <router-link to="/shop">
          <i class="icon-icon-cart"></i>
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "Navigation",

  methods: {
    active(routes) {
      let className = "footer__nav-item";
      return routes.includes(this.$route.path)
        ? className + "--active"
        : className;
    }
  }
};
</script>
