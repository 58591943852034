<template>
  <section class="popup" v-if="popup_other && isPopupHidden() && !isIntro()">
    <div class="container_popup container_popup_other">
      <div
          class="header__menu-close"
          @click="close"
          @touchstart="close"
        >
        <span></span>
        <span></span>
      </div>
      <a href="#" @click.prevent="toPromotions(popup_other)" v-if="popup_other.image || popup_other.iframe" class="container_popup__link_image">
        <div v-html="showModal()"></div>
      </a>
    </div>
  </section>
</template>

<script>
import {mapState} from "vuex";
export default {
  name: "PopupOther",
  data() {
    return {
      popupHidden: this.$store.state.popup.popupHidden
    };
  },
  computed: {
    ...mapState("popup", ["popup_other"]),
  },
  methods: {
    showModal() {
      return this.popup_other.iframe && this.popup_other.iframe[this.$i18n.locale] ? this.popup_other.iframe[this.$i18n.locale] : `<img src="${this.popup_other.image[this.$i18n.locale]}" alt="${this.popup_other.title}">`
    },
    toPromotions(popup) {
      this.$gtm.trackEvent({
        event: 'popup',
        action: 'click',
        label: popup.title,
        value: popup.title
      })
      this.close()
      window.open(popup.link[this.$i18n.locale])
    },
    close() {
      this.popupHidden = this.$store.commit("popup/popupHidden", false);
      this.setCookie("popupShow", "false", "7d");
    },
    setCookie(name, val, expire) {
      this.$cookies.set(name, val, expire);
    },
    isPopupHidden() {
      return this.$store.state.popup.popupHidden;
    },
    isIntro() {
      let page = document.querySelector(".isAge");
      return page;
    },
  }
};
</script>
