<template>
  <section class="colour">
    <Close :to="`/explore/${$route.params.slug}`"/>
<!--    <Stories
      v-if="!this.authenticated"
      :stories="stories"
      :interval="12000"
    >
      <template #Colour>
        <Colour class="colour__content-holder"></Colour>
      </template>
      <template #Aroma>
        <Aroma class="colour__content-holder"></Aroma>
      </template>
      <template #Flavour>
        <Flavour class="colour__content-holder"></Flavour>
      </template>
      <template #GoToVivino>
        <GoToVivino class="colour__content-holder"></GoToVivino>
      </template>
      <template #Subscribe>
        <Subscribe class="colour__content-holder"></Subscribe>
      </template>
    </Stories>-->
<!--    v-else-->
    <Stories
      :stories="stories"
      :interval="12000"
    >
      <template #Colour>
        <Colour class="colour__content-holder"></Colour>
      </template>
      <template #ColourRate>
        <ColourRate class="colour__content-holder" :logged-in="authenticated"></ColourRate>
      </template>
      <template #Aroma>
        <Aroma class="colour__content-holder"></Aroma>
      </template>
      <template #AromaRate>
        <AromaRate class="colour__content-holder" :logged-in="authenticated"></AromaRate>
      </template>
      <template #Flavour>
        <Flavour class="colour__content-holder"></Flavour>
      </template>
      <template #FlavourRate>
        <FlavourRate class="colour__content-holder" :logged-in="authenticated"></FlavourRate>
      </template>
      <template #ThankYou>
        <ThankYou class="colour__content-holder"></ThankYou>
      </template>
    </Stories>
  </section>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import {Stories} from "@/assets/vue-insta-stories";

import Colour from "./Colour.vue";
import ColourRate from "./ColourRate.vue";
import Aroma from "./Aroma.vue";
import AromaRate from "./AromaRate.vue";
import Flavour from "./Flavour.vue";
import FlavourRate from "./FlavourRate.vue";
import Subscribe from "./Subscribe.vue";
import ThankYou from "./ThankYou.vue";
import Close from '../../../components/Close';
import GoToVivino from '../../stories/GoToVivino';

export default {
  name: "Caf",

  components: {
    // GoToVivino,
    Close,
    Stories,
    Colour,
    ColourRate,
    Aroma,
    AromaRate,
    Flavour,
    FlavourRate,
    // Subscribe,
    ThankYou
  },
  data() {
    return {
      templates: {
        default: [
          'Colour',
          'Aroma',
          'Flavour',
          'GoToVivino',
          'Subscribe'
        ],
        member: [
          'Colour',
          'ColourRate',
          'Aroma',
          'AromaRate',
          'Flavour',
          'FlavourRate',
          'ThankYou'
        ]
      }
    };
  },
  computed: {
    ...mapGetters("wine", ["wine", 'getWine']),
    ...mapGetters("auth", ["authenticated"]),
    stories: function () {
      let templates = [];

      /*let steps = this.authenticated
          ? this.templates.member
          : this.templates.default
      ;*/

      let steps = this.templates.member

      steps.forEach(name => templates.push({ template: name }));

      return templates;
    },
  },
  created() {
    const verify = this.getWine(this.$route.params.slug)
    if(verify) {
      this.GET_WINE(this.$route.params.slug)
    }
  },
  mounted() {
    let app = document.querySelector("#app");
    app.classList = "";

    this.$store.commit("headerHide");
    this.$store.commit("navHide");
  },
  methods: {
    ...mapActions('wine', ['GET_WINE'])
  }
};
</script>
