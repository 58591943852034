<template>
  <section v-if="!submitted" class="profile">
    <h1>{{ $t("edit-profile") }}</h1>
    <form class="form" action="#" @submit.prevent="submit" autocomplete="off">
      <div
        class="profile__img-holder"
        :class="{ 'form__row--invalid': v$.form.image.$error }"
      >
        <img :src="this.image" :alt="user.name + ' ' + user.surname" />
        <input
          type="file"
          ref="file"
          class="file"
          name="image"
          id="image"
          v-on:change="onFileChange"
          accept="image/jpg, image/jpeg, image/png"
        />
        <label for="image">
          <i class="icon-icon-camera"></i>
          <p class="file-name"></p>
        </label>
        <div
          class="input-errors"
          v-for="(error, index) of v$.form.image.$errors"
          :key="index"
        >
          <span class="form__row-info">
            {{ error.$message }}
          </span>
        </div>
      </div>
      <div
        class="form__row"
        :class="{ 'form__row--invalid': v$.form.name.$error }"
      >
        <label for="name">{{ $t("name") }}</label>
        <input
          type="text"
          name="name"
          id="name"
          v-model="v$.form.name.$model"
          v-bind:placeholder="$t('type-name')"
        />
        <div
          class="input-errors"
          v-for="(error, index) of v$.form.name.$errors"
          :key="index"
        >
          <span class="form__row-info">
            {{ error.$message }}
          </span>
        </div>
      </div>
      <div
        class="form__row"
        :class="{ 'form__row--invalid': v$.form.surname.$error }"
      >
        <label for="surname">{{ $t("surname") }}</label>
        <input
          type="text"
          name="surname"
          id="surname"
          v-model="v$.form.surname.$model"
          v-bind:placeholder="$t('type-surname')"
        />
        <div
          class="input-errors"
          v-for="(error, index) of v$.form.surname.$errors"
          :key="index"
        >
          <span class="form__row-info">
            {{ error.$message }}
          </span>
        </div>
      </div>
      <div
        class="form__row"
        :class="{ 'form__row--invalid': v$.form.email.$error }"
      >
        <label for="email">{{ $t("email") }}</label>
        <input
          type="email"
          name="email"
          id="email"
          autocomplete="false"
          v-model="v$.form.email.$model"
          v-bind:placeholder="$t('type-email')"
        />
        <div
          class="input-errors"
          v-for="(error, index) of v$.form.email.$errors"
          :key="index"
        >
          <span class="form__row-info">
            {{ error.$message }}
          </span>
        </div>
      </div>
      <div
        class="form__row"
        :class="{ 'form__row--invalid-select': v$.form.country.$error }"
      >
        <label for="country">{{ $t("country") }}</label>
        <vSelect
          name="country"
          id="country"
          v-bind:placeholder="$t('select-country')"
          :options="countries"
          v-model="v$.form.country.$model"
          @keypress="isNumber(event)"
        />
        <div
          class="input-errors"
          v-for="(error, index) of v$.form.country.$errors"
          :key="index"
        >
          <span class="form__row-info">
            {{ error.$message }}
          </span>
        </div>
      </div>
      <div class="form__row">
        <label class="form__label-check">
          <input
            type="checkbox"
            name="keep_me_update"
            id="keep_me_update"
            v-model="v$.form.keep_me_update.$model"
            @change="v$.form.keep_me_update.$touch()"
          />
          <span class="form__checkbox">
            <img src="@/assets/img/icon-check.svg" alt="checkbox" />
          </span>
          {{ $t("subscribed-newsletter") }}
        </label>
      </div>
      <div
        class="form__row"
        :class="{ 'form__row--invalid': !form.imageSizeOk }"
      >
        <div class="input-errors input-errors--size">
          <span class="form__row-info" v-if="!form.imageSizeOk">
            {{ $t("file-greater") }}
          </span>
        </div>
      </div>
      <button class="btn" type="submit">
        {{ $t("save-changes") }}
      </button>
    </form>
  </section>
  <section v-else class="profile-confirm">
    <p v-if="email_change">{{ $t("email-confirm") }}</p>
    <p v-else>{{ $t("profile-update-confirm") }}</p>
    <router-link @click="toggleChanges" class="btn" to="/">
      {{ $t("ok") }}
    </router-link>
  </section>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import vSelect from "@/assets/vue-select/src/index.js";
import FormMixin from "@/mixins/form";
import {email, helpers, minLength, required, requiredIf} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";
import {getError} from "../utils/helpers";

export default {
  name: "Profile",

  components: {
    vSelect
  },
  mixins: [FormMixin],
  setup: () => ({v$: useVuelidate()}),
  data() {
    return {
      image: '',
      email_change: false,
      form: {
        image: "",
        imageSizeOk: true,
        name: "",
        surname: "",
        country: "",
        email: "",
        keep_me_update: false
      },
      email: "",
      submitted: false,
      error: null,
      message: null
    };
  },
  computed: {
    ...mapGetters("auth", ["user", "authenticated"]),
    ...mapGetters("setting", ["countries"]),
  },
  validations() {
    const country = value => this.countries.includes(value);

    return {
      form: {
        image: {
          required: requiredIf(nestedModel => {
            return this.form.image || this.form.image.length !== 0;
          })
        },
        name: {
          required: helpers.withMessage(
            () => this.$t("name-required"),
            required
          ),
          minLength: minLength(2)
        },
        surname: {
          required: helpers.withMessage(
            () => this.$t("surname-required"),
            required
          ),
          minLength: minLength(2)
        },
        country: {
          required: helpers.withMessage(
            () => this.$t("country-required"),
            required
          ),
          country
        },
        email: {
          required: helpers.withMessage(
            () => this.$t("email-required"),
            required
          ),
          email
        },
        keep_me_update: {}
      }
    };
  },
  methods: {
    ...mapActions({
      getUser: "auth/me"
    }),
    onFileChange(e) {
      if (!e.target.files.length) return;
      const image = e.target.files[0];
      this.v$.form.image.$touch();
      this.form.image = image;
      this.image = URL.createObjectURL(image);
    },
    async submit() {
      this.error = null;
      this.message = null;

      this.v$.$validate();

      const file = this.$refs.file.files[0];

      if (file) {
        if (file.size > 1024 * 1024 * 2) {
          this.form.imageSizeOk = false;
          return;
        }
      }

      if (!this.v$.$error) {
        this.v$.$reset();

        const data = new FormData();
        data.append("id", this.user.id);
        data.append("image", this.form.image);
        data.append("name", this.form.name);
        data.append("surname", this.form.surname);
        data.append("country", this.form.country);
        data.append("email", this.form.email);
        data.append("keep_me_update", this.form.keep_me_update);

        await this.$store
          .dispatch("profile/UPDATE_PROFILE", data)
          .then(response => {
            if (this.email !== this.form.email) {
              this.email_change = true;
            }

            this.$store.commit("auth/SET_USER", response.data);

            this.getUser();
            this.submitted = true;
          })
          .catch(error => {
            this.error = getError(error);
          });
      }
    },
    toggleChanges() {
      this.submitted = false;
      this.email_change = false;
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      let charCode = evt.which ? evt.which : evt.keyCode;
      if (
        !(charCode > 31 && (charCode < 48 || charCode > 57)) ||
        charCode == 44 ||
        charCode == 46 ||
        charCode == 47
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    }
  },
  mounted() {
    if (!this.authenticated) {
      this.$router.replace({ name: "home" });
    }

    this.image = this.user.image;
    this.form.name = this.user.name;
    this.form.surname = this.user.surname;
    this.form.email = this.email = this.user.email;
    this.form.country = this.user.country;
    this.form.keep_me_update = this.user.keep_me_update;

    this.$store.cache.dispatch("setting/GET_COUNTRIES");

    let app = document.querySelector("#app");

    app.className = "";
    app.classList.add("isProfile");

    this.$store.commit("headerShow");
    this.$store.commit("userHide");
    this.$store.commit("burgerHide");
    this.$store.commit("linkBackActive");
    this.$store.commit("navHide");

    this.focusInput();
  }
};
</script>
