<template>
  <section class="age">
    <SwitchLocale />
    <router-link to="/" class="age__img-holder">
      <img src="@/assets/img/logo-text-2.svg" alt="Trivento Wine Compass" />
    </router-link>
    <p>
      {{ $t("age-location-text") }}
    </p>
    <strong>{{ $t("legal-drinking") }}</strong>
    <form class="form" @submit.prevent="submit">
      <div class="form__row age__radio-holder">
        <label class="form__radio-container">
          <input type="radio" name="adult" value="true" v-model="form.adult" />
          <span class="form__radio"></span>
          <span>{{ $t("yes") }}</span>
        </label>
        <label class="form__radio-container">
          <input type="radio" name="adult" value="false" v-model="form.adult" />
          <span class="form__radio"></span>
          <span>{{ $t("no") }}</span>
        </label>
      </div>
      <div class="form__row">
        <vSelect
          v-bind:placeholder="$t('select-country')"
          :options="countries"
          v-model="form.country"
        ></vSelect>
      </div>
      <div class="form__row">
        <label class="form__label-check">
          <input type="checkbox" v-model="form.acceptTerms" />
          <span class="form__checkbox">
            <img src="@/assets/img/icon-check.svg" alt="checkbox" />
          </span>
          <span @click="toggle()">
            {{ $t("i-accept") }}
            <span class="underline">{{ $t("terms-conditions") }}</span>
          </span>
        </label>
      </div>
      <button class="btn" type="submit">{{ $t("confirm") }}</button>
      <span class="form--invalid" :class="{ active: form.showError }">{{ this.msgError }}</span>
    </form>
    <Socials />

    <ul class="certificates">
      <li>
        <img src="@/assets/img/bcorp.png" width="50" alt="">
      </li>
      <li>
        <img src="@/assets/img/vegan.png" width="50" alt="">
      </li>
    </ul>
  </section>
</template>

<script>
import {mapGetters} from "vuex";
import SwitchLocale from "@/components/SwitchLocale";
import Socials from "@/components/Socials";
import vSelect from "@/assets/vue-select/src/index.js";

export default {
  name: "AgeLocation",

  components: {
    SwitchLocale,
    Socials,
    vSelect,
  },
  data() {
    return {
      // locales: [],
      form: {
        name: "",
        surname: "",
        email: "",
        password: "",
        password_confirmation: "",
        country: null,
        adult: "",
        acceptTerms: "",
        showError: false,
        msgError: "",
      },
    };
  },

  watch: {
    locales(val) {
      this.$i18n.locale = val[0];
      this.form.country = this.$cookies.get('country');
    }
  },
  methods: {
    submit() {
      if (
        this.form.adult == "true" &&
        this.form.country !== null &&
        this.form.acceptTerms == true
      ) {
        // this.setCookie("country", this.form.country, "7d");
        this.setCookie("firstVisit", false, "7d");
        this.setCookie("acceptTerms", true, "7d");
        this.setCookie("adult", true, "7d");
        this.form.showError = false;
        window.location.href = "/";
        this.$store.commit("popup/popupHidden", true);
      } else {
        if (this.form.adult == "") {
          this.msgError = this.$t("please-fields");
          this.form.showError = true;
        }
        if (this.form.country == null) {
          this.msgError = this.$t("please-fields");
          this.form.showError = true;
        }
        if (this.form.adult == "false") {
          this.msgError = this.$t("you-not-18");
          this.form.showError = true;
        }
        if (this.form.acceptTerms == "") {
          this.msgError = this.$t("accept-term");
          this.form.showError = true;
        }
        if (this.form.adult !== "true" && this.form.country !== null) {
          this.msgError = this.$t("you-not-18");
          this.form.showError = true;
        }
      }
    },
    getCookie(name) {
      this.$cookies.get(name);
    },
    setCookie(name, val, expire) {
      this.$cookies.set(name, val, expire);
    },
    toggle() {
      let terms = document.querySelector(".terms");

      if (terms.classList.contains("active")) {
        terms.classList.remove("active");
      } else {
        terms.classList.add("active");
      }
    },
  },

  computed: {
    ...mapGetters("setting", ["countries", 'locales']),
  },
  mounted() {
    this.$store.cache.dispatch("setting/GET_COUNTRIES");

    this.$store.commit("headerHide");
    this.$store.commit("navHide");

    let app = document.querySelector("#app");
    app.classList.add("isAge");

    this.$store.commit("popupHidden", true);
  },
  unmounted() {
    this.$store.commit("popupHidden", false);
  },
};
</script>
