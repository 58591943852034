import axios from "axios";

export default {
  namespaced: true,

  state: {
    wines: [],
    wine: null,
    error: null,
    foods: [],
  },

  getters: {
    wines: state => {
      return state.wines;
    },
    wine: state => {
      return state.wine;
    },
    passport: state => {
      return state.wine.passport;
    },
    error: state => {
      return state.error;
    },
    getWine: (state) => (slug) => {
      const row = state.wines.find((row) => row.slug === slug)
      return row === undefined
    },
  },

  mutations: {
    SET_WINES(state, wines) {
      state.wines = wines;
    },
    SET_WINE(state, wine) {
      state.wine = wine;
    },
    SET_PASSPORT(state, passport) {
      state.wine.passport = passport;
    },
    SET_ERROR(state, error) {
      state.error = error;
    },
    SET_WINE_RATE(state, rating) {
      state.wine.rating.wine = rating;
    },
    SET_COLOUR_RATE(state, rating) {
      state.wine.rating.colour = rating;
    },
    SET_AROMA_RATE(state, rating) {
      state.wine.rating.aroma = rating;
    },
    SET_FLAVOUR_RATE(state, rating) {
      state.wine.rating.flavour = rating;
    },
  },

  actions: {
    GET_WINES: async ({commit}) => {
      commit("loading", true, {root: true});

      await axios
        .get("/api/wines")
        .then(response => {
          commit("SET_WINES", response.data.data);
          commit("loading", false, {root: true});
        })
        .catch(error => {
          commit("SET_WINES", []);
          commit("loading", false, {root: true});
        });
    },
    GET_WINES_COUNTRY: async ({commit}, country) => {
      commit("loading", true, {root: true});

      await axios
        .get(`/api/wines/${country}`)
        .then(response => {
          commit("SET_WINES", response.data.data);
          commit("loading", false, {root: true});
        })
        .catch(error => {
          commit("SET_WINES", []);
          commit("loading", false, {root: true});
        });
    },
    PASSPORT: async ({commit}, [wine, payload]) => {
      await axios
        .post(`/api/wines/${wine.id}/passport`, payload)
        .then(response => {
          commit("SET_PASSPORT", response.data.data);
        });
    },
    GET_WINE_PREVIEW: async ({commit}, id) => {
      commit("loading", true, {root: true});

      await axios
        .get("/api/wines/preview/"+id)
        .then(response => {
          commit("SET_WINE", response.data.data);
          commit("loading", false, {root: true});
        })
        .catch(error => {
          commit("SET_WINE", null);
          commit("loading", false, {root: true});
        });
    },
    GET_FOOD_PREVIEW: async ({commit}, id) => {
      commit("loading", true, {root: true});

      await axios
        .get("/api/foods/preview/"+id)
        .then(response => {
          commit("SET_WINE", null);
          let dataPrev = {
            foods: []
          }
          dataPrev.foods.push(response.data.data)
          commit("SET_WINE", dataPrev);
          commit("loading", false, {root: true});
        })
        .catch(error => {
          commit("SET_WINE", null);
          commit("loading", false, {root: true});
        });
    },
    GET_WINE: async ({commit}, slug) => {
      commit("loading", true, {root: true});
      await axios
        .get("/api/wines/detail/"+slug)
        .then(response => {
          commit("SET_WINE", response.data.data);
          commit("loading", false, {root: true});
        })
        .catch(error => {
          commit("SET_WINE", null);
          commit("loading", false, {root: true});
        });
    },
  }
};
