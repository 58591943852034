import axios from 'axios'

export default {
  namespaced: true,

  state: {
    settings: [],
    social: null,
    countries: [],
    podcast: null,
    terms: [],
    languages: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(","),
    titles: process.env.VUE_APP_I18N_LANGUAGE_TITLE.split(",")
  },

  getters: {
    settings: (state) => {
      return state.settings
    },
    social: (state) => {
      return state.social
    },
    countries: (state) => {
      return state.countries
    },
    podcast: (state) => {
      return state.podcast
    },
    terms: (state) => {
      return state.terms
    },
    locales: (state) => {
      return state.languages
    },
  },

  mutations: {
    SET_SETTINGS(state, settings) {
      state.settings = settings
    },
    SET_SOCIAL(state, social) {
      state.social = social
    },
    SET_COUNTRIES(state, countries) {
      state.countries = countries
    },
    SET_PODCAST(state, podcast) {
      state.podcast = podcast
    },
    SET_TERMS(state, terms) {
      state.terms = terms
    },
    SET_LOCALES(state, locales) {
      if(locales.length > 0) {
        state.languages = []
        state.titles = []
        locales.forEach(row => {
          state.languages.push(row)
          state.titles.push(row.toUpperCase())
        })
      }
    }
  },

  actions: {
    SET_LOCALE: async ({commit}, lang) => {
      return axios.post('/api/settings/set-locale/' + lang);
    },
    GET_SOCIAL: async ({dispatch}) => {
      dispatch('GET_SETTINGS')
    },
    GET_COUNTRIES: async ({commit}) => {
      commit('loading', true, {root: true})

      await axios.get('/api/settings/countries')
        .then((response) => {
          commit('SET_COUNTRIES', response.data)
          commit('loading', false, {root: true})
        }).catch((error) => {
          commit('SET_COUNTRIES', [])
          commit('loading', false, {root: true})
        })
    },
    GET_PODCAST: async ({dispatch}) => {
      dispatch('GET_SETTINGS')
    },
    GET_TERMS: async ({dispatch}) => {
      dispatch('GET_SETTINGS')
    },
    GET_SETTINGS: async ({commit}) => {
      commit('loading', true, {root: true})

      await axios.get('/api/settings')
        .then((response) => {
          commit('SET_SETTINGS', response.data)
          commit('SET_SOCIAL', response.data.social)
          commit('SET_PODCAST', response.data.podcast[0])
          commit('SET_TERMS', response.data.terms)
          commit('loading', false, {root: true})
        }).catch((error) => {
          commit('SET_SETTINGS', [])
          commit('loading', false, {root: true})
        })
    }
  }
}
