<template>
  <div class="addNotes story-custom">
    <router-link to="/exploreWine">
      <svg
        class="stories__close"
        width="23"
        height="23"
        viewBox="0 0 23 23"
        fill="red"
        stroke="#ffffff"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 2L20.7949 20.7949"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M21.4602 2L2.66532 20.7949"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </router-link>
    <img class="stories__bg" src="@/assets/img/rateUs-bg.jpeg" alt="story-bg" />
    <div class="story-custom__content">
      <template v-if="!isNoted">
        <h1>{{ $t("add-notes") }}</h1>
        <p>
          {{ $t("add-notes-descr") }}
        </p>
      </template>
      <h1 v-else>{{ $t("your-rate") }}</h1>
      <Rating
        v-if="isRated"
        v-model="val"
        :stars="5"
        :cancel="false"
        :readonly="false"
        :disabled="true"
      />
      <div class="addNotes__journal">
        <div class="addNotes__journal-top">
          <h2>{{ $t("wine-journal") }}</h2>
        </div>
        <i>{{ this.wine.name }}</i>
        <i>{{ this.wine.grape_variety[this.$i18n.locale] }}</i>
        <i>{{ this.wine.harvest }}</i>
        <form action="#" @submit.prevent="submit">
          <i
            v-if="isNoted"
            class="icon-icon-write btnEdit"
            :class="{ active: active }"
            @click="editArea()"
          ></i>
          <button
            class="btnSave"
            :class="{ active: active || !isNoted }"
            type="submit"
          >
            {{ $t("save-notes") }}
          </button>
          <textarea
            v-if="isNoted"
            name="note"
            id=""
            v-model="note"
            readonly
          ></textarea>
          <textarea
            v-else
            id=""
            :placeholder="$t('write-notes')"
            @click="focus"
            name="note"
            v-model="note"
            class="scroll-touch"
          ></textarea>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Rating from "primevue/rating";
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css"; //core css
import "primeicons/primeicons.css";
import {mapGetters} from "vuex";
import axios from "axios"; //icons

export default {
  name: "AddNotes",

  components: {
    Rating,
  },
  data() {
    return {
      note: "",
      active: false,
      val: 0,
      isRated: false,
      isNoted: false,
    };
  },
  computed: {
    ...mapGetters("wine", ["wine"]),
  },
  methods: {
    checkWinePassport() {
      if (this.wine.passport.comment) {
        this.note = this.wine.passport.comment;
      }

      if (this.wine.passport.length) {
        if (this.wine.passport[0].comment) {
          this.note = this.wine.passport[0].comment;
          this.isNoted = true;
        }
        if (this.wine.passport[0].wine_rating) {
          this.val = this.wine.passport[0].wine_rating;
          this.isRated = true;
        }
      }
    },
    focus() {
      let area = document.querySelector("textarea");
      area.focus();
    },
    async submit() {
      if (this.note) {
        let area = document.querySelector("textarea");
        area.setAttribute("readonly", "readonly");
        this.active = !this.active;
        this.isNoted = true;

        let rateData = {
          comment: this.note,
        };

        await axios.post("/api/wines/" + this.wine.id + "/passport", rateData);

        this.$router.push("/wineAdded");
      }
    },
    editArea() {
      let area = document.querySelector("textarea");
      area.removeAttribute("readonly");
      area.focus();
      this.active = !this.active;
    },
  },
  mounted() {
    this.$store.commit("headerHide");
    this.$store.commit("navHide");

    this.checkWinePassport();
  },
};
</script>
