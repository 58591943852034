<template>
  <header v-if="!this.$store.state.headerHidden" class="container header">
    <div class="header__inner">
      <div
        v-if="!this.$store.state.linkBackHidden"
        class="link-back"
        @click="
          hideBack();
          back();
        "
      >
        <img src="@/assets/img/icon-arrow-left-white.svg" alt="back" />
      </div>
      <div
        v-if="!this.$store.state.burgerHidden"
        class="burger"
        :class="{ active: active }"
        @click="toggleActive"
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="header__menu-holder" :class="{ active: active }">
        <SwitchLocale />

        <div
          class="header__menu-close"
          @click="
            hideMenu();
            toPrevious();
          "
        >
          <span></span>
          <span></span>
        </div>
        <router-link to="/" class="logo-secondary" @click="toggleActive">
          <LogoPrimary />
        </router-link>
        <nav class="header__nav">
          <ul>
            <li>
              <router-link
                to="/"
                @click="
                  toggleActive();
                  hideUserMenu();
                "
                >{{ $t("home") }}</router-link
              >
            </li>
            <li>
              <a href="https://trivento.com/" target="_blank">
                {{ $t("visit-website") }}
              </a>
            </li>
            <li>
              <a :href="`mailto:info@trivento.com`" target="_blank">
                {{ $t("contact-us") }}
              </a>
            </li>
            <li>
              <router-link
                to="/terms"
                @click="
                  toggleActive();
                  hideUserMenu();
                "
                >{{ $t("terms-conditions") }}</router-link
              >
            </li>
          </ul>
        </nav>
        <template v-if="!this.authenticated">
          <router-link to="/signUp" class="header__signup">
            <i class="icon-icon-login"></i> {{ $t("sign-up") }}
          </router-link>
        </template>
        <transition name="fadeLog" mode="out-in">
          <template v-if="!this.authenticated">
            <router-link
              to="/signIn"
              class="header__logout"
              @click="toggleActive"
            >
              <i class="icon-icon-logout"></i> {{ $t("sign-in") }}
            </router-link>
          </template>
          <template v-else>
            <router-link to="/" @click.prevent="signOut" class="header__logout">
              <i class="icon-icon-logout"></i> {{ $t("sign-out") }}
            </router-link>
          </template>
        </transition>

        <Socials />
        <strong>{{ $t("trivento-wines") }}</strong>
        <span>{{ $t("mendoza-argentina") }}</span>
        <p>{{ $t("rights-reserved") }}</p>
      </div>
      <router-link class="logo-secondary" to="/">
        <LogoPrimary />
      </router-link>
      <a
        v-if="this.authenticated"
        href="#"
        class="header__user"
        :class="{ active: userMenu, hidden: this.$store.state.userHidden }"
        @click="toggleUserMenu"
      >
        <img src="@/assets/img/icon-user.svg" alt="user" />
      </a>
      <div v-if="this.authenticated" class="header__user-holder">
        <button
          class="burger"
          :class="{ active: active }"
          @click="toggleActive"
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
        <div
          class="header__menu-close"
          @click="
            hideUserMenu();
            toPrevious();
          "
        >
          <span></span>
          <span></span>
        </div>
        <router-link to="/" class="logo-secondary" @click="toggleUserMenu">
          <LogoPrimary />
        </router-link>
        <div class="header__user-info">
          <router-link to="/profile" @click="toggleUserMenu" class="header__img-holder">
            <img :src="user.image" :alt="user.name + ' ' + user.surname" />
          </router-link>
          <h2>{{ user.name }} {{ user.surname }}</h2>
          <div class="header__location-holder">
            <i class="icon-icon-location"></i>
            <span>{{ user.country }}</span>
          </div>
        </div>
        <ul>
          <li>
            <router-link to="/news" @click="toggleUserMenu">
              <i class="icon-icon-artricle"></i>
              {{ $t("news-for-you") }}
              <img src="@/assets/img/icon-arrow-right.svg" alt="right" />
            </router-link>
          </li>
          <li>
            <router-link to="/journey" @click="toggleUserMenu">
              <i class="icon-icon-pencil"></i>
              {{ $t("your-journey") }}
              <img src="@/assets/img/icon-arrow-right.svg" alt="right" />
            </router-link>
          </li>
          <li>
            <router-link to="/profile" @click="toggleUserMenu">
              <i class="icon-icon-login"></i>
              {{ $t("profile") }}
              <img src="@/assets/img/icon-arrow-right.svg" alt="right" />
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </header>

  <span v-else></span>
</template>

<script>
import Socials from "@/components/Socials.vue";
import LogoPrimary from "@/components/LogoPrimary.vue";
import {mapActions, mapGetters} from "vuex";
import SwitchLocale from "@/components/SwitchLocale.vue";

export default {
  name: "Header",

  components: {
    Socials,
    LogoPrimary,
    SwitchLocale,
  },
  data() {
    return {
      active: false,
      userMenu: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["user", "authenticated"]),
  },
  methods: {
    ...mapActions({
      signOutAction: "auth/signOut",
    }),
    async signOut() {
      await this.signOutAction();
      await this.$router.replace({ name: "home" });
      this.toggleActive();
    },
    toggleActive() {
      this.active = !this.active;
    },
    hideMenu() {
      this.active = false;
    },
    toggleUserMenu() {
      this.userMenu = !this.userMenu;
    },
    hideUserMenu() {
      if (this.userMenu == true) {
        this.userMenu = false;
      }
    },
    toPrevious() {
      let terms = document.querySelector(".isTerms");
      let news = document.querySelector(".isNews");
      let journey = document.querySelector(".isJourney");
      let profile = document.querySelector(".isProfile");
      let promo = document.querySelector(".isPromotion");
      let cellar = document.querySelector(".isCellar");

      if (terms || news || journey || profile || promo || cellar) {
        this.$router.go(-1);
      }
    },
    back() {
      let promo = document.querySelector(".isPromotion");
      let palate = document.querySelector(".isPalate");
      let journey = document.querySelector(".isJourney");
      let cellar = document.querySelector(".isCellar");
      let cellarDetails = document.querySelector(".isPassDetails");

      if (promo || palate || journey || cellar || cellarDetails) {
        this.$router.go(-1);
      }
    },
    hideBack() {
      let app = document.querySelector("#app");
      let promo = document.querySelector(".isPromotion");

      if (promo) {
        // nothing to do
      } else if (
        app.classList.contains("isProfile") ||
        app.classList.contains("isNews") ||
        app.classList.contains("isJourney")
      ) {
        this.userMenu = !this.userMenu;
      } else if (
        app.classList.contains("isPalate") ||
        app.classList.contains("isCellar") ||
        app.classList.contains("isPassDetails")
      ) {
        // skipping actions
      } else {
        this.active = !this.active;
      }
    },
  },
};
</script>
