<template>
  <div>
    <img
      class="stories__bg"
      src="@/assets/img/colour-1.jpeg"
      :alt="$t('colour')"
    />
    <h1 class="text-align-center colour__underline">
      {{ $t("build-taste") }}
    </h1>
    <div class="stories__info-holder stories__info-holder--middle">
      <h2 class="text-align-center">{{ $t("colour") }}</h2>
      <p class="text-align-center">
        {{ $t("colour-rate-text") }}
      </p>
      <Rating v-model="rating" :stars="5" :cancel="false" :readonly="false" />
    </div>
  </div>
</template>

<script>
import Rating from "primevue/rating";
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import {mapGetters} from "vuex";

export default {
  name: "AromaRate",
  props: {
    loggedIn: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Rating
  },
  data() {
    return {
      rating: 0
    };
  },
  computed: {
    ...mapGetters("wine", ["wine"]),
  },
  mounted() {
    this.rating = this.wine.rating.colour;
    let icons = document.querySelectorAll(".p-rating-icon");
    let eventList = ["touchstart", "mousedown"];
    for (let i = 0; i < icons.length; i++) {
      for (let j = 0; j < eventList.length; j++) {
        icons[i].addEventListener(eventList[j], (event) => {
          this.rating = i + 1;

          this.$store.commit("wine/SET_COLOUR_RATE", this.rating);

          if(this.loggedIn) {
            this.$store.dispatch("wine/PASSPORT", [
              this.wine,
              {
                colour_rating: this.rating
              }
            ]);
          }
        });
      }
    }
  }
};
</script>
