<template>
  <div>
    <img
      class="stories__bg"
      v-lazy="wine.caf.colour.image"
      :alt="$t('colour')"
    />
    <div class="stories__info-holder" :class="'stories__info-holder--'+wine.caf.colour.text_position">
      <div class="stories__info-inner"
         :style="{
            'background-color': wine.caf.colour.text_bg_color,
            'color': wine.caf.colour.text_color
         }"
      >
        <h1 class="text-align-left">{{ $t("colour") }}</h1>
        <p class="text-align-left">{{ description }}</p>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters} from 'vuex';

export default {
  name: "Colour",

  computed: {
    ...mapGetters("wine", ["wine"]),
    description: function () {
      return this.wine.caf.colour.text[ this.$i18n.locale ]
    }
  }
};
</script>
