<template>
  <section class="passport-details">
    <div class="passport-details__info-holder">
      <div class="passport-details__info">
        <div class="passport-details__title">
          <i class="icon-icon-cellar"></i>
          <h1>{{ $t("my-cellar") }}</h1>
        </div>
        <i>{{ wine.name }}</i>
        <i>{{ wine.grape_variety }}</i>
        <i>{{ wine.harvest }}</i>
        <div class="passport-details__info-bottom">
          <Rating
            v-model="passport.wine_rating"
            :stars="5"
            :cancel="false"
            :readonly="false"
            :disabled="!editing"
          />
          <i
            class="icon-icon-write btnEdit"
            @click="edit"
          ></i>
        </div>
      </div>
      <img v-lazy="wine.image" :alt="wine.name"/>
    </div>
    <div v-if="!passport.comment && !editing" class="createNote">
      {{ $t("make-note") }}
    </div>
    <form v-else action="#" @submit.prevent="submit">
      <textarea
        name="note"
        id="wine-note"
        ref="note"
        :readonly="!editing"
        v-model="passport.comment"
        @input="autoResize"
      ></textarea>
      <button type="submit" v-show="editing" class="btn">{{ $t("save-note") }}</button>
      <input type="text" name="rate" :value="passport.wine_rating" class="sr-only"/>
    </form>
  </section>
</template>

<script>
import Rating from "primevue/rating";
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import {mapGetters} from "vuex";
import mixinAutoResize from "../../mixins/autoResize";

export default {
  name: "PassportDetails",

  components: {
    Rating
  },
  mixins: [mixinAutoResize],
  data() {
    return {
      editing: false,
      wine: {
        name: '',
        grape_variety: '',
        harvest: '',
        image: '',
      }
    };
  },
  computed: {
    ...mapGetters("passport", ["passport"]),
  },
  created() {
    if (this.passport === null) {
      this.$router.push('/passport')
    }
  },
  methods: {
    edit() {
      this.editing = true;
      this.$refs.note.focus();
    },
    async submit() {
      await this.$store
        .dispatch('passport/UPDATE_PASSPORT', this.passport)
        .then((response) => {
          this.$store.commit("wine/SET_WINE_RATE", this.passport.wine_rating);
          this.editing = false;
        });
    }
  },
  mounted() {
    this.wine.name = this.passport.wine.name
    this.wine.grape_variety = this.passport.wine.grape_variety[this.$i18n.locale]
    this.wine.harvest = this.passport.wine.harvest
    this.wine.image = this.passport.wine.image

    let app = document.querySelector("#app");

    app.className = "";
    app.classList.add("isPassDetails");

    this.$store.commit("linkBackActive");
    this.$store.commit("navHide");
  }
};
</script>
