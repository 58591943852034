<template>
  <div class="nothing">
    <p>{{ $t("nothing") }}</p>
    <p>{{ $t("come-back") }}</p>
  </div>
</template>

<script>
export default {
  name: "Nothing",
}
</script>