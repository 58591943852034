<template>
  <preloader></preloader>
  <Header/>
  <main
    v-if="
      this.firstVisit() === 'true' ||
        this.acceptTerms() == 'null' ||
        this.country() == 'null'
    "
    class="container"
  >
    <age-location/>
    <terms-conditions/>
  </main>
  <main v-else class="container">
    <slot/>
  </main>
  <Popup/>
  <PopupOther/>
  <PopupLocation/>
  <Footer/>
</template>

<script>
import Header from "./Header.vue";
import Footer from "./Footer.vue";
import Preloader from "./Preloader.vue";
import AgeLocation from "../views/AgeLocation.vue";
import TermsConditions from '../views/TermsConditions.vue';
import Popup from './Popup.vue';
import PopupOther from './PopupOther'
import PopupLocation from './PopupLocation'

export default {
  name: "Main",

  components: {
    Header,
    Footer,
    Preloader,
    AgeLocation,
    TermsConditions,
    Popup,
    PopupOther,
    PopupLocation
  },
  methods: {
    getCookie(name) {
      return this.$cookies.get(name);
    },
    firstVisit() {
      return this.getCookie("firstVisit");
    },
    acceptTerms() {
      return this.getCookie("acceptTerms");
    },
    country() {
      return this.getCookie("country");
    },
    setCookie(name, val, expire) {
      this.$cookies.set(name, val, expire);
    },
  },
  async mounted(){
    let countryOfUser = await this.$cookies.get("country");
    let stateOfUser = await this.$cookies.get("state");
    let popupShow = await this.$cookies.get("popupShow");
    let popupCurrent = await this.$cookies.get("popupCurrent");
    let zipCode = await this.$cookies.get("zip_code");

    const {data} = await this.$store.dispatch('popup/GET_POPUP_OTHERS', {country: countryOfUser, current: popupCurrent, zipCode, state: stateOfUser})// popupCurrent !== null ? `${countryOfUser}/?current=${popupCurrent}` : countryOfUser)
    if(!data) {
      const res = await this.$store.cache.dispatch("promotion/GET_PROMOTIONS", {countryOfUser, stateOfUser})
      if(res !== undefined) {
        this.$store.dispatch('popup/GET_POPUP');
      } else {
        this.setCookie("popupShow", "false", "7d");
      }
    } else {
      this.setCookie("popupCurrent", data.id, "7d");
      this.setCookie("popupShow", "false", "7d");
    }
  }
};
</script>
