import axios from "axios";

export default {
  namespaced: true,

  state: {
    vendors: [],
    error: null
  },

  getters: {
    vendors: state => {
      return state.vendors;
    },
    error: state => {
      return state.error;
    }
  },

  mutations: {
    SET_VENDORS(state, vendors) {
      state.vendors = vendors;
    },
    SET_ERROR(state, error) {
      state.error = error;
    },
    ADD_TO_VENDORS(state, vendor) {
      state.vendors.push(vendor);
    }
  },

  actions: {
    GET_VENDORS: async ({commit}, params) => {
      commit("loading", true, {root: true});
      await axios.get('/sanctum/csrf-cookie')

      await axios
        .post(`/api/sellers`, params)
        // .get(`/api/sellers/${params.countryOfUser}/${params.stateOfUser}/?zip_code=${params.zipCode}`)
        .then(response => {
          commit("SET_VENDORS", response.data.data);
          commit("loading", false, {root: true});
        })
        .catch(error => {
          commit("SET_VENDORS", []);
          commit("loading", false, {root: true});
        });
    },
    GET_VENDOR_PREVIEW: async ({commit}, id) => {
      commit("loading", true, {root: true});

      await axios
        .get(`/api/sellers/preview/${id}`)
        .then(response => {
          commit("SET_VENDORS", []);
          commit("ADD_TO_VENDORS", response.data.data);
          commit("loading", false, {root: true});
        })
        .catch(error => {
          commit("SET_VENDORS", []);
          commit("loading", false, {root: true});
        });
    }
  }
};
