<template>
  <section class="pair">
    <Close :to="`/explore/${$route.params.slug}`"/>
    <Stories v-if="wine.foods.length" :stories="stories" :interval="12000">
      <template
        v-for="food in wine.foods"
        :key="food.id"
        v-slot:[food.id]
      >
        <div>
          <img
            class="stories__bg"
            :src="food.image"
            :alt="food.name[ this.$i18n.locale ]"
          />
          <div
            class="stories__info-holder"
            :class="'stories__info-holder--'+food.text_position"
          >
            <div class="stories__info-inner"
              :style="{
                'background-color': food.background_text,
                'color': food.text_color
              }"
            >
              <h1>{{ food.name[this.$i18n.locale] }}</h1>
              <p>{{ food.description[this.$i18n.locale] }}</p>
            </div>
          </div>
        </div>
      </template>
    </Stories>
  </section>
</template>

<script>
import {Stories} from "@/assets/vue-insta-stories";
import {mapActions, mapGetters} from 'vuex';
import Close from '../../components/Close';

export default {
  name: "Pair",

  components: {
    Close,
    Stories,
  },
  created() {
    const verify = this.getWine(this.$route.params.slug)
    if(verify) {
      this.GET_WINE(this.$route.params.slug)
    }
  },
  computed: {
    ...mapGetters("wine", ["wine", 'getWine']),
    stories: function () {
      let templates = [];
      this.wine.foods.forEach(function (food) {
        templates.push({template: (food.id).toString()})
      });

      return templates;
    },
  },
  mounted() {
    let app = document.querySelector('#app');
    app.classList = "";

    this.$store.commit("headerHide");
    this.$store.commit("navHide");
  },
  methods: {
    ...mapActions('wine', ['GET_WINE'])
  }
};
</script>
