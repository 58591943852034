<template>
  <Layout>
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
  </Layout>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Layout from "./components/Layout.vue";

export default {
  data() {
    return {
      loading: true,
    };
  },
  components: {
    Layout,
  },
  computed: {
    ...mapGetters("auth", ["authenticated", "user"]),
    isHiddenHeader() {
      return this.$store.getters.isHiddenHeader;
    },
  },

  methods: {
    ...mapActions({
      signOutAction: "auth/signOut",
    }),

    async signOut() {
      await this.signOutAction();

      await this.$router.replace({ name: "home" });
    },
    setCookie(name, val, expire) {
      this.$cookies.set(name, val, expire);
    },
    getCookie(name) {
      let val = this.$cookies.get(name);
      return val;
    },
    exist(name) {
      this.$cookies.isKey(name);
    },
    checkVisit() {
      let valStr = this.getCookie("firstVisit");

      if (valStr) {
        this.setCookie("firstVisit", false, "7d");
      } else {
        this.setCookie("firstVisit", true, "7d");
        this.setCookie("acceptTerms", false, "7d");
        this.setCookie("country", null, "7d");
        this.setCookie("adult", false, "7d");
      }
    },
    checkLocale() {
      return this.getCookie("locale");
    },
    // Detects if device is on iOS
    isIos() {
      return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
  // iPad on iOS 13 detection
  || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    },
    // Detects if device is in standalone mode
    isInStandaloneMode() {
      return "standalone" in window.navigator && window.navigator.standalone;
    },
  },
  created() {
    this.$cookies.config("30d");
    this.checkVisit();

    if (!this.getCookie("locale")) {
      this.setCookie("locale", "en", "30d");
    } else {
      this.$i18n.locale = this.getCookie("locale");
    }

    let isIos = this.isIos();

    // Is it PWA or not?
    let isStandalone = this.isInStandaloneMode();
    let isInstalled = false;
    // Pop closed or not?
    let popCookExist = this.getCookie('popShow');

    // Listening for app installation
    window.addEventListener("appinstalled", (evt) => {
      isInstalled = true;
    });

    if (!isStandalone && isIos && !isInstalled && !popCookExist) {
      this.setCookie("popShow", "true", "7d");
    }
  },
  mounted() {
    if (this.getCookie("popShow") == "true") {
      this.$store.commit("popupHidden", false);
    }
  },
  updated() {
    //
  }
};
</script>
