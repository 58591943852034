<template>
  <section class="explore-wine">
    <div class="explore-wine__holder">
      <img
        class="explore-wine__product"
        v-lazy="wine.image"
        :alt="wine.name"
      />
      <div class="justify-center">
        <!-- Aquí aroma, sabor y color -->
        <router-link :to="`/explore/${wine.slug}/pair`">
          <img
            class="explore-wine__arrow explore-wine__arrow-up"
            src="@/assets/img/icon-arrow-down.svg"
            alt="wine"
          />
          <span class="explore-wine__title-s">{{ $t("goes-well") }}</span>
        </router-link>
      </div>
      <div class="justify-between">
        <router-link :to="`/explore/${wine.slug}/stories`">
          <img
            class="explore-wine__arrow explore-wine__arrow-left"
            src="@/assets/img/icon-arrow-down.svg"
            alt="wine"
          />
          <span class="explore-wine__title-s">{{ $t("know-story") }}</span>
        </router-link>
        <!-- Aqui maridaje -->
        <router-link :to="`/explore/${wine.slug}/caf`">
          <img
            class="explore-wine__arrow explore-wine__arrow-right"
            src="@/assets/img/icon-arrow-down.svg"
            alt="wine"
          />
          <span>{{ $t("colour-aroma") }}</span>
<!--          <img
            class="explore-wine__arrow explore-wine__arrow-up"
            src="@/assets/img/icon-arrow-down.svg"
            alt="wine"
          />-->
        </router-link>
      </div>
      <div class="justify-center">
<!--        <router-link :to="`/explore/${wine.slug}/portfolio`">
          <img
            class="explore-wine__arrow"
            src="@/assets/img/icon-arrow-down.svg"
            alt="wine"
          />
          <span class="explore-wine__title-m">{{ $t("explore-portfolio") }}</span>
        </router-link>-->
      </div>
    </div>
  </section>
</template>

<script>

import {mapGetters, mapActions} from 'vuex';

export default {
  name: "ExploreWine",

  data() {
    return {
      el: "#example",
      slides: 3
    };
  },
  computed: {
    ...mapGetters("wine", ["wine", 'getWine']),
  },
  created() {
    const verify = this.getWine(this.$route.params.slug)
    if(verify) {
      this.GET_WINE(this.$route.params.slug)
    }
  },
  mounted() {
    this.$store.commit("headerShow");
    this.$store.commit("navShow");
    this.$store.commit("linkBackDisable");
    this.$store.commit("burgerShow");
    this.$store.commit("userShow");

    let app = document.querySelector("#app");

    app.className = "";
    app.classList.add("isExploreWine");

    let body = document.querySelector("body");
    let html = document.querySelector("html");

    body.classList.add('fixedHeight')
    html.classList.add('fixedHeight')
  },
  methods: {
    ...mapActions('wine', ['GET_WINE'])
  },
  unmounted() {
    let body = document.querySelector("body");
    let html = document.querySelector("html");

    body.classList.remove('fixedHeight')
    html.classList.remove('fixedHeight')
  }
};
</script>
