<template>
  <div class="wineAdded story-custom">
    <router-link to="/exploreWine">
      <svg
        class="stories__close"
        width="23"
        height="23"
        viewBox="0 0 23 23"
        fill="red"
        stroke="#ffffff"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 2L20.7949 20.7949"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M21.4602 2L2.66532 20.7949"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </router-link>
    <img
      class="stories__bg"
      src="@/assets/img/wine-added-bg.jpeg"
      alt="story-bg"
    />
    <div class="story-custom__content">
      <h1>{{ $t("thank-you") }}</h1>
      <p>
        {{ $t("wine-added") }}
      </p>
      <router-link class="btn" @touchstart="clickPass" to="/passport"
        >{{ $t("go-to-passport") }}
      </router-link>
      <router-link @touchstart="clickExplore" to="/explore">{{ $t("to-all-wines") }} </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "WineAdded",

  mounted() {
    let app = document.querySelector('#app')
    app.classList = ''

    this.$store.commit('headerHide');
    this.$store.commit('navHide');
    this.$store.commit('linkBackDisable');
  },
  methods: {
    clickPass() {
      let btn = document.querySelector('[href="/passport"]');
      btn.click();
    },
    clickExplore() {
      let btn = document.querySelector('[href="/explore"]');
      btn.click();
    }
  }
};
</script>
