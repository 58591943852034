import axios from 'axios'
const ipApi = process.env.VUE_APP_IP_API
export default {
    GET_CLIENT_IP_ADDRESS: async ({dispatch, commit}) => {
        return await axios.get('/api/get-client-ip-address')
    },

    GET_GEOLOCATION: async (context, params) => {
      return await axios.post('/api/geolocation', params)
    }
}
