<template>
  <section class="sign">
    <SwitchLocale/>
    <router-link to="/" class="logo">
      <img src="../../assets/img/logo.svg" alt="Trivento Wine Compass"/>
    </router-link>
    <div v-if="step === 1">
      <h1>{{ $t("restore-create-pass") }}</h1>
      <p>
        {{ $t("restore-unic-pass") }}
      </p>
      <form class="form" action="#" @submit.prevent="submit">
        <div
          class="form__row"
          :class="{ 'form__row--invalid': v$.form.password.$error }"
        >
          <label for="password">{{ $t("restore-new-pass") }}</label>
          <input
            type="password"
            name="password"
            id="password"
            v-model="v$.form.password.$model"
            v-bind:placeholder="$t('***')"
            :class="{ active: pass.active }"
            @keydown="togglePass"
            @keyup="togglePass"
          />
          <div
            class="input-errors"
            v-for="(error, index) of v$.form.password.$errors"
            :key="index"
          >
            <span class="form__row-info">
              {{ error.$message }}
            </span>
          </div>
        </div>
        <div
          class="form__row"
          :class="{
            'form__row--invalid': v$.form.password_confirmation.$error,
          }"
        >
          <label for="password-confirm">{{ $t("restore-conf-pass") }}</label>
          <input
            type="password"
            name="password-confirm"
            id="password-confirm"
            v-model="v$.form.password_confirmation.$model"
            v-bind:placeholder="$t('***')"
            :class="{ active: passConf.active }"
            @keydown="togglePass"
            @keyup="togglePass"
          />
          <div
            class="input-errors"
            v-for="(error, index) of v$.form.password_confirmation.$errors"
            :key="index"
          >
            <span class="form__row-info">
              {{ error.$message }}
            </span>
          </div>
        </div>
        <button class="btn" type="submit">
          {{ $t("reset-pass") }}
        </button>
      </form>
    </div>
    <div v-if="step === 2">
      <h1>{{ $t("congrats") }}</h1>
      <p>
        {{ $t("restore-pass-reset") }}
      </p>
      <router-link class="btn" to="/signIn">{{ $t("sign-in") }}</router-link>
    </div>
  </section>
</template>

<script>
import SwitchLocale from "../../components/SwitchLocale";
import useVuelidate from "@vuelidate/core";
import {helpers, minLength, required, sameAs} from "@vuelidate/validators";
import FormMixin from "../../mixins/form";
import {getError} from "../../utils/helpers";

export default {
  name: "ResetPassword",

  components: {
    SwitchLocale,
  },
  mixins: [FormMixin],
  setup: () => ({v$: useVuelidate()}),
  data() {
    return {
      pass: {
        active: false,
      },
      passConf: {
        active: false,
      },
      step: 1,
      form: {
        token: "",
        email: "",
        password: "",
        password_confirmation: "",
      },
      error: null,
      message: null,
    };
  },
  validations() {
    return {
      form: {
        password: {
          required: helpers.withMessage(
            () => this.$t("password-required"),
            required
          ),
          minLength: minLength(8),
        },
        password_confirmation: {
          sameAs: helpers.withMessage(
            () => this.$t("same-as-password"),
            sameAs(this.form.password)
          ),
        },
      },
    };
  },
  methods: {
    async submit() {
      this.error = null;
      this.message = null;

      this.v$.$validate();

      if (!this.v$.$error) {
        this.v$.$reset();
      }

      await this.$store
        .dispatch("auth/RESET_PASSWORD", this.form)
        .then(() => {
          this.step = 2;
        })
        .catch((error) => {
          this.error = getError(error);
        });
    },
  },
  mounted() {
    this.form.email = this.$route.query.email;
    this.form.token = this.$route.params.token;

    let app = document.querySelector("#app");
    app.classList = "";

    this.$store.commit("headerHide");
    this.$store.commit("navHide");

    this.focusInput();
  },
};
</script>
