<template>
  <router-link :to="to">
    <svg
      class="stories__close"
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="2D2D2F"
      xmlns="http://www.w3.org/2000/svg"
      :stroke="stroke"
    >
      <path
        d="M2 2L20.7949 20.7949"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.4602 2L2.66532 20.7949"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </router-link>
</template>

<script>

export default {
  name: "Close",

  props: {
    to: {
      type: String,
      required: true
    },
    stroke: {
      type: String,
      default: '#ffffff'
    }
  }
};
</script>
