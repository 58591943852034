{
  "add-home": "홈 화면에 바로 가기 추가",
  "add-notes": "메모 추가",
  "add-notes-descr": "귀하의 와인 여정에 대한 모든 생각을 기록하는 개인적인 공간입니다.",
  "add_homescreen_app": "탭한 다음 홈 스크린에 추가",
  "age-location-text": "트리벤토의 와인 나침반으로 여정을 시작하려고 합니다. 귀하가 합법적으로 음주할 수 있는 연령을 충족했는지와 귀하의 위치만 알면 됩니다.",
  "aroma": "향",
  "aroma-rate-text": "이 와인의 향이 얼마나 마음에 드셨나요?",
  "become-member": "멤버가되다",
  "become-member-text": "모험심이 느껴지시나요? 와인 나침반의 더 많은 코너 탐색",
  "birth-year": "내 출생 연도",
  "birth-year-required": "계속하려면 출생 연도를 입력하세요",
  "bodega-trivento": "보데가 트리벤토 아르헨티나",
  "build-taste": "내 취향 프로필을 구축하세요.",
  "caf-thank-you": "귀하의 피드백은 귀하의 마음에 들 다른 트리벤토 와인을 식별하고 추천하는 데 도움이 됩니다 ❤️",
  "choose-bottle": "탐색하고 싶은 트리벤토 보틀을 선택하세요",
  "choose-option": "경험을 선택하세요",
  "club": "클럽",
  "colour": "색",
  "colour-aroma": "색상, 향 그리고 풍미",
  "colour-rate-text": "이 와인의 색상이 얼마나 마음에 드셨나요?",
  "come-back": "나중에 다시 오세요",
  "confirm": "확인",
  "congrats": "축하합니다",
  "contact-us": "문의",
  "continue": "계속",
  "country": "거주 국가",
  "country-required": "계속하려면 선택한 국가를 입력하십시오",
  "created-pass": "내 와인 셀러가 생성되었습니다. 이제 웹 사이트를 통해 바로 와인에 대해 평가하실 수 있습니다.",
  "did-not-receive": "이메일을 받지 못하셨다면",
  "discover": "살펴보기",
  "edit-profile": "프로필 수정",
  "email": "이메일",
  "email-confirm": "이메일 주소가 변경되었습니다",
  "email-required": "계속하려면 이메일을 입력하세요",
  "enjoy": "즐기기",
  "explore": "탐색",
  "explore-portfolio": "포트폴리오 보기",
  "file-greater": "파일의 크기가 2MB보다 큽니다",
  "flavour": "풍미",
  "flavour-rate-text": "이 와인의 맛이 얼마나 마음에 드셨나요?",
  "forgot-pass": "비밀번호를 잊어 버렸습니까?",
  "fresh-verification-link": "이메일로 새 인증 링크가 전송되었습니다",
  "go-to-palate": "내 팔레트로 이동",
  "go-to-passport": "내 셀러로 이동",
  "goes-well": "마리아주",
  "harvest": "수확량",
  "hide-taste": "내 취향 숨기기",
  "home": "홈",
  "i-accept": "이용약",
  "id-be-happy": "물론이죠. 기꺼이 하겠습니다!",
  "journey-descr": "크루즈 델 알토, 2016년 가을",
  "keep-updated": "최신 정보를 알려주세요.",
  "know-story": "숨겨진 스토리",
  "legal-drinking": "법적 음주 연령을 충족합니다.",
  "make-note": "노트 만들기",
  "mendoza-argentina": "멘도사-아르헨티나",
  "my-cellar": "내 셀러",
  "my-taste": "내 취향",
  "name": "이름",
  "name-required": "계속하려면 이름을 입력하세요",
  "new": "신규",
  "news-for-you": "맞춤형 뉴스",
  "no": "아니요",
  "nothing": "아직 아무것도 없습니다",
  "ok": "확인",
  "opinion-important": "귀하의 의견은 중요합니다.",
  "palate": "내  팔레트",
  "palate-empty": "당신의 입천장이 비어 있습니다",
  "password": "비밀번호",
  "password-required": "계속하려면 비밀번호를 입력하세요",
  "podcast": "팟캐스트 에피소드",
  "profile": "프로필",
  "profile-update-confirm": "프로필이 성공적으로 업로드되었습니다",
  "promotion": "프로모션",
  "rate-wine": "와인 평가",
  "request-again": "다시 요청해 주세요",
  "request-password-reset": "비밀번호 초기화 요청",
  "resend-pass": "비밀번호 재설정 재요청",
  "resend-pass-again": "{0}초 후 비밀번호를 재설정할 수 있습니다",
  "reset-pass": "비밀번호 재설정",
  "reset-pass-email-sent": "비밀번호 재설정 이메일 전송됨",
  "restore-conf-pass": "새 비밀번호 확인",
  "restore-create-pass": "새 비밀번호",
  "restore-enter-email": "비밀번호를 재설정하려면 계정에 연결된 이메일을 입력하세요",
  "restore-new-pass": "새 비밀번호",
  "restore-pass": "비밀번호 복구",
  "restore-pass-reset": "비밀번호가 성공적으로 재설정되었습니다. 새 비밀번호로 로그인하세요.",
  "restore-unic-pass": "비밀번호는 최소 8문자여야 합니다",
  "restore-we-have-sent": "이메일 주소로 비밀번호 재설정 방법을 전송했습니다",
  "rights-reserved": "All Rights Reserved 2021",
  "same-as-password": "비밀번호가 일치하지 않습니다",
  "save-changes": "변경 사항 저장",
  "save-note": "메모 저장",
  "save-notes": "내 메모 저장",
  "select-country": "계속하려면 선택한 국가를 입력하십시오.",
  "select-year-birth": "출생 연도 선택",
  "shop": "쇼핑",
  "show-taste": "내 취향 보기",
  "sign-in": "로그인",
  "sign-out": "로그아웃",
  "sign-up": "가입",
  "spare-minutes": "몇 분 정도 시간을 내어 비비노에 대해 평가해 주시겠습니까?",
  "subscribe": "네, 더 알아보고 싶습니다.",
  "subscribed-newsletter": "뉴스레터 구독",
  "surname": "성",
  "surname-required": "계속하려면 성을 입력하세요",
  "taste-palate": "내  팔레트",
  "terms-conditions": "관에 동의합니다",
  "thank-you": "감사합니다!",
  "thank-you-member": "회원이 되신 것을 진심으로 축하드립니다",
  "to-all-wines": "모든 와인 보기",
  "trivento-wines": "트리벤토 와인",
  "type-email": "이메일을 입력하세요",
  "type-name": "이름을 입력하세요",
  "type-surname": "성을 입력하세요",
  "variety": "품종",
  "vendors": "판매점",
  "visit-trivento": "Trivento.com 방문",
  "visit-website": "웹 사이트 방문",
  "vivino-website": "비비노 바로가기",
  "welcome": "환영합니다!",
  "wine": "와인",
  "wine-added": "이 와인은 당신의 등급과 함께 당신의 여권에 추가되었습니다",
  "wine-journal": "내 와인 일기",
  "write-notes": "여기에서 메모를 작성하세요.",
  "yes": "예",
  "your-cellar": "내 셀러",
  "your-journey": "내 여정",
  "your-rate": "와인에 대한 내 평가",
  "accept-term": "이용약 관에 동의합니다",
  "no-promotions": "판매자 웹사이트에서 최신 프로모션에 대해 알아보세요.",

  "enable-geolocation": "위치 정보 활성화하기",
  "geolocation-description": "Trivento Wine Compass는 개인 맞춤 콘텐츠를 제공하기 위해 귀하의 위치 정보에 접근하고자 합니다. 활성화하려면 다음 단계를 따르세요:",
  "geolocation_step_1": "핸드폰의 \"설정\" 옵션으로 이동하세요.",
  "geolocation_step_2": "\"개인 정보 및 보안\" 옵션을 선택하세요.",
  "geolocation_step_3": "\"위치\" 옵션을 선택하세요.",
  "geolocation_step_4": "사용 중인 브라우저를 찾아 \"앱 사용 중에만 허용\" 옵션을 선택하세요.",
  "wines": "와인",
  "experiences": "경험"
}
