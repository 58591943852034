// import $ from "jquery";
// window.jQuery = $;
// window.$ = $;

let common = document.addEventListener("DOMContentLoaded", () => {
  let arrInvalid = document.querySelectorAll(".form__input-invalid");
  // console.log(arrInvalid);

  for (let i = 0; i < arrInvalid.length; i++) {
    // let label = arrInvalid[i];
    // let label = arrInvalid[i].closest(".form__row");
    // console.log(label);
    // label.style.color = "#FF4343";
  }

  // $(".select-year").select2({
  //   placeholder: "Select your year of birth"
  // });
});

export default common;
