<template>
  <div class="locale-changer language-list">
    <img
      class="language-list__arrow"
      src="@/assets/img/icon-arrow-down.svg"
      alt="arrow"
    />
    <select
      v-if="type === 'select'"
      v-model="$i18n.locale"
      class="select locale"
      style="text-transform: uppercase !important"
      @change="switchLocale($i18n.locale)"
    >
      <option
        v-for="(locale, i) in languages"
        :key="`Lang${i}`"
        :value="locale"
        :selected='option'
      >
        {{ titles[i] }}
      </option>
    </select>
    <ul v-else-if="type === 'list'">
      <li v-for="locale in locales" :key="locale" @click="switchLocale(locale)">
        {{ locale }}
      </li>
    </ul>
  </div>
</template>

<script>
import {mapState} from "vuex";
export default {
  name: "SwitchLocale",

  props: {
    type: {
      type: String,
      validator: val => ["select", "list"].includes(val),
      default: "select"
    },
  },
  /*watch: {
    languages(val) {
      this.locales = this.getCookie('locales') ? this.getCookie('locales').split(",") : process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(",")
      this.titles = this.getCookie('locales') ? this.getCookie('locales').split(",") : process.env.VUE_APP_I18N_LANGUAGE_TITLE.split(",")
    }
  },*/
  data() {
    return {
      // locales: this.getCookie('locales') ? this.getCookie('locales').split(",") : process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(","),
      // titles: this.getCookie('locales') ? this.getCookie('locales').split(",") : process.env.VUE_APP_I18N_LANGUAGE_TITLE.split(","),
      option: ''
    };
  },
  methods: {
    switchLocale(lang) {
      this.setLocalesForIp()
      for (let i = 0; i < this.locales.length; i++) {
        if (lang === this.locales[i]) {
          this.$i18n.locale = this.locales[i];
          this.setCookie("locale", this.locales[i], "30d");
          this.$store.dispatch("setting/SET_LOCALE", this.locales[i]);
        }
      }
    },
    setCookie(name, val, expire) {
      this.$cookies.set(name, val, expire);
    },
    getCookie(name) {
      return this.$cookies.get(name);
    },
    exist(name) {
      this.$cookies.isKey(name);
    },
    checkLocale() {
      return this.getCookie("locale");
    },
    setLocalesForIp() {
      this.locales = this.getCookie('locales') ? this.getCookie('locales').split(",") : process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(",")
      this.titles = this.getCookie('locales') ? this.getCookie('locales').split(",") : process.env.VUE_APP_I18N_LANGUAGE_TITLE.split(",")
    }
  },
  computed: {
    ...mapState("setting", ["languages", 'titles']),
  },
};
</script>
