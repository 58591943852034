{
  "add-home": "添加主屏幕快捷方式",
  "add-notes": "添加您的笔记",
  "add-notes-descr": "此处是您的私人空间，您可以在这里记录下全部关于葡萄酒之旅的想法。",
  "add_homescreen_app": "点击然后添加到主屏幕",
  "age-location-text": "您的风之语酒庄的葡萄酒之旅即将启程。我们想知道您是否已到法定饮酒年龄以及您所在的位置。",
  "aroma": "芳香",
  "aroma-rate-text": "您对此款葡萄酒芳香的喜爱程度是多少？",
  "become-member": "成为会员",
  "become-member-text": "是否感到兴奋？探索葡萄酒罗盘的更多角落",
  "birth-year": "您的出生年份",
  "birth-year-required": "请输入您的出生年份",
  "bodega-trivento": "阿根廷风之语酒庄",
  "build-taste": "建立您的品味档案。",
  "caf-thank-you": "您对此款葡萄酒的反馈将帮助我们了解您的喜好，并向您展示其他您可能喜欢❤️的风之语葡萄酒",
  "choose-bottle": "选择您想探索的一瓶风之语葡萄酒",
  "choose-option": "选择搭配",
  "club": "俱乐部",
  "colour": "色泽",
  "colour-aroma": "色香味",
  "colour-rate-text": "您对此款葡萄酒色泽的喜爱程度是多少？",
  "come-back": "请晚点再来。",
  "confirm": "确认",
  "congrats": "恭喜",
  "contact-us": "联系我们",
  "continue": "继续",
  "country": "居住国家",
  "country-required": "请选择国家以继续",
  "created-pass": "我们为您打造了一个酒窖，现在您可以直接在我们的网站上对葡萄酒进行评价",
  "did-not-receive": "如果你没有收到电子邮件",
  "discover": "发现",
  "edit-profile": "编辑个人资料",
  "email": "邮箱",
  "email-confirm": "您的电子邮件地址已更改",
  "email-required": "请输入您的电子邮件",
  "enjoy": "享用",
  "explore": "探索",
  "explore-portfolio": "产品浏览",
  "file-greater": "您的文件大小超过2MB",
  "flavour": "风味",
  "flavour-rate-text": "您有多喜欢此款葡萄酒的口味？",
  "forgot-pass": "忘记密码？",
  "fresh-verification-link": "新的验证链接已发送到您的电子邮件地址",
  "go-to-palate": "去我的味觉专栏",
  "go-to-passport": "去我的地窖",
  "goes-well": "相当匹配",
  "harvest": "收获",
  "hide-taste": "隐藏我的口味",
  "home": "主页",
  "i-accept": "我接受",
  "id-be-happy": "当然，我很乐意！",
  "journey-descr": "Cruz del Alto，2016年 秋天",
  "keep-updated": "消息通知",
  "know-story": "知道这个故事",
  "legal-drinking": "我已到法定饮酒年龄",
  "make-note": "添加您的笔记",
  "mendoza-argentina": "阿根廷门多萨",
  "my-cellar": "我的酒窖",
  "my-taste": "我的口味",
  "name": "名",
  "name-required": "请输入您的姓名",
  "new": "新的",
  "news-for-you": "新消息",
  "no": "否",
  "nothing": "这里还是空的。",
  "ok": "好的",
  "opinion-important": "您的意见非常重要。",
  "palate": "您的喜好",
  "palate-empty": "你的味觉专栏还没有内容",
  "password": "密码",
  "password-required": "请输入您的密码",
  "podcast": "播客集",
  "profile": "您的档案",
  "profile-update-confirm": "配置文件更新成功",
  "promotion": "促销",
  "rate-wine": "评价葡萄酒",
  "request-again": "请再要求一次",
  "request-password-reset": "请求密码重置",
  "resend-pass": "重新发送密码恢复说明",
  "resend-pass-again": "您可以在{0}秒内再次重新发送重置密码",
  "reset-pass": "重置密码",
  "reset-pass-email-sent": "重置密码电子邮件已发送",
  "restore-conf-pass": "确认新密码",
  "restore-create-pass": "创建新密码",
  "restore-enter-email": "输入与帐户关联的电子邮件以找回您的密码",
  "restore-new-pass": "新密码",
  "restore-pass": "恢复密码",
  "restore-pass-reset": "您的密码已成功重置。请在登录时使用您的新密码",
  "restore-unic-pass": "密码必须至少包含8个字符",
  "restore-we-have-sent": "我们已向您的电子邮件地址发送了密码找回说明",
  "rights-reserved": "版权所有 2021 年",
  "same-as-password": "确认密码不匹配",
  "save-changes": "保存更改",
  "save-note": "保存您的记录",
  "save-notes": "保存我的笔记",
  "select-country": "请选择国家以继续",
  "select-year-birth": "选择您的出生年份",
  "shop": "商店",
  "show-taste": "展示我的口味",
  "sign-in": "登录",
  "sign-out": "注销",
  "sign-up": "注册",
  "spare-minutes": "您可以花一点点时间在 Vivino 上给我们评价吗？",
  "subscribe": "是的，我想要探索更多。",
  "subscribed-newsletter": "订阅通讯",
  "surname": "姓",
  "surname-required": "请输入您的姓氏",
  "taste-palate": "您的喜好",
  "terms-conditions": "条款和条件",
  "thank-you": "谢谢！",
  "thank-you-member": "感谢您加入我们会员",
  "to-all-wines": "返回到葡萄酒",
  "trivento-wines": "风之语葡萄酒",
  "type-email": "输入您的邮箱地址",
  "type-name": "输入您的名字",
  "type-surname": "输入您的姓氏",
  "variety": "多样",
  "vendors": "供应商",
  "visit-trivento": "访问 Trivento.com",
  "visit-website": "访问我们的网站",
  "vivino-website": "去 Vivino",
  "welcome": "欢迎",
  "wine": "葡萄酒",
  "wine-added": "该酒和您的评级已添加至您的护照中",
  "wine-journal": "我的葡萄酒之旅",
  "write-notes": "请将笔记写在此处。",
  "yes": "是",
  "your-cellar": "您的酒窖",
  "your-journey": "您的旅程",
  "your-rate": "您对这款酒的评价",
  "accept-term": "我接受 条款和条件",
  "no-promotions": "在商家網站上了解最新的促銷活動。",

  "enable-geolocation": "启用地理定位",
  "geolocation-description": "Trivento Wine Compass希望访问您的位置以提供个性化内容。要启用它，请按照以下步骤操作：",
  "geolocation_step_1": "打开您手机的\"设置\"选项。",
  "geolocation_step_2": "选择\"隐私与安全\"选项。",
  "geolocation_step_3": "选择\"位置\"选项。",
  "geolocation_step_4": "找到您正在使用的浏览器，然后选择\"仅在使用应用时\"选项。",
  "wines": "葡萄酒",
  "experiences": "经验"
}
