<template>
  <section :class="{ active: active }" class="terms">
    <div class="terms__inner">
      <img alt="back" src="@/assets/img/icon-arrow-left-white.svg" @click="toggle()"/>
      <div class="justify-center">
        <LogoPrimary/>
      </div>
    </div>
    <h1>{{ $t("terms-conditions") }}</h1>
    <p v-html="terms_conditions[ this.$i18n.locale ]"></p>
    <a href="http://trivento.com/" target="_blank">
      {{ $t("visit-trivento") }}
    </a>
  </section>
</template>

<script>
import LogoPrimary from "@/components/LogoPrimary.vue";
import {mapGetters} from "vuex";

export default {
  name: "TermsConditions",

  components: {
    LogoPrimary,
  },
  computed: {
    ...mapGetters("setting", ["settings", "terms"]),
    terms_conditions: function () {
      let byLocale = [];
      this.terms.map(function (item) {
        byLocale[item.key] = item.val
      })
      return byLocale
    }
  },
  methods: {
    toggle() {
      let terms = document.querySelector('.terms');

      if (terms.classList.contains('active')) {
        terms.classList.remove('active');
      } else {
        terms.classList.add('active')
      }
    }
  },
  mounted() {
    this.$store.cache.dispatch('setting/GET_TERMS');

    this.$store.commit("navHide");
    this.$store.commit("userHide");
    this.$store.commit("burgerHide");
    this.$store.commit("linkBackActive");

    let app = document.querySelector("#app");
    let agePage = document.querySelector('.age');

    if (!agePage) {
      app.className = "";
      app.classList.add("isTerms");
    }
  },
  unmounted() {
    this.$store.commit("linkBackDisable");
  },
};
</script>
