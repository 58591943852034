{
  "add-home": "Tilføj en genvej til din hjemmeskærm",
  "add-notes": "Tilføj dine notater",
  "add-notes-descr": "Dette er dit personlig rum. Her kan du fører en log med dine tanker om din vinrejse.",
  "add_homescreen_app": "Tryk og tilføj til hjemmeskærm",
  "age-location-text": "Din rejse med Triventos vinkompas skal til at begynde. Vi skal bare lige sikre os, at du er gammel nok til at kunne drikke lovligt, der hvor du er lokaliseret.",
  "aroma": "Aroma",
  "aroma-rate-text": "I hvor høj grad kunne du lide vinens aroma?",
  "become-member": "Bliv medlem",
  "become-member-text": "Er du eventyrlysten? Udforsk flere hjørner af Triventos vinkompas",
  "birth-year": "Dit fødselsår",
  "birth-year-required": "Tilføj dit fødselsår for at fortsætte",
  "bodega-trivento": "Bodega Trivento Argentina",
  "build-taste": "Opbyg din smagsprofil",
  "caf-thank-you": "Din feedback hjælper os med at identificere og vise dig andre Trivento vine som du vil elske❤",
  "choose-bottle": "Vælg den Trivento flaske du ønsker at udforske",
  "choose-option": "Vælg en oplevelse",
  "club": "KLUBBEN",
  "colour": "Farve",
  "colour-aroma": "Farve, aroma og smag",
  "colour-rate-text": "I hvor høj grad kunne du lide vinens farve?",
  "come-back": "Kom tilbage senere",
  "confirm": "Godkend",
  "congrats": "Tillykke",
  "contact-us": "KONTAKT OS",
  "continue": "Fortsæt",
  "country": "Bopælsland",
  "country-required": "Gå til vælg land for at fortsætte",
  "created-pass": "Vi har opført en vinkælder til dig, du kan nu bedømme vinen direkte på vores hjemmeside",
  "did-not-receive": "Hvis du ikke modtog emailen",
  "discover": "OPDAG",
  "edit-profile": "REDIGER DIN PROFIL",
  "email": "Email",
  "email-confirm": "Din mailadresse blev ændret",
  "email-required": "Tilføj din email for at fortsætte",
  "enjoy": "NYD DET",
  "explore": "UDFORSK",
  "explore-portfolio": "Udforsk porteføljen",
  "file-greater": "Din fil størrelse er større end 2MB",
  "flavour": "Smag",
  "flavour-rate-text": "I hvor høj grad kunne du lide vinens smag?",
  "forgot-pass": "Har du glemt dit kodeord?",
  "fresh-verification-link": "Et nyt link til verifikation er blevet sendt til din email",
  "go-to-palate": "Gå til Min gane",
  "go-to-passport": "Gå til min vinkælder",
  "goes-well": "Går godt sammen med",
  "harvest": "HØST",
  "hide-taste": "Forhandlere",
  "home": "HJEM",
  "i-accept": "Jeg accepterer",
  "id-be-happy": "Selvfølgelig. Det gør jeg med glæde",
  "journey-descr": "Cruz del Alto, Efterår 2016",
  "keep-updated": "Hold mig opdateret",
  "know-story": "Lær historien at kende",
  "legal-drinking": "Jeg er gammel nok til at drikke",
  "make-note": "Tilføj dine noter",
  "mendoza-argentina": "Mendoza, Argentina",
  "my-cellar": "Min kælder",
  "my-taste": "Tilbage til alle vine",
  "name": "Fornavn",
  "name-required": "Tilføj dit navn for at fortsætte",
  "new": "Ny",
  "news-for-you": "Nyheder til dig",
  "no": "Nej",
  "nothing": "Her er ikke noget endnu",
  "ok": "Ok",
  "opinion-important": "Din mening er vigtig",
  "palate": "Dine Palette",
  "palate-empty": "Din gane er tom",
  "password": "Kodeord",
  "password-required": "Tilføj dit kodeord for at fortsætte",
  "podcast": "PODCAST EPISODER",
  "profile": "Din profil",
  "profile-update-confirm": "Profilen blev opdateret",
  "promotion": "PROMOVERINGER",
  "rate-wine": "Vurder din vin",
  "request-again": "Anmod igen",
  "request-password-reset": "Anmod om at få fornyet kodeordet",
  "resend-pass": "Gensend instruktioner til at fornye kodeordet",
  "resend-pass-again": "Du kan gensende anmodningen om at få fornyet dit kodeord om {0} sekunder",
  "reset-pass": "Forny kodeordet",
  "reset-pass-email-sent": "En email til brug for fornyelse af kodeordet er sendt",
  "restore-conf-pass": "Bekræft dit nye kodeord",
  "restore-create-pass": "Lav et nyt kodeord",
  "restore-enter-email": "Tilføj emailen der hører til kontoen for at gendanne dit kodeord",
  "restore-new-pass": "Nyt kodeord",
  "restore-pass": "Gendan kodeord",
  "restore-pass-reset": "Dit kodeord er blevet fornyet. Brug dit nye kodeord til at logge ind",
  "restore-unic-pass": "Dit kodeord skal indeholde mindst 8 tegn",
  "restore-we-have-sent": "Vi har sendt instruktioner til gendannelse af kodeord til din email",
  "rights-reserved": "Alle rettigheder forbeholdes",
  "same-as-password": "Kodeordene stemmer ikke overens",
  "save-changes": "GEM MINE ÆNDRINGER",
  "save-note": "Gem din note",
  "save-notes": "Gem mine notater",
  "select-country": "Gå til vælg land for at fortsætte",
  "select-year-birth": "Vælg dit fødselsår",
  "shop": "KØB",
  "show-taste": "Vis Min smag",
  "sign-in": "Log ind",
  "sign-out": "Log ud",
  "sign-up": "Tilmeld dig",
  "spare-minutes": "Har du et par minutter til en anmeldelse?",
  "subscribe": "Ja, jeg vil gerne udforske mere.",
  "subscribed-newsletter": "Opskrevet til nyhedsbrev",
  "surname": "Efternavn",
  "surname-required": "Tilføj dit efternavn for at fortsætte",
  "taste-palate": "DIN PALETTE",
  "terms-conditions": "vilkår og betingelser",
  "thank-you": "Tak!",
  "thank-you-member": "TAK FORDI DU ER BLEVET MEDLEM",
  "to-all-wines": "Tilbage til alle vine",
  "trivento-wines": "Trivento vine",
  "type-email": "Indtast din email",
  "type-name": "Skriv dit navn",
  "type-surname": "Skriv dit efternavn",
  "variety": "SORT",
  "vendors": "Forhandlere",
  "visit-trivento": "Besøg Trivento.com",
  "visit-website": "BESØG VORES HJEMMESIDE",
  "vivino-website": "Besøg Vivino",
  "welcome": "VELKOMMEN",
  "wine": "VIN",
  "wine-added": "Vinen og din bedømmelse var tilføjettil dit pas",
  "wine-journal": "Min vinjournal",
  "write-notes": "Skriv dine notater her",
  "yes": "Ja",
  "your-cellar": "Din kælder",
  "your-journey": "Din rejse",
  "your-rate": "Din bedømmelse af vinen",
  "accept-term": "Jeg accepterer vilkår og betingelser",
  "no-promotions": "Find ud af de seneste kampagner på butikkernes websteder.",

  "enable-geolocation": "Aktivér geolokalisering",
  "geolocation-description": "Trivento Wine Compass ønsker at få adgang til din placering for at levere personligt indhold. For at aktivere det, skal du følge disse trin:",
  "geolocation_step_1": "Gå til \"Indstillinger\" på din telefon.",
  "geolocation_step_2": "Vælg \"Privatliv og sikkerhed\".",
  "geolocation_step_3": "Vælg \"Placering\".",
  "geolocation_step_4": "Find den browser, du bruger, og vælg \"Mens du bruger appen\" muligheden.",
  "wines": "Vine",
  "experiences": "Oplevelser"
}
