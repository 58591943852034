import axios from 'axios'

export default {
  namespaced: true,

  state: {
    shops: [],
    error: null,
  },

  getters: {
    shops: (state) => {
      return state.shops
    },
    error: (state) => {
      return state.error;
    },
  },

  mutations: {
    SET_SHOPS(state, shops) {
      state.shops = shops
    },
    SET_ERROR(state, error) {
      state.error = error;
    },
  },

  actions: {
    GET_SHOPS: async ({commit}, country) => {
      commit('loading', true, {root: true})

      await axios.get(`/api/shops/${country}`)
        .then((response) => {
          commit('SET_SHOPS', response.data.data)
          commit('loading', false, {root: true})
        }).catch((error) => {
          commit('SET_SHOPS', [])
          commit('loading', false, {root: true})
        })
    }
  }
}
