{
  "add-home": "ホーム画面にショートカットを追加",
  "add-notes": "メモを追加",
  "add-notes-descr": "ここはワインの旅について気になった点などを記録する個人的なコーナーです。",
  "add_homescreen_app": "をタップし、ホームスクリーンに追加します",
  "age-location-text": "Triventoが贈るワインの旅が、間もなく始まります。あなたが合法的にお酒を飲める年齢かどうかと、お住まいの国を教えてください。",
  "aroma": "香り",
  "aroma-rate-text": "このワインの香りはどれくらい好きですか？",
  "become-member": "会員になる",
  "become-member-text": "フィーリング 冒険的ですか？ワインコンパスのその他のコーナーを探索する",
  "birth-year": "生まれた年",
  "birth-year-required": "続行するには生まれた年を入力してください",
  "bodega-trivento": "ボデガ・トリヴェント・アルゼンチン",
  "build-taste": "テイストプロフィールを作成",
  "caf-thank-you": "あなたのフィードバックは重要です。 Vivinoで当社を評価するために数分お時間をいただけますか？",
  "choose-bottle": "調べたいトリヴェントのボトルを選択してください",
  "choose-option": "体験を選ぶ",
  "club": "クラブ",
  "colour": "色",
  "colour-aroma": "色、香り、味わい",
  "colour-rate-text": "このワインの色はどれくらい好きですか？",
  "come-back": "後で戻って来て。",
  "confirm": "確定",
  "congrats": "おめでとうございます",
  "contact-us": "お問い合わせ",
  "continue": "続ける",
  "country": "居住国",
  "country-required": "続行するには、選択した国を入力してください",
  "created-pass": "あなたのワインセラーを作成しました。これでウェブサイトから直接ワインを評価することができます。",
  "did-not-receive": "メールが届かない場合",
  "discover": "トリヴェントについて",
  "edit-profile": "プロフィールを編集",
  "email": "メールアドレス",
  "email-confirm": "メールアドレスが変更されました",
  "email-required": "続行するにはメールアドレスを入力してください",
  "enjoy": "楽しみかた",
  "explore": "商品ラインナップ",
  "explore-portfolio": "ポートフォリオ",
  "file-greater": "ファイルサイズが2MBを超えています",
  "flavour": "味",
  "flavour-rate-text": "このワインの味はどれくらい好きですか？",
  "forgot-pass": "パスワードをお忘れですか？",
  "fresh-verification-link": "新たな承認リンクがメールアドレスに送信されました",
  "go-to-palate": "あなたの好みへ",
  "go-to-passport": "あなたの購入履歴へ",
  "goes-well": "ペアリング",
  "harvest": "収穫",
  "hide-taste": "マイテイストを非表示",
  "home": "ホーム",
  "i-accept": "同意",
  "id-be-happy": "もちろん、よろしくお願いします！",
  "journey-descr": "クルスデルアルト、2016年秋",
  "keep-updated": "最新情報を受信する",
  "know-story": "ストーリー",
  "legal-drinking": "私は成人です",
  "make-note": "メモを作成する",
  "mendoza-argentina": "メンドーサ、アルゼンチン",
  "my-cellar": "私のワインセラー",
  "my-taste": "マイテイスト",
  "name": "名前",
  "name-required": "続行するには名前を入力してください",
  "new": "New",
  "news-for-you": "最新ニュース",
  "no": "いいえ",
  "nothing": "まだ何も無い。",
  "ok": "OK",
  "opinion-important": "あなたの意見は重要です。",
  "palate": "あなたの好み",
  "palate-empty": "まだ登録されていません",
  "password": "パスワード",
  "password-required": "続行するにはパスワードを入力してください",
  "podcast": "PODCASTエピソード",
  "profile": "プロフィール",
  "profile-update-confirm": "プロフィールが正常に更新されました",
  "promotion": "プロモーション",
  "rate-wine": "ワインを評価する",
  "request-again": "もう一度リクエストする",
  "request-password-reset": "パスワードリセットをリクエスト",
  "resend-pass": "パスワード復元手順を再送信",
  "resend-pass-again": "パスワードリセットを{0}秒後に再送信できます",
  "reset-pass": "パスワードをリセットする",
  "reset-pass-email-sent": "パスワードリセットのメールを送信しました",
  "restore-conf-pass": "新しいパスワードを確認",
  "restore-create-pass": "新しいパスワードを作成",
  "restore-enter-email": "アカウントに関連付けられているメールアドレスを入力して、パスワードを復元します",
  "restore-new-pass": "新しいパスワード",
  "restore-pass": "パスワードを復元",
  "restore-pass-reset": "パスワードは正常にリセットされました。サインインする際に新しいパスワードを使用してください",
  "restore-unic-pass": "パスワードは8文字以上含まれている必要があります",
  "restore-we-have-sent": "パスワード復元手順をメールアドレスに送信しました",
  "rights-reserved": "All Rights Reserved 2021",
  "same-as-password": "確認用パスワードが一致しません",
  "save-changes": "変更を保存",
  "save-note": "メモを保存",
  "save-notes": "メモを保存",
  "select-country": "住居国を選択してください",
  "select-year-birth": "生まれた年を選択してください",
  "shop": "ショッピング",
  "show-taste": "マイテイストを表示",
  "sign-in": "サインイン",
  "sign-out": "サインアウト",
  "sign-up": "サインアップ",
  "spare-minutes": "Vivinoで当社を評価するために数分お時間をいただけますか？",
  "subscribe": "はい、もっと調べたいです。",
  "subscribed-newsletter": "ニュースレターを購読",
  "surname": "苗字",
  "surname-required": "続行するには名字を入力してください",
  "taste-palate": "あなたの味覚",
  "terms-conditions": "利用規約",
  "thank-you": "ありがとうございました！",
  "thank-you-member": "メンバー登録いただきましてありがとうございます",
  "to-all-wines": "すべてのワインに戻る",
  "trivento-wines": "トリヴェント",
  "type-email": "メールアドレスを入力してください",
  "type-name": "名前を入力してください",
  "type-surname": "名字を入力してください",
  "variety": "品種",
  "vendors": "ベンダー",
  "visit-trivento": "Trivento.comにアクセス",
  "visit-website": "ウェブサイトにアクセス",
  "vivino-website": "Vivinoに移動",
  "welcome": "ようこそ",
  "wine": "ワイン",
  "wine-added": "このワインとあなたの評価がパスポートに追加されました",
  "wine-journal": "ワイン日記",
  "write-notes": "ここにメモを書いてください。",
  "yes": "はい",
  "your-cellar": "あなたのワインセラー",
  "your-journey": "履歴",
  "your-rate": "このワインに対するあなたの評価",
  "accept-term": "同意 します契約条件条件",
  "no-promotions": "ショップのホームページで最新のキャンペーン情報を確認する。",

  "enable-geolocation": "位置情報を有効にする",
  "geolocation-description": "Trivento Wine Compassは、個別化されたコンテンツを提供するために、あなたの位置情報にアクセスしたいと思っています。有効にするには、以下の手順に従ってください：",
  "geolocation_step_1": "携帯電話の「設定」オプションに移動します。",
  "geolocation_step_2": "「プライバシーとセキュリティ」オプションを選択します。",
  "geolocation_step_3": "「位置情報」オプションを選択します。",
  "geolocation_step_4": "使用中のブラウザを見つけ、「アプリ使用時にのみ許可」オプションを選択します。",
  "wines": "ワイン",
  "experiences": "経験"
}
