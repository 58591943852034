<template>
  <section class="promo club">
    <h1>{{ $t("promotion") }}</h1>
    <div v-if="promotions.length !== 0">
      <div v-for="promo in promotions" :key="promo.id">
        <a
          class="promo__item promo__item--new"
          :href="promo.link"
          target="_blank"
        >
          <img v-lazy="promo.image" :alt="promo.title[this.$i18n.locale]" />
          <span v-if="promo.newest">{{ $t("new") }}</span>
          <h2>{{ promo.title[this.$i18n.locale] }}</h2>
          <p>{{ promo.description[this.$i18n.locale] }}</p>
        </a>
      </div>
    </div>
    <div class="nothing">
      <p>{{ $t("no-promotions") }}</p>
    </div>
<!--    <nothing v-else />-->
  </section>
</template>

<script>
import {mapGetters} from "vuex";
// import Nothing from "../components/Nothing";

export default {
  name: "Promotions",

  components: {
    // Nothing,
  },
  computed: {
    ...mapGetters("promotion", ["promotions"]),
  },
  mounted() {
    const params = this.$route.query;
    if(Object.prototype.hasOwnProperty.call(params, 'promotion')) {
      this.$store.dispatch('promotion/GET_PROMOTION_PREVIEW', params.promotion);
    } else {
      let countryOfUser = this.$cookies.get("country");
      let stateOfUser = this.$cookies.get("state");
      this.$store.cache.dispatch("promotion/GET_PROMOTIONS", {countryOfUser, stateOfUser});

      this.$store.commit("navHide");
      this.$store.commit("linkBackActive");
      this.$store.commit("burgerHide");
      this.$store.commit("userHide");

      let app = document.querySelector("#app");

      app.className = "";
      app.classList.add("isPromotion");
    }
  },
};
</script>
