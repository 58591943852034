export default {
  headerHide(state) {
    state.headerHidden = true;
  },
  headerShow(state) {
    state.headerHidden = false;
  },
  navHide(state) {
    state.navHidden = true;
  },
  navShow(state) {
    state.navHidden = false;
  },
  footerHide(state) {
    state.footerHidden = true;
  },
  footerShow(state) {
    state.footerHidden = false;
  },
  exploreActive(state) {
    state.isExplore = true;
  },
  exploreDisable(state) {
    state.isExplore = false;
  },
  enjoyActive(state) {
    state.isEnjoy = true;
  },
  enjoyDisable(state) {
    state.isEnjoy = false;
  },
  shopActive(state) {
    state.isShop = true;
  },
  shopDisable(state) {
    state.isShop = false;
  },
  discoverActive(state) {
    state.isDiscover = true;
  },
  discoverDisable(state) {
    state.isDiscover = false;
  },
  linkBackDisable(state) {
    state.linkBackHidden = true;
  },
  linkBackActive(state) {
    state.linkBackHidden = false;
  },
  burgerHide(state) {
    state.burgerHidden = true;
  },
  burgerShow(state) {
    state.burgerHidden = false;
  },
  userHide(state) {
    state.userHidden = true;
  },
  userShow(state) {
    state.userHidden = false;
  },
  loading(state, value) {
    state.loading = value;
  },
  popupHidden(state, bool) {
    state.popupHidden = bool;
  },

  popupLocationShow(state, bool) {
    state.popupLocation = bool;
  },
};
