{
  "add-home": "Accueil",
  "add-notes": "Ajoutez vos notes",
  "add-notes-descr": "Voici votre espace personnel où vous pourrez garder toutes vos notes sur votre parcours à travers nos vins.",
  "add_homescreen_app": "Tapez et ensuite ajoutez à l'écran d'accueil",
  "age-location-text": "Votre parcours à travers Trivento's wine compass est sur le point de commencer. Nous avons juste besoin de savoir si vous avez l'âge légal pour consommer de l'alcool, et votre emplacement.",
  "aroma": "Arôme",
  "aroma-rate-text": "Avez-vous bien aimé l'arôme de ce vin?",
  "become-member": "Devenir membre",
  "become-member-text": "Avez-vous le goût d'une aventure? Découvrez d'autres coins du Wine Compass",
  "birth-year": "Votre année de naissance",
  "birth-year-required": "Saisissez votre année de naissance pour continuer",
  "bodega-trivento": "Bodega Trivento Argentine",
  "build-taste": "Créez votre profil de goût.",
  "caf-thank-you": "Vos commentaires sur ce vin nous aideront à identifier et à vous présenter d'autres vins Trivento que vous aimerez❤",
  "choose-bottle": "Choisissez la bouteille de Trivento que vous voulez explorer",
  "choose-option": "Choisissez une expérience",
  "club": "LE CLUB",
  "colour": "Couleur",
  "colour-aroma": "Couleur, arôme et saveur",
  "colour-rate-text": "Avez-vous bien aimé la couleur de ce vin?",
  "come-back": "Revenez plus tard",
  "confirm": "CONFIRMER",
  "congrats": "Félicitations",
  "contact-us": "CONTACTEZ-NOUS",
  "continue": "CONTINUER",
  "country": "Pays de résidence",
  "country-required": "Sélectionnez votre pays pour continuer",
  "created-pass": "Nous avons créé une cave à vin pour vous, et maintenant vous pourrez évaluer le vin directement sur notre site web",
  "did-not-receive": "Si vous n'avez pas reçu l'e-mail",
  "discover": "DÉCOUVRIR",
  "edit-profile": "MODIFIER VOTRE PROFIL",
  "email": "E-mail",
  "email-confirm": "Votre adresse e-mail a été modifiée",
  "email-required": "Saisissez votre e-mail pour continuer",
  "enjoy": "DÉGUSTER",
  "explore": "EXPLORER",
  "explore-portfolio": "Explorer le portefeuille",
  "file-greater": "La taille de votre fichier est supérieure à 2 Mo",
  "flavour": "Saveur",
  "flavour-rate-text": "Avez-vous bien aimé la saveur de ce vin?",
  "forgot-pass": "Avez-vous oublié votre mot de passe?",
  "fresh-verification-link": "Un nouveau lien de vérification a été envoyé à votre adresse e-mail.",
  "go-to-palate": "Aller à mon palais",
  "go-to-passport": "Aller sur ma cave",
  "goes-well": "Il s'accorde bien avec",
  "harvest": "RÉCOLTE",
  "hide-taste": "Cacher Mon goût",
  "home": "ACCUEIL",
  "i-accept": "J'accepte",
  "id-be-happy": "Bien sûr, avec plaisir!",
  "journey-descr": "Cruz del Alto, automne 2016",
  "keep-updated": "Je souhaite recevoir d'autres infos",
  "know-story": "Connaissez l'histoire",
  "legal-drinking": "J'ai l'âge légal pour consommer de l'alcool",
  "make-note": "Prenez vos notes",
  "mendoza-argentina": "Mendoza, Argentine",
  "my-cellar": "Ma cave",
  "my-taste": "Mon goût",
  "name": "Prénom",
  "name-required": "Saisissez votre prénom pour continuer",
  "new": "Nouveau",
  "news-for-you": "Des nouvelles pour vous",
  "no": "Non",
  "nothing": "Aucun élément n'est enregistré encore",
  "ok": "D'accord",
  "opinion-important": "Votre opinion est importante.",
  "palate": "Votre palais",
  "palate-empty": "Votre palais est vide",
  "password": "Mot de passe",
  "password-required": "Saisissez votre mot de passe pour continuer",
  "podcast": "ÉPISODES DU PODCAST",
  "profile": "Votre profil",
  "profile-update-confirm": "Profil mis à jour avec succès",
  "promotion": "PROMOTIONS",
  "rate-wine": "Évaluez votre vin",
  "request-again": "Demander à nouveau",
  "request-password-reset": "Réinitialiser votre mot de passe",
  "resend-pass": "Renvoyer les instructions de récupération du mot de passe",
  "resend-pass-again": "Vous pourrez redemander la réinitialisation du mot de passe dans {0} secondes",
  "reset-pass": "Réinitialiser le mot de passe",
  "reset-pass-email-sent": "L'e-mail de réinitialisation du mot de passe a été envoyé",
  "restore-conf-pass": "Confirmer le nouveau mot de passe",
  "restore-create-pass": "Créer un nouveau mot de passe",
  "restore-enter-email": "Saisissez l'adresse e-mail associée au compte pour récupérer votre mot de passe",
  "restore-new-pass": "Nouveau mot de passe",
  "restore-pass": "Restaurer le mot de passe",
  "restore-pass-reset": "Votre mot de passe a été réinitialisé avec succès. Veuillez utiliser votre nouveau mot de passe lorsque vous vous connecterez",
  "restore-unic-pass": "Le mot de passe doit contenir au moins 8 caractères",
  "restore-we-have-sent": "Nous avons envoyé les instructions de récupération du mot de passe à votre adresse e-mail",
  "rights-reserved": "Tous droits réservés 2021",
  "same-as-password": "Le mot de passe de confirmation ne correspond pas",
  "save-changes": "ENREGISTRER",
  "save-note": "Enregistrer vos notes",
  "save-notes": "Enregistrer mes notes",
  "select-country": "Sélectionnez votre pays pour continuer",
  "select-year-birth": "Sélectionnez votre année de naissance",
  "shop": "ACHETER",
  "show-taste": "Afficher Mon goût",
  "sign-in": "Se connecter",
  "sign-out": "Se déconnecter",
  "sign-up": "S'inscrire",
  "spare-minutes": "Pourriez-vous prendre quelques minutes pour nous évaluer sur Vivino?",
  "subscribe": "Oui, j'aimerais en savoir plus.",
  "subscribed-newsletter": "S'abonner à la newsletter",
  "surname": "Nom de famille",
  "surname-required": "Saisissez votre nom de famille pour continuer",
  "taste-palate": "VOTRE PALAIS",
  "terms-conditions": "les conditions générales",
  "thank-you": "Merci!",
  "thank-you-member": "MERCI DE DEVENIR MEMBRE",
  "to-all-wines": "Retourner à tous les vins",
  "trivento-wines": "Vins Trivento",
  "type-email": "Tapez votre e-mail",
  "type-name": "Tapez votre prénom",
  "type-surname": "Tapez votre nom de famille",
  "variety": "VARIÉTÉ",
  "vendors": "Fournisseurs",
  "visit-trivento": "Visitez Trivento.com",
  "visit-website": "VISITEZ NOTRE SITE WEB",
  "vivino-website": "Aller sur Vivino",
  "welcome": "BIENVENUE",
  "wine": "VIN",
  "wine-added": "Le vin et votre note attribuée ont été ajoutés à votre cave",
  "wine-journal": "Mon journal de vin",
  "wine_added": "Le vin et votre note attribuée ont été ajoutés à votre passeport",
  "write-notes": "Écrivez vos notes ici.",
  "yes": "Oui",
  "your-cellar": "Votre cave",
  "your-journey": "Votre parcours",
  "your-rate": "Votre note pour ce vin",
  "accept-term": "J'accepte les conditions générales",
  "no-promotions": "Découvrez les dernières promotions sur le site web des commerces.",

  "enable-geolocation": "Activer la géolocalisation",
  "geolocation-description": "Trivento Wine Compass souhaite accéder à votre emplacement pour fournir un contenu personnalisé. Pour l'activer, suivez ces étapes:",
  "geolocation_step_1": "Allez dans l'option \"Réglages\" sur votre téléphone.",
  "geolocation_step_2": "Sélectionnez l'option \"Confidentialité et sécurité\".",
  "geolocation_step_3": "Choisissez l'option \"Localisation\".",
  "geolocation_step_4": "Trouvez le navigateur que vous utilisez et sélectionnez l'option \"Pendant l'utilisation de l'application\".",
  "wines": "Vins",
  "experiences": "Expériences"
}
