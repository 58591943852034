<template>
  <div class="preloader" v-if="this.$store.state.loading">
    <img src="@/assets/img/preloader.gif" alt="Trivento">
  </div>
</template>

<script>
export default {
  watch: {
    '$store.state.loading': function () {
      if (this.$store.state.loading) {
        document.body.classList.add('loading')
      } else {
        document.body.classList.remove('loading')
      }
    }
  }
}
</script>
