<template>
  <section class="home">
    <h1>{{ $t("choose-option") }}</h1>
    <nav>
      <ul class="home__nav-list">
        <div class="home__compass-holder">
          <img
            class="home__compass"
            alt="Wine Compass"
            src="../assets/img/logo.svg"
          />
          <img
            class="home__compass-arrows"
            src="../assets/img/icon-arrows.svg"
            alt="arrows"
          />
        </div>
        <div class="justify-center">
          <li>
            <router-link to="/explore">
              <span>{{ $t("wines") }}</span>
              <div class="home__icon-holder">
                <img src="/img/wines.png" alt="">
              </div>
            </router-link>
          </li>
        </div>

        <div class="justify-between">
          <li>
            <router-link to="/discover">
              <div class="home__icon-holder">
                <img src="/img/discovers.png" alt="">
              </div>
              <span>{{ $t("experiences") }}</span>
            </router-link>
          </li>
          <li>
            <router-link to="/enjoy">
              <div class="home__icon-holder">
                <img src="/img/enjoys.png" alt="">
              </div>
              <span>{{ $t("enjoy") }}</span>
            </router-link>
          </li>
        </div>

        <div class="justify-center">
          <li>
            <router-link to="/vendors">
              <div class="home__icon-holder">
                <img src="/img/vendors.png" alt="">
              </div>
              <span>{{ $t("shop") }}</span>
            </router-link>
          </li>
        </div>
      </ul>
    </nav>
    <img
            style="position:absolute;bottom:10px;right:0;z-index:1.5;width:52px;margin-right:10px"
            alt="Wine Compass"
            src="/img/logo-B-new.png"
          />
  </section>
</template>

<script>
import {mapGetters} from "vuex";
import axios from "axios";

export default {
  name: "Home",

  mounted: function () {
    this.$store.commit("headerShow");
    this.$store.commit("navHide");
    this.$store.commit("userShow");
    this.$store.commit("burgerShow");
    this.$store.commit("linkBackDisable");

    let app = document.querySelector("#app");

    app.className = "";
    app.classList.add("isHome");

    if (this.authenticated) {
      axios.get("/api/user").then(response => {
        this.$cookies.set('country', response.data.data.country)
      });
    }

    this.$gtm.trackEvent({
      event: 'Home',
      action: 'Pageview',
      label: 'Home',
      value: 'Home'
    })
  },
  computed: {
    ...mapGetters("auth", ["user", "authenticated"]),
  },
};
</script>
