export default {
  loading: false,

  headerHidden: false,
  footerHidden: true,
  navHidden: true,
  linkBackHidden: false,
  burgerHidden: false,
  userHidden: false,
  popupHidden: false,
  popupLocation: false,

  isMember: false,
  isRatedWine: false,
  isRated: false,

  isExplore: false,
  isDiscover: false,
  isEnjoy: false,
  isShop: false,

  availablePromos: true,
  availablePosts: true,

  lastRouter: ""
};
