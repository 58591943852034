<template>
  <section class="passport">
    <div class="passport__title">
      <i class="icon-icon-cellar"></i>
      <h1>{{ $t("your-cellar") }}</h1>
    </div>
    <ul v-if="passports.length !== 0">
      <li class="passport__row" v-for="passport in passports" :key="passport.id">
        <a @click="handleChoicePassport(passport)">
          <strong>{{ passport.wine.name }}, <span>{{ passport.wine.harvest }}</span></strong>
          <img src="@/assets/img/icon-arrow-right.svg" alt="right" />
        </a>
      </li>
    </ul>
    <nothing v-else />
  </section>
</template>

<script>
import {mapGetters} from "vuex";
import Nothing from "../../components/Nothing";

export default {
  name: "Passport",

  components: {
    Nothing
  },
  computed: {
    ...mapGetters("passport", ["passports"]),
  },
  methods: {
    handleChoicePassport(passport) {
      this.$store.commit('passport/SET_PASSPORT', passport)
      this.$router.push('/passport-details')
    },
  },
  mounted() {
    this.$store.dispatch('passport/GET_PASSPORTS');

    let app = document.querySelector("#app");

    app.className = "";
    app.classList.add("isCellar");

    this.$store.commit("headerShow");
    this.$store.commit("linkBackActive");
    this.$store.commit("navHide");
    this.$store.commit("userHide");
    this.$store.commit("burgerHide");
  }
};
</script>
