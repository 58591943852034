{
  "add-home": "Agregar acceso directo",
  "add-notes": "Tomá notas",
  "add-notes-descr": "Este es tu espacio personal para mantener un registro de los datos que te interesan acerca de tu viaje por el mundo del vino.",
  "add_homescreen_app": "Tocá y luego agregá a la pantalla de inicio",
  "age-location-text": "Tu recorrido por el Wine Compass de Trivento está por comenzar. Solamente necesitamos saber si tenés  la edad legal para beber y en qué parte del mundo estás.",
  "aroma": "Aroma",
  "aroma-rate-text": "¿Cuánto te gustó el aroma de este vino?",
  "become-member": "Convertite en miembro",
  "become-member-text": "¿Tenés ganas de aventurarte? Explorá otros rincones del Wine Compass",
  "birth-year": "Tu año de nacimiento",
  "birth-year-required": "Ingresá tu año de nacimiento para continuar",
  "bodega-trivento": "Bodega Trivento Argentina",
  "build-taste": "Construí el perfil de tu gusto",
  "caf-thank-you": "Tus puntuaciones nos permitirán identificar y mostrarte otros vinos de Trivento que te encantarán ❤",
  "choose-bottle": "Elegí la botella de Trivento que querés explorar",
  "choose-option": "Escogé una experiencia",
  "club": "EL CLUB",
  "colour": "Color",
  "colour-aroma": "Aroma, sabor y color",
  "colour-rate-text": "¿Cuánto te gustó el color de este vino?",
  "come-back": "Volvé más tarde",
  "confirm": "CONFIRMAR",
  "congrats": "Felicidades",
  "contact-us": "CONTACTANOS",
  "continue": "CONTINUAR",
  "country": "País de residencia",
  "country-required": "Seleccioná el país para continuar",
  "created-pass": "Creamos una bodega para vos, ahora podrás calificar el vino directamente en nuestro sitio web.",
  "did-not-receive": "Si no recibiste el correo electrónico",
  "discover": "DESCUBRIR",
  "edit-profile": "EDITÁ TU PERFIL",
  "email": "E-mailadres",
  "email-confirm": "Se cambió tu dirección de correo electrónico",
  "email-required": "Por favor, ingresá tu contraseña para continuar.",
  "enjoy": "DISFRUTÁ",
  "explore": "EXPLORAR",
  "explore-portfolio": "Explorá el portafolio",
  "file-greater": "El tamaño de tu archivo es superior a 2 MB",
  "flavour": "Sabor",
  "flavour-rate-text": "¿Cuánto te gustó el sabor de este vino?",
  "forgot-pass": "¿Olvidaste tu contraseña?",
  "fresh-verification-link": "Se envió un nuevo enlace de verificación a tu correo electrónico",
  "go-to-palate": "Ir a mi paladar",
  "go-to-passport": "Ir a mi bodega",
  "goes-well": "Maridaje",
  "harvest": "COSECHA",
  "hide-taste": "Ocultar mi gusto",
  "home": "INICIO",
  "i-accept": "Acepto",
  "id-be-happy": "¡Sin duda!",
  "journey-descr": "Cruz del Alto, Otoño 2016",
  "keep-updated": "Quiero estar al tanto de las novedades",
  "know-story": "Conocé la historia",
  "legal-drinking": "Tengo la edad mínima legal para consumir alcohol",
  "make-note": "Crear tus notas",
  "mendoza-argentina": "Mendoza, Argentina",
  "my-cellar": "Mi bodega",
  "my-taste": "Mi gusto",
  "name": "Nombre",
  "name-required": "Ingresá tu apellido para continuar",
  "new": "Nuevo",
  "news-for-you": "Noticias para vos",
  "no": "No",
  "nothing": "Aún no hay nada aquí",
  "ok": "Sí",
  "opinion-important": "Tu opinión es importante para nosotros.",
  "palate": "Tu paladar",
  "palate-empty": "Tu paladar está vacío",
  "password": "Contraseña",
  "password-required": "Por favor, ingresá tu contraseña para continuar.",
  "podcast": "EPISODIOS DEL PODCAST",
  "profile": "Tu perfil",
  "profile-update-confirm": "Se actualizó el perfil con éxito",
  "promotion": "PROMOCIONES",
  "rate-wine": "Ponele nota a tu vino",
  "request-again": "Solicitar otra vez",
  "request-password-reset": "Solicitar que se restablezca la contraseña",
  "resend-pass": "Reenviar las instrucciones de recuperación de la contraseña",
  "resend-pass-again": "Podés reenviar el restablecimiento de tu contraseña en {0} segundos.",
  "reset-pass": "Restablecer la contraseña",
  "reset-pass-email-sent": "Se envió el correo para restablecer la contraseña",
  "restore-conf-pass": "Confirmar nueva contraseña",
  "restore-create-pass": "Crear nueva contraseña",
  "restore-enter-email": "Ingresá el correo electrónico asociado con la cuenta para recuperar tu contraseña",
  "restore-new-pass": "Nueva contraseña",
  "restore-pass": "Restaurar contraseña",
  "restore-pass-reset": "Tu contraseña se restableció correctamente. Utilizá tu nueva contraseña al iniciar sesión",
  "restore-unic-pass": "La contraseña debe contener al menos 8 caracteres",
  "restore-we-have-sent": "Enviamos instrucciones para la recuperación de la contraseña a tu correo electrónico",
  "rights-reserved": "Todos los derechos reservados 2021",
  "same-as-password": "La contraseña de confirmación no coincide",
  "save-changes": "GUARDAR MiS CAMBIOS",
  "save-note": "Guardar mis notas",
  "save-notes": "Guardar mis anotaciones.",
  "select-country": "Seleccioná el país para continuar",
  "select-year-birth": "Seleccioná tu año de nacimiento",
  "shop": "COMPRAR",
  "show-taste": "Mostrar mi gusto",
  "sign-in": "Iniciar sesión",
  "sign-out": "Cerrar sesión",
  "sign-up": "Registrarse",
  "spare-minutes": "¿Tenés unos minutos para calificarnos en Vivino?",
  "subscribe": "Sí, quiero explorar más.",
  "subscribed-newsletter": "Suscrito al boletín informativo",
  "surname": "Apellido(s)",
  "surname-required": "Ingresá tu apellido para continuar",
  "taste-palate": "TU PALADAR",
  "terms-conditions": "los términos y condiciones",
  "thank-you": "¡Gracias!",
  "thank-you-member": "GRACIAS POR UNIRTE",
  "to-all-wines": "Volver a todos los vinos",
  "trivento-wines": "Vinos Trivento",
  "type-email": "Typ je e-mailadres",
  "type-name": "Escribí tu nombre",
  "type-surname": "Escribí tu apellido.",
  "variety": "CEPA",
  "vendors": "Dónde comprar",
  "visit-trivento": "Visitá Trivento.com",
  "visit-website": "VISITÁ NUESTRO SITIO WEB",
  "vivino-website": "Ir a Vivino",
  "welcome": "TE DAMOS LA BIENVENIDA",
  "wine": "VINO",
  "wine-added": "Este vino junto con tu calificación se agregaron a tu bodega",
  "wine-journal": "Mi diario de vinos",
  "write-notes": "Escribí tus anotaciones aquí.",
  "yes": "Sí",
  "your-cellar": "Tu bodega",
  "your-journey": "Tu recorrido",
  "your-rate": "Tu calificación para este vino",
  "accept-term": "Acepto los términos y condiciones",
  "no-promotions": "Enterate de las últimas promociones en la página web de los comercios.",

  "enable-geolocation": "Activa tu geolocalización",
  "geolocation-description": "Trivento Wine Compass quiere acceder a tu geolocalización para brindarte contenido personalizado. Para activarla, seguí los siguientes pasos:",
  "geolocation_step_1": "Entrá a la opción \"Configuración\" de tu celular.",
  "geolocation_step_2": "Elegí la opción \"Privacidad y seguridad\".",
  "geolocation_step_3": "Elegí la opción \"Ubicación\".",
  "geolocation_step_4": "Encontrá el navegador que estás usando y seleccioná la opción \"Al usar la app\"",
  "wines": "Vinos",
  "experiences": "Experiencias"
}
