<template>
  <div>
    <img
      class="stories__bg"
      v-lazy="wine.caf.aroma.image"
      :alt="$t('aroma')"
    />
    <div class="stories__info-holder" :class="'stories__info-holder--'+wine.caf.aroma.text_position">
      <div class="stories__info-inner"
        :style="{
          'background-color': wine.caf.aroma.text_bg_color,
          'color': wine.caf.aroma.text_color
        }"
      >
        <h1 class="text-align-left">{{ $t("aroma") }}</h1>
        <p class="text-align-left">{{ description }}</p>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters} from 'vuex';

export default {
  name: "Aroma",

  computed: {
    ...mapGetters("wine", ["wine"]),
    description: function () {
      return this.wine.caf.aroma.text[ this.$i18n.locale ]
    }
  }
};
</script>
