import {createStore} from "vuex";
import state from "./state.js";
import actions from "./actions.js";
import mutations from "./mutations.js";
import getters from "./getters.js";
// Plugins
import createCache from 'vuex-cache';
import createPersistedState from "vuex-persistedstate";
// Modules
import auth from "./modules/auth";
import club from "./modules/club";
import discover from "./modules/discover";
import enjoy from "./modules/enjoy";
import palate from "./modules/palate";
import passport from "./modules/passport";
import profile from "./modules/profile";
import promotion from "./modules/promotion";
import setting from "./modules/setting";
import shop from "./modules/shop";
import vendor from "./modules/vendor";
import wine from "./modules/wine";
import popup from "./modules/popup";

const persistedData = createPersistedState();
const cacheData = createCache();

export default createStore({
  state,
  getters,
  mutations,
  actions,
  modules: {
    auth,
    club,
    discover,
    enjoy,
    palate,
    passport,
    profile,
    promotion,
    setting,
    shop,
    vendor,
    wine,
    popup
  },
  plugins: [
    persistedData,
    cacheData
  ]
});
