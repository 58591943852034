export default {
  methods: {
    focusInput() {
      let inputArr = document.querySelectorAll(
        'input[type="text"], input[type="password"], input[type="email"]'
      );

      for (let i = 0; i < inputArr.length; i++) {
        inputArr[i].addEventListener("focus", function () {
          this.closest(".form__row").classList.add("form__row--focused");
        });
        inputArr[i].addEventListener("focusout", function () {
          this.closest(".form__row").classList.remove("form__row--focused");
        });
      }
    }
  }
};
