<template>
  <section class="discover">
    <div v-if="discovers.length !== 0">
      <div
        v-for="discover in discovers"
        :key="discover.id"
      >
        <a :href="discover.link" target="_blank" class="discover__item">
          <img v-lazy="discover.image" :alt="discover.name[ this.$i18n.locale ]" />
          <div class="discover__item-content"
            :class="'discover__item-content--'+discover.align_content"
            :style="{ 'color': discover.text_color }"
          >
            <h2>{{ discover.name[ this.$i18n.locale ] }}</h2>
            <p>{{ discover.description[ this.$i18n.locale ] }}</p>
          </div>
        </a>
      </div>
    </div>
    <nothing v-else />
  </section>
</template>

<script>
import {mapGetters} from 'vuex';
import Nothing from '../components/Nothing';

export default {
  name: "Discover",

  components: {
    Nothing
  },
  computed: {
    ...mapGetters("discover", ["discovers"]),
  },
  mounted() {
    const params = this.$route.query;
    if(Object.prototype.hasOwnProperty.call(params, 'discover')) {
      this.$store.dispatch('discover/GET_DISCOVER_PREVIEW', params.discover);
    } else {
      let countryOfUser = this.$cookies.get('country');
      this.$store.cache.dispatch('discover/GET_DISCOVERS', countryOfUser);

      this.$store.commit("navShow");
      this.$store.commit("linkBackDisable");
      this.$store.commit("burgerShow");
      this.$store.commit("userShow");

      let app = document.querySelector("#app");

      app.className = "";
      app.classList.add("isDiscover");
    }
  },
};
</script>
