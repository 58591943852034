<template>
  <div>
    <img
      class="stories__bg"
      src="@/assets/img/story-5.jpeg"
      :alt="$t('become-member')"
    />
    <div class="stories__info-holder">
      <h1>{{ $t("become-member") }}</h1>
      <p>{{ $t("become-member-text") }}</p>
      <router-link
        class="btn"
        @touchstart="beMember"
        to="/signUp"
      >{{ $t("subscribe") }}</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "Subscribe",

  methods: {
    beMember() {
      window.location.href = "/signUp";
    }
  }
};
</script>
