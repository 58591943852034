<template>
  <section class="journey">
    <div class="journey__icon-holder">
      <i class="icon-icon-pencil"></i>
    </div>
    <h1>{{ $t("your-journey") }}</h1>
    <div class="journey__item-holder">
      <router-link to="/palate" class="journey__item">
        <i class="icon-icon-wine"></i><p class="journey__item-title">{{ $t("palate") }}</p>
      </router-link>
      <router-link to="/passport" class="journey__item">
        <i class="icon-icon-cellar"></i><p class="journey__item-title">{{ $t("your-cellar") }}</p>
      </router-link>
    </div>
    <figure>
      <img src="@/assets/img/journey-1.jpeg" alt="" />
      <figcaption>{{ $t("journey-descr") }}</figcaption>
    </figure>
  </section>
</template>

<script>

export default {
  name: "Journey",

  mounted() {
    let app = document.querySelector("#app");

    app.className = "";
    app.classList.add("isJourney");

    this.$store.commit("headerShow");
    this.$store.commit("userHide");
    this.$store.commit("burgerHide");
    this.$store.commit("linkBackActive");
    this.$store.commit("navHide");
  }
};
</script>
