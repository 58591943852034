<template>
  <section v-if="wines.length" class="palate">
    <div class="palate__title">
      <i class="icon-icon-wine"></i>
      <h1>{{ $t("taste-palate") }}</h1>
    </div>
    <div class="swiper-holder">
      <span class="swiper-icon-left">
        <img src="@/assets/img/icon-arrow-left.svg" alt="left" />
      </span>
      <span class="swiper-icon-right">
        <img src="@/assets/img/icon-arrow-right-light.svg" alt="right" />
      </span>
      <swiper
        :slides-per-view="1"
        :space-between="30"
        :loop="true"
        :options="swiperOption"
      >
        <swiper-slide v-for="(wine, index) in user" :key="index">
          <RadarChart :chartData="chartData.user" class="chartRadar" />
          <div class="palate__row">
            <span>{{ $t("my-taste") }}</span>
            <span
              :style="{
                'background-color': color.userRgba,
              }"
            ></span>
          </div>
        </swiper-slide>
        <swiper-slide v-for="(wine, index) in wines" :key="index">
          <RadarChart
            v-if="showWines && data !== null"
            :chartData="chartData.wines.wines[index]"
            class="chartRadar"
          />
          <RadarChart
            v-if="!showWines && data !== null"
            :chartData="chartData.wines.winesNoUser[index]"
            class="chartRadar"
          />
          <div class="palate__row-holder">
            <div class="palate__row">
              <span>{{ wine.name }}</span>
              <span
                :style="{
                  'background-color': wine.color,
                }"
              ></span>
            </div>
            <div class="palate__row" v-if="showWines">
              <span>{{ $t("my-taste") }}</span>
              <span
                :style="{
                  'background-color': color.userRgba,
                }"
              ></span>
            </div>
          </div>
          <button class="btn" @click="toggle()" :disabled="!active">
            <transition name="fade" mode="out-in">
              <span v-if="showWines">
                {{ $t("hide-taste") }}
              </span>
              <span v-else>
                {{ $t("show-taste") }}
              </span>
            </transition>
          </button>
        </swiper-slide>
      </swiper>
    </div>

    <router-link v-if="!authenticated" to="/signUp" class="btn" style="margin-top: 20px;">{{ $t("sign-up") }}</router-link>
  </section>
  <section v-else class="palate palate--empty">
    <div class="palate__title">
      <i class="icon-icon-wine"></i>
      <h1>{{ $t("taste-palate") }}</h1>
    </div>
    <p>{{ $t("palate-empty") }}</p>
  </section>
</template>

<script>
import {Swiper, SwiperSlide} from "swiper/vue";
import RadarChart from "@/components/RadarChart.vue";
import {mapGetters} from "vuex";

export default {
  name: "Palate",

  components: {
    RadarChart,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      active: true,
      data: null,
      showWines: true,
      color: {
        user: [0, 255, 0],
        userRgba: "rgba(0, 255, 0, 0.4)",
        wines: [
          [255, 0, 0],
          [0, 102, 255],
          [0, 204, 102],
          [204, 204, 0],
          [255, 102, 102],
          [0, 51, 102],
        ],
      },
      wines: [],
      user: [],
      chartData: {
        wines: {
          labels: [],
          datasets: {},
          wines: [],
          winesNoUser: [],
        },
        user: {
          labels: [],
          datasets: {},
        },
      },
      swiperOption: {
        loop: true,
        navigation: {
          // nextEl: ".swiper-button-next",
          // prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  computed: {
    ...mapGetters("palate", ["palates"]),
    ...mapGetters("auth", ["authenticated"]),
    ...mapGetters("wine", ["wine"]),
  },
  methods: {
    toggle() {
      this.showWines = !this.showWines;
      this.active = !this.active;

      setTimeout(() => {
        this.active = !this.active;
      }, 1500);
    },
    buildPalate(response) {
      this.data = response.data.data;

      // ----- ----- START BUILD USER PALATE ----- -----
      let label = Object.keys(this.data.user_palate);
      this.chartData.user.labels = label;

      let palat = Object.values(this.data.user_palate);
      this.chartData.user.datasets = [
        {
          label: "User`s palate",
          data: palat,
          backgroundColor: "rgba(" + this.color.user.join(", ") + ", 0.4)",
          pointHoverBorderColor:
            "rgba(" + this.color.user.join(", ") + ", 1)",
          borderColor: "transparent",
          pointBackgroundColor: "transparent",
          pointBorderColor: "transparent",
          pointHoverBackgroundColor: "#fff",
          lineTension: 0.4,
        },
      ];

      this.user.push({
        name: "My taste",
        color: this.color.userRgba,
      });
      // ----- ----- END BUILD USER PALATE ----- -----

      // ----- ----- START BUILD WINES PALATE ----- -----
      let winesName = Object.keys(this.data.wine_palates);
      let winesPalate = Object.values(this.data.wine_palates);

      let winesDatasets = [];
      winesPalate.forEach((palate, index) => {
        this.wines.push({
          name: winesName[index],
          color: "rgba(" + this.color.wines[index].join(", ") + ")",
        });
        winesDatasets.push({
          label: winesName[index],
          data: Object.values(palate),
          backgroundColor:
            "rgba(" + this.color.wines[index].join(", ") + ", 0.4)",
          pointHoverBorderColor:
            "rgba(" + this.color.wines[index].join(", ") + ", 1)",
          borderColor: "transparent",
          pointBackgroundColor: "transparent",
          pointBorderColor: "transparent",
          pointHoverBackgroundColor: "#fff",
          lineTension: 0.4,
        });

        // --- --- SEPARATED DATASETS -WITH- USER`s DATASET
        let wine = { datasets: {}, labels: [] };
        let wineDataset = [];

        wineDataset.push(
          {
            label: winesName[index],
            data: Object.values(palate),
            backgroundColor:
              "rgba(" + this.color.wines[index].join(", ") + ", 0.4)",
            pointHoverBorderColor:
              "rgba(" + this.color.wines[index].join(", ") + ", 1)",
            borderColor: "transparent",
            pointBackgroundColor: "transparent",
            pointBorderColor: "transparent",
            pointHoverBackgroundColor: "#fff",
            lineTension: 0.4,
          },
          {
            label: "User`s palate",
            data: palat,
            backgroundColor: "rgba(" + this.color.user.join(", ") + ", 0.4)",
            pointHoverBorderColor:
              "rgba(" + this.color.user.join(", ") + ", 1)",
            borderColor: "transparent",
            pointBackgroundColor: "transparent",
            pointBorderColor: "transparent",
            pointHoverBackgroundColor: "#fff",
            lineTension: 0.4,
          }
        );

        wine.datasets = wineDataset;
        wine.labels = Object.keys(this.data.user_palate);
        this.chartData.wines.wines.push(wine);

        // --- --- SEPARATED DATASETS -WITHOUT- USER`s DATASET
        let wineNoUser = { datasets: {}, labels: [] };
        let wineDatasetNoUser = [];

        wineDatasetNoUser.push({
          label: winesName[index],
          data: Object.values(palate),
          backgroundColor:
            "rgba(" + this.color.wines[index].join(", ") + ", 0.4)",
          pointHoverBorderColor:
            "rgba(" + this.color.wines[index].join(", ") + ", 1)",
          borderColor: "transparent",
          pointBackgroundColor: "transparent",
          pointBorderColor: "transparent",
          pointHoverBackgroundColor: "#fff",
          lineTension: 0.4,
        });

        wineNoUser.datasets = wineDatasetNoUser;
        wineNoUser.labels = Object.keys(this.data.user_palate);
        this.chartData.wines.winesNoUser.push(wineNoUser);
      });

      this.chartData.wines.labels = label;
      this.chartData.wines.datasets = winesDatasets;
    }
  },
  mounted() {
    this.$store.commit("loading", true)
    if(!this.authenticated && this.wine && this.wine.rating.colour && this.wine.rating.aroma && this.wine.rating.flavour) {
      this.$store
        .dispatch("palate/SET_PALATE_TEMP", {
          wine_id: this.wine.id,
          colour: this.wine.rating.colour,
          aroma: this.wine.rating.aroma,
          flavour: this.wine.rating.flavour
        })
        .then((res) => {
          this.buildPalate(res)
          this.$store.commit("loading", false)
        })
        .catch((error) => {
          this.$store.commit("loading", false)
        });
    } else {
      this.$store
        .dispatch("palate/GET_PALATES")
        .then((response) => {
          this.buildPalate(response)
          this.$store.commit("loading", false)
        })
        .catch((error) => {
          this.$store.commit("loading", false)
        });
    }

    let app = document.querySelector("#app");

    app.className = "";
    app.classList.add("isPalate");

    this.$store.commit("headerShow");
    this.$store.commit("userHide");
    this.$store.commit("burgerHide");
    this.$store.commit("navHide");
  },
};
</script>
