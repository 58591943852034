<template>
  <ul class="socials">
    <li v-for="item in social" :key="item.id">
      <a
        :href="item.val"
        target="_blank"
      >
        <i :class="'icon-icon-'+item.key"></i>
      </a>
    </li>
  </ul>
</template>

<script>

import {mapGetters} from 'vuex';

export default {
  name: "Socials",

  computed: {
    ...mapGetters("setting", ["social"]),
  },
  mounted() {
    this.$store.cache.dispatch('setting/GET_SOCIAL');
  }
};
</script>
