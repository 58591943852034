{
  "add-home": "Verknüpfung auf dem Startbildschirm erstellen",
  "add-notes": "Fügen Sie eigene Notizen hinzu",
  "add-notes-descr": "Dies ist Ihr persönlicher Bereich, in dem Sie alle Ihre Gedanken zu Ihrer Weinreise protokollieren können.",
  "add_homescreen_app": "Tippen Sie auf und dann auf Zum Startbildschirm hinzufügen",
  "age-location-text": "Ihre Reise im Weinkompass von Trivento beginnt. Wir müssen nur wissen, ob Sie alt genug sind, um legal Alkohol in dem Land zu trinken, in dem Sie sich befinden.",
  "aroma": "Bouquet",
  "aroma-rate-text": "Wie sehr hat Ihnen das Aroma dieses Weines gefallen?",
  "become-member": "Werden Sie Mitglied",
  "become-member-text": "Haben Sie Lust auf ein Abenteuer? Entdecken Sie weitere Inhalte des Weinkompasses",
  "birth-year": "Ihr Geburtsjahr",
  "birth-year-required": "Bitte geben Sie Ihr Geburtsjahr ein.",
  "bodega-trivento": "Bodega Trivento Argentinien",
  "build-taste": "Verfeinern Sie Ihr Geschmacksprofil",
  "caf-thank-you": "Ihr Feedback ist uns wichtig. Können Sie sich ein paar Minuten Zeit nehmen, um uns auf Vivino zu bewerten?",
  "choose-bottle": "Wählen Sie die Flasche von Trivento, über die Sie mehr erfahren möchten.",
  "choose-option": "Was möchten Sie tun?",
  "club": "DER CLUB",
  "colour": "Farbe",
  "colour-aroma": "Farbe, Bouquet & Geschmack",
  "colour-rate-text": "Wie sehr hat Ihnen die Farbe dieses Weines gefallen?",
  "come-back": "Kommen Sie später wieder.",
  "confirm": "BESTÄTIGEN",
  "congrats": "Herzlichen Glückwunsch!",
  "contact-us": "KONTAKTIEREN SIE UNS",
  "continue": "WEITER",
  "country": "Land des Wohnsitzes",
  "country-required": "Bitte wählen Sie ein Land aus",
  "created-pass": "Wir haben für Sie einen Weinkeller angelegt, jetzt können Sie den Wein direkt auf unserer Website bewerten.",
  "did-not-receive": "Falls Sie die E-Mail nicht erhalten haben",
  "discover": "ERFORSCHEN",
  "edit-profile": "PROFIL BEARBEITEN",
  "email": "E-Mail-Adresse",
  "email-confirm": "Ihre E-Mail-Adresse wurde geändert.",
  "email-required": "Bitte geben Sie Ihre E-Mail-Adresse ein.",
  "enjoy": "GENIESSEN",
  "explore": "ENTDECKEN",
  "explore-portfolio": "Entdecken Sie das Portfolio",
  "file-greater": "Ihre Dateigröße ist größer als 2 MB.",
  "flavour": "Geschmack",
  "flavour-rate-text": "Wie sehr hat Ihnen der Geschmack dieses Weines gefallen?",
  "forgot-pass": "Passwort vergessen?",
  "fresh-verification-link": "Ein neuer Bestätigungslink wurde an Ihre E-Mail-Adresse geschickt.",
  "go-to-palate": "Zu meinem Gaumen",
  "go-to-passport": "Zu meinem Keller",
  "goes-well": "Was passt dazu?",
  "harvest": "ERNTE",
  "hide-taste": "Mein Geschmacksprofil verbergen",
  "home": "STARTSEITE",
  "i-accept": "Ich akzeptiere",
  "id-be-happy": "Natürlich gerne!",
  "journey-descr": "Cruz del Alto, Herbst 2016",
  "keep-updated": "Informieren Sie mich bitte über Neuigkeiten.",
  "know-story": "Alles über Ihren Wein",
  "legal-drinking": "Ich erfülle das Mindestalter für legalen Alkoholkonsum.",
  "make-note": "Fügen Sie Ihre Notizen hinzu",
  "mendoza-argentina": "Mendoza, Argentinien",
  "my-cellar": "Mein Keller",
  "my-taste": "Mein Geschmacksprofil",
  "name": "Name",
  "name-required": "Bitte geben Sie Ihren Namen ein.",
  "new": "Neu",
  "news-for-you": "Neuigkeiten für Sie",
  "no": "Nein",
  "nothing": "Noch ist hier nichts.",
  "ok": "OK",
  "opinion-important": "Ihre Meinung ist uns wichtig!",
  "palate": "Ihr Geschmack",
  "palate-empty": "Ihr Gaumen ist leer",
  "password": "Passwort",
  "password-required": "Bitte geben Sie Ihr Passwort ein.",
  "podcast": "PODCAST-FOLGEN",
  "profile": "Ihr Profil",
  "profile-update-confirm": "Profil erfolgreich aktualisiert",
  "promotion": "SONDERAKTIONEN",
  "rate-wine": "Bewerten Sie Ihren Wein",
  "request-again": "Neu anfordern",
  "request-password-reset": "Passwort zurücksetzen lassen",
  "resend-pass": "Anweisungen zur Passwortwiederherstellung erneut senden",
  "resend-pass-again": "Sie können in {0} Sekunden erneut versuchen, das Passwort zurückzusetzen.",
  "reset-pass": "Passwort zurücksetzen",
  "reset-pass-email-sent": "E-Mail zum Zurücksetzen des Passworts wurde gesendet.",
  "restore-conf-pass": "Neues Passwort bestätigen",
  "restore-create-pass": "Neues Passwort erstellen",
  "restore-enter-email": "Geben Sie die mit dem Konto verknüpfte E-Mail-Adresse ein, um Ihr Passwort wiederherzustellen.",
  "restore-new-pass": "Neues Passwort",
  "restore-pass": "Passwort wiederherstellen",
  "restore-pass-reset": "Ihr Passwort wurde erfolgreich zurückgesetzt. Bitte verwenden Sie bei der Anmeldung Ihr neues Passwort.",
  "restore-unic-pass": "Das Passwort muss mindestens 8 Zeichen lang sein.",
  "restore-we-have-sent": "Wir haben Anweisungen zur Passwortwiederherstellung an Ihre E-Mail-Adresse gesendet.",
  "rights-reserved": "Alle Rechte vorbehalten 2021",
  "same-as-password": "Bestätigungspasswort stimmt nicht überein",
  "save-changes": "MEINE ÄNDERUNGEN SPEICHERN",
  "save-note": "Notiz speichern",
  "save-notes": "Meine Notizen speichern",
  "select-country": "Bitte wählen Sie ein Land aus",
  "select-year-birth": "Geburtsjahr auswählen",
  "shop": "KAUFEN",
  "show-taste": "Mein Geschmacksprofil anzeigen",
  "sign-in": "Anmelden",
  "sign-out": "Abmelden",
  "sign-up": "Registrieren",
  "spare-minutes": "Würden Sie sich ein paar Minuten Zeit nehmen, um uns auf Vivino zu bewerten?",
  "subscribe": "Ja, ich möchte mehr entdecken.",
  "subscribed-newsletter": "Newsletter abonniert",
  "surname": "Nachname",
  "surname-required": "Bitte geben Sie Ihren Nachnamen ein.",
  "taste-palate": "IHR GESCHMACK",
  "terms-conditions": "die Geschäftsbedingungen",
  "thank-you": "Vielen Dank!",
  "thank-you-member": "VIELEN DANK, DASS SIE MITGLIED WERDEN!",
  "to-all-wines": "Zurück zu allen Weinen",
  "trivento-wines": "Trivento-Weine",
  "type-email": "E-Mail-Adresse eingeben",
  "type-name": "Vornamen eingeben",
  "type-surname": "Nachnamen eingeben",
  "variety": "REBSORTE",
  "vendors": "Verkäufer",
  "visit-trivento": "Besuchen Sie Trivento.com",
  "visit-website": "BESUCHEN SIE UNSERE WEBSITE",
  "vivino-website": "Zu Vivino",
  "welcome": "HERZLICH WILLKOMMEN",
  "wine": "WEIN",
  "wine-added": "Dieser Wein wurde zusammen mit Ihrer Bewertung zu Ihrem Reisepass hinzugefügt.",
  "wine-journal": "Mein Weintagebuch",
  "write-notes": "Schreiben Sie hier Ihre Notizen nieder.",
  "yes": "Ja",
  "your-cellar": "Ihr Keller",
  "your-journey": "Ihre Reise",
  "your-rate": "Ihre Bewertung für diesen Wein",
  "accept-term": "Ich akzeptiere die Geschäftsbedingungen.",
  "no-promotions": "Informieren Sie sich auf den Websites der Einzelhändler über die neuesten Sonderangebote.",

  "enable-geolocation": "Geolokalisierung aktivieren",
  "geolocation-description": "Trivento Wine Compass möchte auf deinen Standort zugreifen, um personalisierte Inhalte bereitzustellen. Um dies zu aktivieren, befolge diese Schritte:",
  "geolocation_step_1": "Gehe zur \"Einstellungen\" Option auf deinem Telefon.",
  "geolocation_step_2": "Wähle die Option \"Datenschutz und Sicherheit\".",
  "geolocation_step_3": "Wähle die Option \"Standort\".",
  "geolocation_step_4": "Finde den Browser, den du verwendest, und wähle die Option \"Während der App-Nutzung\".",
  "wines": "Weine",
  "experiences": "Erfahrungen"
}
