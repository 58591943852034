import axios from "axios";

export default {
  namespaced: true,

  state: {
    popup: null,
    popup_other: null,
    popupHidden: true,
  },

  mutations: {
    SET_POPUP(state, popup) {
      state.popup = popup;
    },
    GET_POPUP_OTHERS(state, popup) {
      state.popup_other = popup;
    },
    popupHidden(state, bool) {
      state.popupHidden = bool;
    },
  },

  actions: {
    GET_POPUP: async ({commit}) => {
      commit("loading", true, {root: true});
      await axios
        .get('/api/popup')
        .then(response => {
          commit("SET_POPUP", response.data.data);
          commit("loading", false, {root: true});
        })
        .catch(error => {
          commit("SET_POPUP", null);
          commit("loading", false, {root: true});
        });
    },
    GET_POPUP_OTHERS: async ({commit}, params) => {
      commit("loading", true, {root: true});
      try{
        const {data: data} = await axios.post(`/api/popup-others`, params)
        commit("GET_POPUP_OTHERS", data.data);
        commit("loading", false, {root: true});
        return Promise.resolve(data)
      }
      catch (e) {
        commit("GET_POPUP_OTHERS", null)
        commit("loading", false, {root: true})
        return Promise.reject(e)
      }
    },
  }
};
