import axios from 'axios'

export default {
  namespaced: true,

  state: {
    passports: [],
    passport: null,
    error: null,
  },

  getters: {
    passports: (state) => {
      return state.passports
    },
    passport: (state) => {
      return state.passport
    },
    error: (state) => {
      return state.error;
    },
  },

  mutations: {
    SET_PASSPORTS(state, passports) {
      state.passports = passports
    },
    SET_PASSPORT(state, passport) {
      state.passport = passport
    },
    SET_ERROR(state, error) {
      state.error = error;
    },
  },

  actions: {
    GET_PASSPORTS: async ({commit}) => {
      commit('loading', true, {root: true})

      await axios.get('/api/passports')
        .then((response) => {
          commit('SET_PASSPORTS', response.data.data)
          commit('loading', false, {root: true})
        })
        .catch((error) => {
          commit('SET_PASSPORTS', [])
          commit('loading', false, {root: true})
        })
    },
    UPDATE_PASSPORT: async ({commit}, passport) => {
      await axios.get('/sanctum/csrf-cookie')
      return axios.post(`/api/wines/${passport.wine.id}/passport`, passport)
    }
  }
}
