{
  "add-home": "Toevoegen aan startscherm",
  "add-notes": "Aantekeningen maken",
  "add-notes-descr": "Hier kun je jouw reiservaring in de wereld van wijn bijhouden, gewoon voor jezelf.",
  "add_homescreen_app": "Tikken en dan toevoegen aan startscherm",
  "age-location-text": "Je reis in het wijnkompas van Trivento gaat zo van start. We moeten alleen even nagaan of je oud genoeg bent om te drinken en waar je woont.",
  "aroma": "Aroma",
  "aroma-rate-text": "Hoe aangenaam vond je het aroma van deze wijn?",
  "become-member": "Lid worden",
  "become-member-text": "Zin in avontuur? Verken nog meer richtingen op het wijnkompas.",
  "birth-year": "Jouw geboortejaar",
  "birth-year-required": "Voer je geboortejaar in om door te gaan",
  "bodega-trivento": "Bodega Trivento Argentina",
  "build-taste": "Stel je smaakprofiel samen.",
  "caf-thank-you": "Jouw mening is belangrijk voor ons. Heb je een paar minuten de tijd om ons een beoordeling te geven op Vivino?",
  "choose-bottle": "Kies de fles Trivento die je verder wilt verkennen",
  "choose-option": "Kies een ervaring",
  "club": "DE CLUB",
  "colour": "Kleur",
  "colour-aroma": "Kleur, aroma en smaak",
  "colour-rate-text": "Hoe mooi vond je de kleur van deze wijn?",
  "come-back": "Kom later terug",
  "confirm": "Bevestigen",
  "congrats": "Gefeliciteerd",
  "contact-us": "CONTACT OPNEMEN",
  "continue": "Doorgaan",
  "country": "Land van verblijf",
  "country-required": "Selecteer een land om door te gaan",
  "created-pass": "We hebben een wijnkelder voor je gemaakt. Je kunt de wijn nu direct op onze website beoordelen",
  "did-not-receive": "Als je de e-mail niet hebt ontvangen",
  "discover": "ONTDEKKEN",
  "edit-profile": "PROFIEL BEWERKEN",
  "email": "E-mailadres",
  "email-confirm": "Je e-mailadres is gewijzigd",
  "email-required": "Voer je e-mailadres in om door te gaan",
  "enjoy": "GENIETEN",
  "explore": "VERKENNEN",
  "explore-portfolio": "Verken het aanbod",
  "file-greater": "Je bestand is groter dan 2 MB",
  "flavour": "Smaak",
  "flavour-rate-text": "Hoe aangenaam vond je de smaak van deze wijn?",
  "forgot-pass": "Wachtwoord vergeten?",
  "fresh-verification-link": "Er is een nieuwe verificatielink naar je e-mailadres gestuurd",
  "go-to-palate": "Ga naar mijn smaakpalet",
  "go-to-passport": "Naar mijn wijnkelder",
  "goes-well": "Past goed bij",
  "harvest": "OOGSTJAAR",
  "hide-taste": "Mijn smaakprofiel verbergen",
  "home": "Home",
  "i-accept": "Ik ga akkoord",
  "id-be-happy": "Ja, met plezier!",
  "journey-descr": "Cruz del Alto, najaar 2016",
  "keep-updated": "Ik wil updates ontvangen",
  "know-story": "Lees het verhaal",
  "legal-drinking": "Ik heb de wettelijke alcoholleeftijd bereikt",
  "make-note": "Aantekeningen nemen",
  "mendoza-argentina": "Mendoza, Argentinië",
  "my-cellar": "Mijn wijnkelder",
  "my-taste": "Mijn smaakprofiel",
  "name": "Voornaam",
  "name-required": "Voer je naam in om door te gaan",
  "new": "Nieuw",
  "news-for-you": "Nieuws voor jou",
  "no": "Nee",
  "nothing": "Er is nog niets te zien",
  "ok": "OK",
  "opinion-important": "Jouw mening is belangrijk voor ons.",
  "palate": "Jouw smaakprofiel",
  "palate-empty": "Je smaakpalet is leeg",
  "password": "Wachtwoord",
  "password-required": "Voer je wachtwoord in om door te gaan",
  "podcast": "PODCASTAFLEVERINGEN",
  "profile": "Jouw profiel",
  "profile-update-confirm": "Profiel bijgewerkt",
  "promotion": "PROMOTIES",
  "rate-wine": "Beoordeel je wijn",
  "request-again": "Opnieuw aanvragen",
  "request-password-reset": "Wachtwoord resetten",
  "resend-pass": "Instructies voor wachtwoordherstel opnieuw sturen",
  "resend-pass-again": "Je kunt de e-mail voor wachtwoordherstel over {0} seconden opnieuw sturen",
  "reset-pass": "Wachtwoord resetten",
  "reset-pass-email-sent": "E-mail voor wachtwoordherstel gestuurd",
  "restore-conf-pass": "Nieuw wachtwoord bevestigen",
  "restore-create-pass": "Nieuw wachtwoord aanmaken",
  "restore-enter-email": "Voer het e-mailadres is dat bij het account hoort om je wachtwoord te herstellen",
  "restore-new-pass": "Nieuw wachtwoord",
  "restore-pass": "Wachtwoord herstellen",
  "restore-pass-reset": "Je wachtwoord is gereset. Gebruik je nieuwe wachtwoord om in te loggen",
  "restore-unic-pass": "Wachtwoord moet minimaal 8 tekens bevatten",
  "restore-we-have-sent": "We hebben instructies voor wachtwoordherstel naar je e-mailadres gestuurd",
  "rights-reserved": "Alle rechten voorbehouden 2021",
  "same-as-password": "Wachtwoordbevestiging komt niet overeen",
  "save-changes": "WIJZIGINGEN OPSLAAN",
  "save-note": "Aantekening opslaan",
  "save-notes": "Aantekeningen opslaan",
  "select-country": "Selecteer een land om door te gaan",
  "select-year-birth": "Selecteer je geboortejaar",
  "shop": "WINKELEN",
  "show-taste": "Mijn smaakprofiel weergeven",
  "sign-in": "Inloggen",
  "sign-out": "Afmelden",
  "sign-up": "Aanmelden",
  "spare-minutes": "Heb je een paar minuten de tijd om ons een beoordeling te geven op Vivino?",
  "subscribe": "Ja, ik wil meer verkennen.",
  "subscribed-newsletter": "Inschrijven voor nieuwsbrief",
  "surname": "Achternaam",
  "surname-required": "Voer je achternaam in om door te gaan",
  "taste-palate": "JOUW SMAAKPROFIEL",
  "terms-conditions": "met de Algemene voorwaarden",
  "thank-you": "Bedankt!",
  "thank-you-member": "BEDANKT DAT JE LID BENT GEWORDEN",
  "to-all-wines": "Terug naar alle wijnen",
  "trivento-wines": "Trivento-wijnen",
  "type-email": "Typ je e-mailadres",
  "type-name": "Typ je voornaam",
  "type-surname": "Typ je achternaam",
  "variety": "variety",
  "vendors": "Verkopers",
  "visit-trivento": "Bezoek Trivento.com",
  "visit-website": "BEZOEK ONZE WEBSITE",
  "vivino-website": "Naar Vivino",
  "welcome": "WELKOM",
  "wine": "WIJN",
  "wine-added": "Deze wijn is samen met je beoordeling aan je paspoort toegevoegd",
  "wine-journal": "Mijn wijndagboek",
  "write-notes": "Typ hier je aantekeningen.",
  "yes": "Ja",
  "your-cellar": "Jouw wijnkelder",
  "your-journey": "Jouw avontuur",
  "your-rate": "Jouw beoordeling van deze wijn",
  "accept-term": "Ik ga akkoord met de Algemene voorwaarden",
  "no-promotions": "Ontdek de meest recente promoties op de website van de winkels.",

  "enable-geolocation": "Activeer geolocatie",
  "geolocation-description": "Trivento Wine Compass wil toegang tot je locatie om gepersonaliseerde inhoud te kunnen bieden. Volg deze stappen om het in te schakelen:",
  "geolocation_step_1": "Ga naar de \"Instellingen\" optie op je telefoon.",
  "geolocation_step_2": "Selecteer de \"Privacy en beveiliging\" optie.",
  "geolocation_step_3": "Kies de \"Locatie\" optie.",
  "geolocation_step_4": "Zoek de browser die je gebruikt en selecteer de optie \"Terwijl de app wordt gebruikt\".",
  "wines": "Wijnen",
  "experiences": "Ervaringen"
}
