<template>
  <section class="popup" v-if="popupLocation">
    <div class="container_popup popup_location">
      <div
          class="header__menu-close"
          @click="close"
          @touchstart="close"
        >
        <span></span>
        <span></span>
      </div>
      <div class="container_popup_content">
        <div class="text-center">
          <img src="@/assets/img/location.png" class="icono" alt="location">
        </div>
        <h1 class="popup_title">{{ $t("enable-geolocation") }}</h1>
        <br>
        <p>{{ $t("geolocation-description") }}</p>
        <div>
          <ol class="text-left">
            <li>{{ $t("geolocation_step_1") }}</li>
            <li>{{ $t("geolocation_step_2") }}</li>
            <li>{{ $t("geolocation_step_3") }}</li>
            <li>{{ $t("geolocation_step_4") }}</li>
          </ol>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import store from "@/store";
export default {
  name: "Popup",
  /*data() {
    return {
      popupHidden: this.$store.state.popup.popupHidden
    };
  },*/
  computed: {
    ...mapState(["popupLocation"]),
  },
  methods: {
    ...mapMutations(['popupLocationShow']),
    close() {
      this.popupLocationShow(false)
      this.setCookie("popupLocationShow", "false", "7d");
    },
    setCookie(name, val, expire) {
      this.$cookies.set(name, val, expire);
    },
  }
};
</script>
