{
  "***": "*********",
  "add-home": "Add desktop shortcut",
  "add-notes": "Add your notes",
  "add-notes-descr": "This is your personal space to keep a log of all your thoughts about your wine journey.",
  "add_homescreen_app": "Tap and then Add to homescreen",
  "age-location-text": "Your journey in Trivento's wine compass is about to begin. We just need to know if you're old enought to legally drink and where you are located.",
  "aroma": "Aroma",
  "aroma-rate-text": "How much did you like the aroma of this wine?",
  "become-member": "Become a member",
  "become-member-text": "Feeling adventurous? Explore more corners of the Wine Compass",
  "birth-year": "Your year birth",
  "birth-year-required": "Please enter your birth year to continue",
  "bodega-trivento": "Bodega Trivento Argentina",
  "build-taste": "Build your taste profile.",
  "caf-thank-you": "Your feedback about this wine will help us  identify and show you other Trivento wines that you will love❤",
  "choose-bottle": "Choose the bottle of Trivento you want to explore",
  "choose-option": "Choose an experience",
  "club": "The club",
  "colour": "Colour",
  "colour-aroma": "Colour, aroma & flavour",
  "colour-rate-text": "How much did you like the colour of this wine?",
  "come-back": "Come back later.",
  "confirm": "Confirm",
  "congrats": "Congrats",
  "contact-us": "Contact us",
  "continue": "Continue",
  "country": "Country of Residence",
  "country-required": "Please select country to continue",
  "created-pass": "We have created a wine passport for you, now you will be able to rate the wine directly on our website",
  "did-not-receive": "If you did not receive the email",
  "discover": "Discover",
  "edit-profile": "Edit your profile",
  "email": "Email",
  "email-confirm": "Your email address was changed",
  "email-required": "Please enter your email to continue",
  "enjoy": "Enjoy",
  "explore": "Explore",
  "explore-portfolio": "Explore the portfolio",
  "file-greater": "Your file size is greater than 2MB",
  "flavour": "Flavour",
  "flavour-rate-text": "How much did you like the flavour of this wine?",
  "forgot-pass": "Forgot your password?",
  "fresh-verification-link": "A fresh verification link has been sent to your email address.",
  "go-to-palate": "Go To my Palate",
  "go-to-passport": "Go To my Cellar",
  "goes-well": "Goes well with",
  "harvest": "Harvest",
  "hide-taste": "Hide my taste",
  "home": "Home",
  "i-accept": "I accept",
  "id-be-happy": "Sure, I’d be happy to!",
  "journey-descr": "Cruz del Alto, Autum 2016",
  "keep-updated": "Keep me updated",
  "know-story": "Know the story",
  "legal-drinking": "I am of legal drinking age",
  "make-note": "Make your notes",
  "mendoza-argentina": "Mendoza - Argentina",
  "my-cellar": "My Cellar",
  "my-taste": "My taste",
  "name": "Name",
  "name-required": "Please enter your name to continue",
  "new": "New",
  "news-for-you": "News for you",
  "no": "No",
  "nothing": "There is nothing here yet.",
  "ok": "Ok",
  "opinion-important": "Your opinion is important.",
  "palate": "Your Palate",
  "palate-empty": "Your palate is empty",
  "password": "Password",
  "password-required": "Please enter your password to continue",
  "podcast": "Podcast episodes",
  "profile": "Your Profile",
  "profile-update-confirm": "Profile updated successfully",
  "promotion": "Promotions",
  "rate-wine": "Rate your wine",
  "request-again": "Request again",
  "request-password-reset": "Request Password Reset",
  "resend-pass": "Resend password recovery instructions",
  "resend-pass-again": "You can resend the password reset again in {0} seconds.",
  "reset-pass": "Reset Password",
  "reset-pass-email-sent": "Reset password email sent.",
  "restore-conf-pass": "Confirm new password",
  "restore-create-pass": "Create new password",
  "restore-enter-email": "Enter the email associated with the account to recover your password",
  "restore-new-pass": "New password",
  "restore-pass": "Restore Password",
  "restore-pass-reset": "Your password has been reset successfully. Please use your new password when signing in",
  "restore-unic-pass": "Password must contain at least 8 characters",
  "restore-we-have-sent": "We have sent password recovery instructions to your email address",
  "rights-reserved": "All Rights Reserved",
  "same-as-password": "Confirmation password does not match",
  "save-changes": "Save my changes",
  "save-note": "Save your note",
  "save-notes": "Save Notes",
  "select-country": "Please enter select country to continue",
  "select-year-birth": "Select your year of birth",
  "shop": "Shop",
  "show-taste": "Show my taste",
  "sign-in": "Sign In",
  "sign-out": "Sign Out",
  "sign-up": "Sign Up",
  "spare-minutes": "Can you spare a few minutes to rate us on Vivino?",
  "subscribe": "Yes, I want to explore more.",
  "subscribed-newsletter": "Subscribed to newsletter",
  "surname": "Last name",
  "surname-required": "Please enter your surname to continue",
  "taste-palate": "Your palate",
  "terms-conditions": "Terms & Conditions",
  "thank-you": "Thank you!",
  "thank-you-member": "THANK YOU FOR BECOMING A MEMBER",
  "to-all-wines": "Back to all wines",
  "trivento-wines": "Trivento Wines",
  "type-email": "Type your email",
  "type-name": "Type your name",
  "type-surname": "Type your surname",
  "variety": "Variety",
  "vendors": "Vendors",
  "visit-trivento": "Visit Trivento.com",
  "visit-website": "Visit our website",
  "vivino-website": "Go to Vivino",
  "welcome": "Welcome",
  "wine": "Wine",
  "wine-added": "This wine along with your rating was added to your passport",
  "wine-journal": "My Wine Journal",
  "write-notes": "Write your notes here.",
  "yes": "Yes",
  "your-cellar": "Your Cellar",
  "your-journey": "Your Journey",
  "your-rate": "Your rate for this wine",
  "please-fields": "Please fill all fields to continue",
  "you-not-18": "Sorry, you are not 18 years old",
  "accept-term": "Please, accept Terms & Conditions",
  "no-promotions": "Check for the latest promotions on the VENDORS website.",

  "enable-geolocation": "Enable geolocation",
  "geolocation-description": "Trivento Wine Compass wants to access your location to provide personalized content. To enable it, follow these steps:",
  "geolocation_step_1": "Go to the \"Settings\" option on your phone.",
  "geolocation_step_2": "Select the \"Privacy and Security\" option.",
  "geolocation_step_3": "Choose the \"Location\" option.",
  "geolocation_step_4": "Find the browser you are using and select the \"While using the app\" option.",
  "wines": "Wines",
  "experiences": "Experiences"
}
