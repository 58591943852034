import axios from 'axios'

export default {
  namespaced: true,

  state: {
    enjoys: [],
    error: null,
  },

  getters: {
    enjoys: (state) => {
      return state.enjoys
    },
    error: (state) => {
      return state.error;
    },
  },

  mutations: {
    SET_ENJOYS(state, enjoys) {
      state.enjoys = enjoys
    },
    SET_ERROR(state, error) {
      state.error = error;
    },
    ADD_TO_ENJOYS(state, enjoy) {
      state.enjoys.push(enjoy)
    }
  },

  actions: {
    GET_ENJOYS: async ({commit}, country) => {
      commit('loading', true, {root: true})

      await axios.get(`/api/enjoys/${country}`)
        .then((response) => {
          commit('SET_ENJOYS', response.data.data)
          commit('loading', false, {root: true})
        }).catch((error) => {
          commit('SET_ENJOYS', [])
          commit('loading', false, {root: true})
        })
    },
    GET_ENJOY_PREVIEW: async ({commit}, id) => {
      commit('loading', true, {root: true})

      await axios.get(`/api/enjoys/preview/${id}`)
        .then((response) => {
          commit('SET_ENJOYS', [])
          commit('ADD_TO_ENJOYS', response.data.data)
          commit('loading', false, {root: true})
        }).catch((error) => {
          commit('SET_ENJOYS', [])
          commit('loading', false, {root: true})
        })
    },
  }
}
