<template>
  <section class="explore">
    <h1>{{ $t("welcome") }}</h1>
    <div v-if="wines.length !== 0">
      <p>{{ $t("choose-bottle") }}</p>

      <div class="explore__swiper-holder">
        <swiper
          :slides-per-view="3"
          :space-between="0"
          :loop="true"
          :options="swiperOption"
          navigation
        >
          <swiper-slide v-for="wine in wines" :key="wine.id">
            <a @click="handleChoiceWine(wine)">
              <img v-lazy="wine.image" :alt="wine.name" />
            </a>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <nothing v-else />
  </section>
</template>

<script>
import SwiperCore, {Navigation} from "swiper";
import {Swiper, SwiperSlide} from "swiper/vue";
import {mapGetters} from 'vuex';
import Nothing from '../components/Nothing';

SwiperCore.use([Navigation]);

export default {
  name: "Explore",

  components: {
    Nothing,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOption: {
        loop: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  computed: {
    ...mapGetters("wine", ["wines"]),
  },
  methods: {
    handleChoiceWine(wine) {
      this.$store.commit('wine/SET_WINE', wine);
      this.$router.push(`/explore/${wine.slug}`);
    },
    prev() {
      this.$refs.swiper.$swiper.slidePrev();
    },
    next() {
      this.$refs.swiper.$swiper.slideNext();
    },
  },
  mounted() {
    let countryOfUser = this.$cookies.get('country');
    this.$store.dispatch('wine/GET_WINES_COUNTRY', countryOfUser);

    this.$store.commit("headerShow");
    this.$store.commit("navShow");
    this.$store.commit("linkBackDisable");
    this.$store.commit("userShow");
    this.$store.commit("burgerShow");

    let app = document.querySelector("#app");
    app.className = "";
    app.classList.add("isExplore");
  },
};
</script>
