<template>
  <section class="promo">
    <!-- <router-link class="link-back" to="/news">
      <img src="@/assets/img/icon-arrow-left-white.svg" alt="back" />
    </router-link>
    <div class="justify-center">
      <router-link to="/">
        <LogoPrimary />
      </router-link>
    </div> -->
    <h1>{{ $t("club") }}</h1>
    <div v-if="clubs.length">
      <div v-for="club in clubs" :key="club.id">
        <a class="promo__item promo__item--new" :href="club.link" target="_blank">
          <img v-lazy="club.image" :alt="club.title[this.$i18n.locale]"/>
          <span>{{ $t("new") }}</span>
          <h2>{{ club.title[this.$i18n.locale] }}</h2>
          <p>
            {{ club.description[this.$i18n.locale] }}
          </p>
        </a>
      </div>
    </div>
    <nothing v-else/>
  </section>
</template>

<script>
import Nothing from '@/components/Nothing';
import {mapGetters} from "vuex";

export default {
  name: "Promotion",

  components: {
    Nothing
  },
  computed: {
    ...mapGetters("club", ["clubs"]),
  },
  mounted() {
    this.$store.cache.dispatch('club/GET_CLUBS');

    this.$store.commit("navHide");
    this.$store.commit("headerShow");

    let app = document.querySelector("#app");

    app.className = "";
    app.classList.add("isPromotion");
  },
};
</script>
