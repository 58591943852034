<template>
  <section class="promo club">
    <h1>{{ $t("promotion") }}</h1>
    <div v-if="localPromotions.length !== 0">
      <div v-for="promo in localPromotions" :key="promo.id">
        <a class="promo__item promo__item--new"
           :href="promo.link"
           target="_blank"
        >
          <img v-lazy="promo.image" :alt="promo.title[this.$i18n.locale]"/>
          <span v-if="promo.newest">{{ $t("new") }}</span>
          <h2>{{ promo.title[this.$i18n.locale] }}</h2>
          <p>{{ promo.description[this.$i18n.locale] }}</p>
        </a>
      </div>
    </div>
    <nothing v-else/>
  </section>
</template>

<script>
import {mapGetters} from 'vuex';
import Nothing from '../../components/Nothing';

export default {
  name: "Promotion",
  components: {
    Nothing
  },
  computed: {
    ...mapGetters("promotion", ["localPromotions"]),
  },
  mounted() {
    this.$store.cache.dispatch('promotion/GET_LOCAL_PROMOTIONS');

    let app = document.querySelector("#app");

    app.className = "";
    app.classList.add("isPromotion");
  }
};
</script>
