<template>
  <section class="news">
    <div class="news__icon-holder">
      <i class="icon-icon-artricle"></i>
    </div>
    <h1>{{ $t("news-for-you") }}</h1>
    <div class="news__row-holder">
      <router-link to="/newsPromotions" class="news__item">
        <div class="news__item-top">
          <h2>{{ $t("promotion") }}</h2>
        </div>
        <img src="@/assets/img/news-1.jpeg" :alt="$t('promotion')" />
      </router-link>
      <router-link to="/club" class="news__item">
        <div class="news__item-top">
          <h2>{{ $t("club") }}</h2>
        </div>
        <img src="@/assets/img/news-2.jpeg" :alt="$t('club')" />
      </router-link>
    </div>
    <div v-if="podcast" class="news__row-holder">
      <a :href="podcast.val" target="_blank" class="news__item">
        <div class="news__item-top">
          <h2>{{ $t("podcast") }}</h2>
        </div>
        <img src="@/assets/img/news-3.jpeg" :alt="$t('podcast')" />
        <img class="news__play" src="@/assets/img/icon-play.svg" alt="$t('play')" />
      </a>
    </div>
  </section>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Profile",

  computed: {
    ...mapGetters("setting", ["podcast"]),
  },
  mounted() {
    this.$store.cache.dispatch('setting/GET_PODCAST');

    let app = document.querySelector("#app");

    app.className = "";
    app.classList.add("isNews");

    this.$store.commit("headerShow");
    this.$store.commit("navHide");
    this.$store.commit("burgerHide");
    this.$store.commit("userHide");
    this.$store.commit('linkBackActive');

    this.$store.commit("navHide");
  }
};
</script>
