<template>
  <section class="shop">
    <h1>{{ $t("shop") }}</h1>
    <div v-if="shops.length !== 0">
      <div v-for="shop in shops" :key="shop.id">
        <router-link
          v-if="shop.link.type === 'internal'"
          class="shop__item"
          :to="shop.link.path"
          :style="{
            color: shop.text_color,
            background: shop.background_color,
          }"
        >
          <div class="shop__icon-holder">
            <img v-lazy="shop.image" :alt="shop.name[this.$i18n.locale]" />
          </div>
          <div class="shop__item-content">
            <h2>{{ shop.name[this.$i18n.locale] }}</h2>
            <p>{{ shop.description[this.$i18n.locale] }}</p>
          </div>
        </router-link>
        <a
          v-else
          class="shop__item"
          :href="shop.link.path"
          :style="{
            color: shop.text_color,
            background: shop.background_color,
          }"
          target="_blank"
        >
          <div class="shop__icon-holder">
            <img v-lazy="shop.image" :alt="shop.name[this.$i18n.locale]" />
          </div>
          <div class="shop__item-content">
            <h2>{{ shop.name[this.$i18n.locale] }}</h2>
            <p>{{ shop.description[this.$i18n.locale] }}</p>
          </div>
        </a>
      </div>
    </div>
    <nothing v-else />
  </section>
</template>

<script>
import {mapGetters} from "vuex";
import Nothing from "../components/Nothing";

export default {
  name: "Shop",

  components: {
    Nothing,
  },
  computed: {
    ...mapGetters("shop", ["shops"]),
  },
  mounted() {
    let countryOfUser = this.$cookies.get('country');
    this.$store.cache.dispatch("shop/GET_SHOPS", countryOfUser);

    this.$store.commit("navShow");
    this.$store.commit("linkBackDisable");
    this.$store.commit("burgerShow");
    this.$store.commit("userShow");

    let app = document.querySelector("#app");
    app.className = "";
    app.classList.add("isShop");
  },
};
</script>
